<template>
  <div>
    <ul class="health-messages list-disc" v-if="mode === 'instrument'">
      <li v-for="(message, index) in health.messages" v-bind:key="index">
        {{ message.title }}
      </li>
    </ul>

    <div v-else-if="mode === 'sensor'">
      <div v-if="health.messages.length !== 0">
        <h4 class="health-header">
          {{ health.friendly_name || health.id }}
        </h4>
        <ul class="health-messages list-disc">
          <li v-for="(message, index) in health.messages" v-bind:key="index">
            {{ message.title }}
          </li>
        </ul>
      </div>
      <div v-for="(component, name) in health.components" :key="name">
        <div v-if="component.messages.length">
          <h4 class="health-header">
            {{ component.friendly_name || name }}
          </h4>
          <ul class="health-messages list-disc">
            <li
              v-for="(message, index) in component.messages"
              v-bind:key="index"
            >
              {{ message.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-else-if="mode === 'fm'">
      <div v-if="health.messages">
        <li v-for="(message, index) in health.messages" v-bind:key="index">
          {{ message.title }}
        </li>
      </div>
      <div
        v-for="(sensorHealth, sensorName) in health.sensors"
        v-bind:key="sensorName"
      >
        <div v-if="sensorHealth.status !== 'ok'">
          <h3 class="sensor-header">
            {{ sensorHealth.friendly_name || sensorName }}
          </h3>
          <ul class="health-messages list-disc">
            <li
              v-for="(message, index) in sensorHealth.messages"
              v-bind:key="index"
            >
              {{ message.title }}
            </li>
          </ul>
        </div>
        <div v-for="(component, name) in sensorHealth.components" :key="name">
          <div v-if="component.messages.length">
            <h4 class="health-header">
              {{ component.friendly_name || name }}
            </h4>
            <ul class="health-messages list-disc">
              <li
                v-for="(message, index) in component.messages"
                v-bind:key="index"
              >
                {{ message.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    health: {
      type: [Array, Object],
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.health-messages {
  margin: 0 0 0 1.5rem;
  padding: 0;
}

.health-header {
  margin-top: 0.8rem;
  margin-bottom: 0.2rem;
  padding: 0;
}

.sensor-header {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 0;
  text-decoration: underline;
}
</style>
