<template>
  <div>
    <sf-stack class="filters">
      <sf-stack-item v-if="mode === 'fm'">
        <sf-form-item label="Sensor">
          <sf-select
            :options="sensorOptions"
            :value="params.sensor"
            @change="(e) => updateParam('sensor', e.target.value)"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item span="fill">
        <sf-form-item label="Search terms in MongoDB syntax">
          <sf-input
            v-model.trim="params.query"
            name="event-query"
            autocomplete="on"
            placeholder='{"sensor_event_id":{"$gte": 0}}'
            @keyup.enter="updateParam('search')"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item>
        <sf-form-item label="Submit">
          <sf-button @click="updateParam('search')">Search</sf-button>
        </sf-form-item>
      </sf-stack-item>
    </sf-stack>
    <sf-event-list
      :events="events"
      :params="params"
      :total="total"
      :event="event"
      :update-param="updateParam"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SfBaseView from '@/components/BaseView'
import SfFormItem from '@/components/FormItem'
import SfNotification from '@/components/SfNotification'
import SfSelect from '@/components/SfSelect'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import SfInput from '@/components/SfInput'
import SfButton from '@/components/SfButton'
import timestamp from '@/filters/timestamp'
import withoutEmpty from '@/helpers/withoutEmpty'
import apiService from '@/services/apiService'
import SfEventList from './_components/EventList.vue'
import eventSearchMixin from '@/mixins/eventSearchMixin'

export default {
  mixins: [eventSearchMixin],

  components: {
    SfBaseView,
    SfFormItem,
    SfNotification,
    SfSelect,
    SfStack,
    SfStackItem,
    SfInput,
    SfButton,
    SfEventList,
  },

  filters: {
    timestamp,
  },

  async preload({ route, store }) {
    const { id: eventIdParam, ...queryParams } = route.query

    let params = {
      sensor: store.getters.id,
      limit: 25,
      page: 1,
      query: '',
      advanced: 1,
      ...queryParams,
    }

    const {
      data: {
        events,
        meta: { total },
      },
    } = await apiService.getEventsAdvanced({
      ...params,
      offset: (params.page - 1) * params.limit,
    })

    const { data: sensors } = await apiService.getSensors()

    let event = null
    if (eventIdParam) {
      const [eventSensor, eventId] = eventIdParam.split('_')
      event = (await apiService.getEventById(eventSensor, eventId)).data
    }

    return {
      event,
      events,
      params,
      sensors,
      total,
    }
  },

  computed: {
    ...mapState(['mode', 'id']),
  },

  methods: {
    async updateParam(key, value) {
      this.params[key] = value
      // Reset the page on param changes.
      if (key !== 'page') {
        this.params.page = 1
      }

      const newQuery = withoutEmpty(this.params)

      if (
        newQuery.query !== this.$route.query ||
        this.params.sensor !== this.$route.query.sensor
      ) {
        // URL was changed, search was made or URL was changed manually.
        // Push both to the browsing history.
        this.$router.push({
          path: '/events/advanced',
          query: withoutEmpty(this.params),
        })
      } else {
        // User pressed Search twice in a row, perhaps there is new events?
        const {
          data: { events },
        } = await apiService.getEventsAdvanced({
          ...this.params,
          offset: (this.params.page - 1) * this.params.limit,
        })

        this.events = events
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filters {
  margin-bottom: 2rem;
}
</style>
