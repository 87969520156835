<template>
  <div :class="classes">
    <div class="aside">
      <div class="icon">
        <font-awesome-icon :icon="icon" />
      </div>
    </div>
    <div class="main">
      <div v-if="title" class="heading">
        <h2 class="title">{{ title }}</h2>
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBan,
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    status: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        notification: true,
        [`is-status-${this.status}`]: true,
      }
    },

    icon() {
      return {
        alert: faBan,
        info: faInfoCircle,
        warning: faExclamationCircle,
        success: faCheckCircle,
      }[this.status]
    },
  },
}
</script>

<style lang="scss" scoped>
.notification {
  background: $white;
  border-radius: 3px;
  border-top: 3px solid $grey-500;
  box-shadow: 0 0 0 1px rgba($grey-900, 0.1), 0 1px 3px 0 rgba($grey-900, 0.15);
  display: flex;
  padding: 2rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0 0 0.5rem;
}

.icon {
  align-items: center;
  background: $grey-200;
  border-radius: 50%;
  color: $grey-600;
  display: flex;
  font-size: 1.6rem;
  height: 2.4rem;
  justify-content: center;
  margin-right: 2rem;
  width: 2.4rem;
}

.content > :first-child {
  margin-top: 0;
}

.content > :last-child {
  margin-bottom: 0;
}

.is-status-info {
  border-top-color: $blue-500;
}

.is-status-info .icon {
  background: $blue-100;
  color: $blue-700;
}

.is-status-success {
  border-top-color: $green-600;
}

.is-status-success .icon {
  background: $green-100;
  color: $green-700;
}

.is-status-warning {
  border-top-color: $yellow-700;
}

.is-status-warning .icon {
  background: $yellow-100;
  color: $yellow-800;
}

.is-status-alert {
  border-top-color: $red-600;
}

.is-status-alert .icon {
  background: $red-100;
  color: $red-700;
}
</style>
