<template>
  <div class="base-view">
    <div class="header">
      <div class="container">
        <div class="title-row">
          <h1 class="title">{{ title }}</h1>
          <slot name="header" />
        </div>
        <div v-if="$slots.tabs" class="tabs">
          <slot name="tabs" />
        </div>
      </div>
    </div>
    <div class="body">
      <div class="container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  max-width: $container-width;
  width: 100%;
}

.header {
  background: $grey-200;
  border-bottom: 1px solid $grey-400;
  box-shadow: 0 1px 0 $white;
  padding: 1.8rem 2rem;
}

.title-row {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
}

.title {
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0;
}

.tabs {
  margin-bottom: -1.8rem;
  margin-top: 1.8rem;
}

.body {
  padding: 4rem 2rem;
}
</style>
