import axios from 'axios'
import config from '../config'
import store from '@/store'
import {
  ACCESS_FORBIDDEN,
  VERSION_CONFLICT,
  SOMETHING_WENT_WRONG,
  SENSOR_OFFLINE,
} from '@/helpers/messages'
import { Handled } from '@/helpers/errors'
import { safeReplaceRoute } from '../router'

const CONFIG_VERSION_HEADER = 'x-sensorfleet-config-version'

/* Handle some common errors and pass others as unhandled so caller can take
 * care of them.
 */
const handleGlobalErrors = (error) => {
  const status = error.response.status
  if (status === 401) {
    store
      .dispatch('auth/expireSession')
      .then(() => safeReplaceRoute('/login'))
      .catch(() => {})
    return Promise.reject(Handled)
  } else if (status === 403) {
    if (error.config.method === 'get') {
      safeReplaceRoute('/forbidden')
    } else {
      store.dispatch('toasts/raise', ACCESS_FORBIDDEN).then()
    }
    return Promise.reject(Handled)
  } else if (status === 409) {
    store.dispatch('toasts/raise', VERSION_CONFLICT).then()
    return Promise.reject(Handled)
  } else if (status === 500 || status === 504) {
    store.dispatch('toasts/raise', SOMETHING_WENT_WRONG).then()
    return Promise.reject(Handled)
  } else if (status === 502) {
    // No notification as if would be spammed on every click on UI
    return Promise.reject(Handled)
  }
  return Promise.reject({ ...error })
}

/* Axios client for general API requests. */
const apiClient = axios.create({
  baseURL: config.api_path,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

/* Error handling */
apiClient.interceptors.response.use(
  (config) => {
    return config
  },

  (error) => {
    if (
      error.config.localErrorHandlers &&
      error.config.localErrorHandlers.includes(error.response.status)
    ) {
      return Promise.reject(error)
    } else {
      return handleGlobalErrors(error)
    }
  }
)

/* Axios client for config handling.
 * It saves version from request to store and adds
 * that same version to response headers when updating config.
 */
const configClient = axios.create({
  baseURL: config.api_path,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

configClient.interceptors.request.use((config) => {
  if (['put', 'post', 'delete'].includes(config.method)) {
    let version = store.state.configVersions[config.url]
    if (version) {
      config.headers[CONFIG_VERSION_HEADER] = version
    }
  }
  return config
})

configClient.interceptors.response.use(
  (config) => {
    const version = config.headers[CONFIG_VERSION_HEADER]
    if (version) {
      store.commit('setConfigVersion', {
        item: config.config.url,
        version: config.headers[CONFIG_VERSION_HEADER],
      })
    }
    if (Array.isArray(config.data)) {
      /* Check if array items has versions and save them if found
         For example /sensors/fm/instruments -> /sensors/fm/instruments/foo_0@fm
      */
      config.data.forEach((item) => {
        if ('config_version' in item && 'id' in item) {
          store.commit('setConfigVersion', {
            item: config.config.url + '/' + item.id,
            version: item.config_version,
          })
        }
      })
    }
    return config
  },

  (error) => {
    if (
      error.config.localErrorHandlers &&
      error.config.localErrorHandlers.includes(error.response.status)
    ) {
      return Promise.reject(error)
    } else {
      return handleGlobalErrors(error)
    }
  }
)

export default {
  getEventById(sensor, id) {
    return apiClient.get(`/events/${sensor}/${id}`)
  },
  getEvents({ sensor, limit, offset, age, source, type, id }, config) {
    return apiClient.get('/events', {
      params: {
        sensor,
        limit,
        offset,
        age,
        source,
        type,
        id,
      },
      ...config,
    })
  },
  getEventsAdvanced({ sensor, query, limit, offset }) {
    return apiClient.get('/events/advanced', {
      params: {
        sensor,
        query,
        limit,
        offset,
      },
    })
  },
  getEventTypes() {
    return apiClient.get('/events/types')
  },
  getInstrumentEventTypes(sensor, instrument) {
    return apiClient.get(
      `/sensors/${sensor}/instruments/${instrument}/event-types`
    )
  },
  getSensors() {
    return configClient.get('/sensors')
  },
  getSensor(sensor) {
    return configClient.get(`/sensors/${sensor}`)
  },
  getSensorInterfaces(sensor) {
    return configClient.get(`/sensors/${sensor}/interfaces`)
  },
  getSensorInterface(sensor, iface) {
    return configClient.get(`/sensors/${sensor}/interfaces/${iface}`)
  },
  createSensorInterface(sensor, config) {
    return configClient.post(`/sensors/${sensor}/interfaces`, config)
  },
  updateSensorInterface(sensor, iface, config) {
    return configClient.put(`/sensors/${sensor}/interfaces/${iface}`, config)
  },
  deleteSensorInterface(sensor, iface) {
    return configClient.delete(`/sensors/${sensor}/interfaces/${iface}`)
  },
  getPhysicalInterfaces(sensor) {
    return configClient.get(`/sensors/${sensor}/interfaces/physical`)
  },
  getFreeIp(sensor, sensorIFace) {
    return apiClient.get(
      `/sensors/${sensor}/interfaces/${sensorIFace}/next-free-ip`
    )
  },
  getSensorRetentionRules(sensor) {
    return configClient.get(`/sensors/${sensor}/retention-rules`)
  },
  updateSensorRetentionRules(sensor, rules) {
    return configClient.put(`/sensors/${sensor}/retention-rules`, rules)
  },
  getSensorSettings(sensor) {
    return configClient.get(`/sensors/${sensor}/settings`)
  },
  restartSensor(sensor) {
    return apiClient.post(`/sensors/${sensor}/restart`)
  },
  updateSensorSettings(sensor, settings) {
    return configClient.put(`/sensors/${sensor}/settings`, settings)
  },
  getInstruments(sensor) {
    return configClient.get(`/sensors/${sensor}/instruments`)
  },
  getInstrument(sensor, instrument) {
    return configClient.get(`/sensors/${sensor}/instruments/${instrument}`)
  },
  createInstrument(sensor, data, config) {
    return configClient.post(`/sensors/${sensor}/instruments`, data, config)
  },
  deleteInstrument(sensor, instrument) {
    return configClient.delete(`/sensors/${sensor}/instruments/${instrument}`)
  },
  createInstrumentInterface(sensor, instrument, config) {
    return configClient.post(
      `/sensors/${sensor}/instruments/${instrument}/interfaces`,
      config
    )
  },
  getInstrumentInterfaces(sensor, instrument) {
    return configClient.get(
      `/sensors/${sensor}/instruments/${instrument}/interfaces`
    )
  },
  updateInstrumentInterface(sensor, instrument, iface, config) {
    return configClient.put(
      `/sensors/${sensor}/instruments/${instrument}/interfaces/${iface}`,
      config
    )
  },
  deleteInstrumentInterface(sensor, instrument, iface) {
    return configClient.delete(
      `/sensors/${sensor}/instruments/${instrument}/interfaces/${iface}`
    )
  },
  createInstrumentDownload(sensor, instrument, config) {
    return configClient.post(
      `/sensors/${sensor}/instruments/${instrument}/downloads`,
      config
    )
  },
  getInstrumentDownloads(sensor, instrument) {
    return configClient.get(
      `/sensors/${sensor}/instruments/${instrument}/downloads`
    )
  },
  getAllowEditDownloads(sensor, instrument) {
    return configClient.get(
      `/sensors/${sensor}/instruments/${instrument}/downloads/allow-edit`
    )
  },
  updateInstrumentDownload(sensor, instrument, download, config) {
    return configClient.put(
      `/sensors/${sensor}/instruments/${instrument}/downloads/${download}`,
      config
    )
  },
  deleteInstrumentDownload(sensor, instrument, download) {
    return configClient.delete(
      `/sensors/${sensor}/instruments/${instrument}/downloads/${download}`
    )
  },
  getInstrumentRetentionRules(sensor, instrument) {
    return configClient.get(
      `/sensors/${sensor}/instruments/${instrument}/retention-rules`
    )
  },
  updateInstrumentRetentionRules(sensor, instrument, rules) {
    return configClient.put(
      `/sensors/${sensor}/instruments/${instrument}/retention-rules`,
      rules
    )
  },
  getInstrumentSettings(sensor, instrument) {
    return configClient.get(
      `/sensors/${sensor}/instruments/${instrument}/settings`
    )
  },
  updateInstrumentSettings(sensor, instrument, settings) {
    return configClient.put(
      `/sensors/${sensor}/instruments/${instrument}/settings`,
      settings
    )
  },

  getInstrumentConfig(sensor, instrument) {
    return configClient.get(
      `/sensors/${sensor}/instruments/${instrument}/config`
    )
  },

  updateInstrumentConfig(sensor, instrument, config) {
    return configClient.put(
      `/sensors/${sensor}/instruments/${instrument}/config`,
      config
    )
  },

  getInstrumentConfigSchema(sensor, instrument) {
    return apiClient.get(
      `/sensors/${sensor}/instruments/${instrument}/config_schema`
    )
  },
  getSensorVersion(sensor) {
    return apiClient.get(`/sensors/${sensor}/version`)
  },
  createCheckSensorUpdatesJob(sensor) {
    return apiClient.post(`/sensors/${sensor}/version/check`)
  },
  createSensorUpgradeJob(sensor) {
    return apiClient.post(`/sensors/${sensor}/version/upgrade`)
  },
  getLastSensorUpgradeJob(sensor) {
    return apiClient.get(`/sensors/${sensor}/version/upgrade/last`)
  },
  getAvailableInstruments() {
    return apiClient.get('/available-instruments')
  },
  refreshAvailableInstruments() {
    return apiClient.post('/available-instruments/refresh')
  },
  restartInstrument(sensor, instrument) {
    return apiClient.post(
      `/sensors/${sensor}/instruments/${instrument}/restart`
    )
  },
  getInitialConfig() {
    return apiClient.get('/initial-config')
  },

  logIn({ username, password, expires }) {
    return apiClient.post('/login', { username, password, expires })
  },
  logOut() {
    return apiClient.post('/logout')
  },
  getSession() {
    return apiClient.get('/session')
  },

  getUsers() {
    return configClient.get('/users')
  },
  getUser(id) {
    return configClient.get(`/users/${id}`)
  },
  createUser(user) {
    return configClient.post('/users', user)
  },
  deleteUser(id) {
    return configClient.delete(`/users/${id}`)
  },
  getRandomPassword() {
    return configClient.get('/users/random-password')
  },
  async updateUser(id, user) {
    await this.getUser(id)
    return configClient.put(`/users/${id}`, user)
  },
  async updateUserPassword(id, { current_password, new_password }) {
    await configClient.get(`/users/${id}/password`)
    return configClient.put(`/users/${id}/password`, {
      current_password,
      new_password,
    })
  },
  getTokens(userId) {
    return configClient.get(`/users/${userId}/api-tokens`)
  },
  getToken(userId, tokenId) {
    return configClient.get(`/users/${userId}/api-tokens/${tokenId}`)
  },
  createToken(userId, token) {
    return configClient.post(`/users/${userId}/api-tokens`, token)
  },
  async deleteToken(userId, tokenId) {
    await this.getToken(userId, tokenId)
    return configClient.delete(`/users/${userId}/api-tokens/${tokenId}`)
  },
}
