import apiService from '../services/apiService'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  actions: {
    async changePassword({ commit, state }, { data }) {
      await apiService.updateUserPassword(state.user.user_id, data)
      commit('setUser', { ...state.user, change_password: false })
    },
    async logIn({ dispatch }, { username, password, rememberMe = false }) {
      await apiService.logIn({
        username,
        password,
        expires: !rememberMe,
      })
      await dispatch('getSession')
    },
    async logOut({ commit }) {
      commit('setUser', null)
      await apiService.logOut()
    },
    async getSession({ commit }) {
      const { data: user } = await apiService.getSession()
      commit('setUser', user)
    },
    async expireSession({ commit, getters }) {
      if (!getters.isLoggedIn) {
        throw new Error('Not logged in')
      }
      commit('setUser', null)
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },
  getters: {
    hasPrivilege: (state) => (privilege) => {
      return (
        state.user &&
        state.user.privileges &&
        state.user.privileges.indexOf(privilege) !== -1
      )
    },
    isAdmin: (_state, getters) => {
      return (
        getters.hasPrivilege('admin:read') ||
        getters.hasPrivilege('admin:write')
      )
    },
    isLoggedIn: (state) => !!state.user,
  },
}
