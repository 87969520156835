<template>
  <sf-stack>
    <sf-stack-item span="fill">
      <sf-input
        type="number"
        :min="0"
        :placeholder="placeholderDays"
        v-model.trim="days"
        @input="onChange"
        cy="days"
      >
        <template slot="append"> days </template>
      </sf-input>
    </sf-stack-item>
    <sf-stack-item span="fill">
      <sf-input
        type="number"
        :min="0"
        :placeholder="placeholderHours"
        v-model.trim="hours"
        @input="onChange"
        cy="hours"
      >
        <template slot="append"> hours </template>
      </sf-input>
    </sf-stack-item>
    <sf-stack-item>
      <sf-button plain type="button" @click="$emit('input', null)" cy="clear">
        Clear
      </sf-button>
    </sf-stack-item>
  </sf-stack>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import SfInput from '@/components/SfInput'

const state = (value) => {
  return {
    days: value == null ? '' : Math.floor(value / (24 * 3600)),
    hours: value == null ? '' : Math.floor((value % (24 * 3600)) / 3600),
  }
}

export default {
  components: {
    SfButton,
    SfStack,
    SfStackItem,
    SfInput,
  },

  props: {
    value: {
      type: Number,
    },
    placeholder: {
      type: Number,
      default: null,
    },
  },

  data() {
    return state(this.value)
  },

  computed: {
    placeholderDays() {
      return this.placeholder == null
        ? ''
        : Math.floor(this.placeholder / (24 * 3600))
    },

    placeholderHours() {
      return this.placeholder == null
        ? ''
        : Math.floor((this.placeholder % (24 * 3600)) / 3600)
    },
  },

  watch: {
    value(value) {
      Object.assign(this, state(value))
    },
  },

  methods: {
    onChange() {
      const daysInSeconds = Math.max(0, this.days * 24 * 60 * 60)
      const hoursInSeconds = Math.max(0, this.hours * 60 * 60)
      this.$emit('input', Math.floor(daysInSeconds + hoursInSeconds))
    },
  },
}
</script>
