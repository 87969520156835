/*
 * Visualize using https://stately.ai/viz
 */

import { createMachine, send } from 'xstate'

const bridgeSelectorHidden = (context) => {
  return context.bridgeSelectorHidden
}

export default createMachine({
  id: 'main',
  initial: 'editing',
  context: {
    typeSelector: 'bridge',
    bridgeSelector: 'visible',
    physInterfaceSelector: 'hidden',
    ipAddressSelector: 'static',
    saveButton: 'disabled',
  },
  states: {
    end: {
      type: 'final',
    },
    editing: {
      id: 'editing',
      type: 'parallel',
      initial: 'initial',
      states: {
        bridgeSelector: {
          id: 'bridgeSelector',
          initial: 'initial',
          states: {
            initial: {
              always: [
                {
                  target: 'hidden',
                  cond: (context) => context.bridgeSelector === 'hidden',
                },
                {
                  target: 'visible',
                  cond: (context) => context.bridgeSelector === 'visible',
                },
              ],
            },
            visible: {
              on: {
                PHYS_SELECTED: 'hidden',
              },
            },
            hidden: {
              on: {
                BRIDGE_SELECTED: 'visible',
                MIRROR_BRIDGE_SELECTED: 'visible',
              },
            },
          },
        },
        physInterfaceSelector: {
          id: 'physInterfaceSelector',
          initial: 'initial',
          states: {
            initial: {
              always: [
                {
                  target: 'hidden',
                  cond: (context) => context.physInterfaceSelector === 'hidden',
                },
                {
                  target: 'visible',
                  cond: (context) =>
                    context.physInterfaceSelector === 'visible',
                },
                {
                  target: 'disabled',
                  cond: (context) =>
                    context.physInterfaceSelector === 'disabled',
                },
              ],
            },
            visible: {
              on: {
                BRIDGE_SELECTED: 'hidden',
                MIRROR_BRIDGE_SELECTED: 'hidden',
              },
            },
            hidden: {
              on: {
                PHYS_SELECTED: 'visible',
              },
            },
            disabled: {},
          },
        },
        typeSelector: {
          id: 'typeSelector',
          initial: 'initial',
          states: {
            initial: {
              always: [
                {
                  target: 'bridge',
                  cond: (context) => context.typeSelector === 'bridge',
                },
                {
                  target: 'phys',
                  cond: (context) => context.typeSelector === 'phys',
                },
                {
                  target: 'hidden',
                  cond: (context) => context.typeSelector === 'hidden',
                },
              ],
            },
            bridge: {
              on: {
                HIDE_TYPE: 'hidden',
                PHYS_SELECTED: 'phys',
              },
            },
            phys: {
              on: {
                HIDE_TYPE: 'hidden',
                BRIDGE_SELECTED: 'bridge',
              },
            },
            hidden: {},
          },
        },
        saveButton: {
          id: 'saveButton',
          initial: 'initial',
          states: {
            initial: {
              always: [
                {
                  target: 'disabled',
                  cond: (context) => context.saveButton === 'disabled',
                },
                {
                  target: 'enabled',
                  cond: (context) => context.saveButton === 'enabled',
                },
                {
                  target: 'saving',
                  cond: (context) => context.saveButton === 'saving',
                },
              ],
            },
            disabled: {
              on: {
                CONFIG_VALID: 'enabled',
              },
            },
            enabled: {
              on: {
                CONFIG_INVALID: 'disabled',
                SAVING: 'saving',
              },
            },
            saving: {
              on: {
                SAVE_FAILED: 'enabled',
                SAVED: '#main.end',
              },
            },
          },
        },
        ipAddressSelector: {
          id: 'ipAddressSelector',
          initial: 'initial',
          states: {
            initial: {
              always: [
                {
                  target: 'visible.dhcp',
                  cond: (context) => context.ipAddressSelector === 'dhcp',
                },
                {
                  target: 'visible.static',
                  cond: (context) => context.ipAddressSelector === 'static',
                },
                {
                  target: 'hidden',
                  cond: (context) => context.ipAddressSelector === 'hidden',
                },
              ],
            },
            visible: {
              id: 'visible',
              initial: 'dhcp',
              states: {
                dhcp: {
                  on: {
                    DHCP_OFF: 'static',
                    MIRROR_BRIDGE_SELECTED:
                      '#main.editing.ipAddressSelector.hidden',
                  },
                },
                static: {
                  on: {
                    DHCP_ON: 'dhcp',
                    MIRROR_BRIDGE_SELECTED:
                      '#main.editing.ipAddressSelector.hidden',
                  },
                },
                hist: {
                  type: 'history',
                  history: 'deep',
                },
              },
            },
            hidden: {
              on: {
                BRIDGE_SELECTED: 'visible.hist',
                PHYS_SELECTED: 'visible.hist',
              },
            },
          },
        },
      },
    },
  },
  actions: {},
})
