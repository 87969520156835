<template>
  <portal to="modal">
    <transition
      appear
      name="scale-fade"
      @enter="$emit('enter')"
      @after-enter="$emit('after-enter')"
    >
      <div :class="classes" v-on="$listeners">
        <div class="dialog">
          <div v-if="title" class="dialog-header">
            <h1 class="dialog-title">{{ title }}</h1>
            <button
              v-if="$listeners && $listeners.close"
              class="dialog-close"
              @click="$emit('close')"
            >
              &times;
            </button>
          </div>
          <div v-if="$slots.default" class="dialog-body">
            <slot />
          </div>
          <div v-if="$slots.actions" class="dialog-footer">
            <div class="dialog-actions">
              <slot name="actions" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
    },
  },

  mounted() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.body.style.overflowY = 'hidden'
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.body.style.overflowY = ''
  },

  computed: {
    classes() {
      return {
        modal: true,
        [`is-${this.width}`]: !!this.width,
      }
    },
  },

  methods: {
    open() {
      this.$emit('open')
    },

    close() {
      this.$emit('close')
    },

    handleKeyDown(evt) {
      if (evt.key === 'Escape') {
        this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.scale-fade-enter,
.scale-fade-leave-active {
  opacity: 0;
}

.scale-fade-enter .dialog,
.scale-fade-leave-active .dialog {
  transform: scale(1.1);
}

.modal {
  align-items: center;
  background: rgba($grey-900, 0.3);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow-y: auto;
  padding: 20vh 2rem;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.25s;
  z-index: 100;
}

.dialog {
  background: $white;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba($grey-900, 0.2), 0 8px 12px rgba($grey-900, 0.1);
  display: inline-block;
  max-width: 640px;
  transition: all 0.15s;
  width: 100%;
}

.dialog-header {
  border-bottom: 1px solid $grey-lighter;
  padding: 1.5rem 2rem;
  position: relative;
}

.dialog-title {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}

.dialog-close {
  bottom: 0;
  font-size: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 6rem;
}

.dialog-body {
  padding: 2rem;
}

.dialog-body > :first-child {
  margin-top: 0;
}

.dialog-body > :last-child {
  margin-bottom: 0;
}

.dialog-footer {
  border-top: 1px solid $grey-lighter;
  padding: 1.5rem 2rem;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
}

.is-narrow .dialog {
  max-width: 480px;
}
</style>
