import apiService from '@/services/apiService'

export default {
  namespaced: true,
  state: {
    tokens: null,
  },
  mutations: {
    setTokens(state, tokens) {
      state.tokens = tokens
    },
  },
  actions: {
    async createToken({ dispatch, rootState }, { data }) {
      const {
        data: { token },
      } = await apiService.createToken(rootState.auth.user.user_id, data)
      await dispatch('getTokens')
      return token
    },
    async deleteToken({ dispatch, rootState }, { tokenId }) {
      await apiService.deleteToken(rootState.auth.user.user_id, tokenId)
      await dispatch('getTokens')
    },
    async getTokens({ commit, rootState }) {
      const { data: tokens } = await apiService.getTokens(
        rootState.auth.user.user_id
      )
      commit('setTokens', tokens)
    },
  },
}
