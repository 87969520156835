<template>
  <div>
    <sf-stack class="filters">
      <sf-stack-item v-if="mode === 'fm'" span="fill">
        <sf-form-item label="Sensor">
          <sf-select
            :options="sensorOptions"
            :value="params.sensor"
            @change="(e) => updateParam('sensor', e.target.value)"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item span="fill">
        <sf-form-item label="Source Instrument">
          <sf-select
            :options="sourceOptions"
            :value="params.source"
            @change="(e) => updateParam('source', e.target.value)"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item span="fill">
        <sf-form-item label="Event type">
          <sf-combo-box
            :options="eventsForInstrument"
            :value="params.type"
            @change="(e) => updateParam('type', e.target.value)"
            @click.native="$event.target.select()"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item span="fill">
        <sf-form-item label="Time range">
          <sf-select
            :options="ageOptions"
            :value="params.age"
            @change="(e) => updateParam('age', e.target.value)"
          />
        </sf-form-item>
      </sf-stack-item>
    </sf-stack>
    <sf-event-list
      :events="events"
      :params="params"
      :total="total"
      :event="event"
      :update-param="updateParam"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SfBaseView from '@/components/BaseView'
import SfFormItem from '@/components/FormItem'
import SfSelect from '@/components/SfSelect'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import timestamp from '@/filters/timestamp'
import withoutEmpty from '@/helpers/withoutEmpty'
import instrumentsForSensors from '@/helpers/instrumentsForSensors'
import apiService from '@/services/apiService'
import SfComboBox from '@/components/ComboBox'
import SfEventDetails from './_components/EventDetails'
import SfEventList from './_components/EventList.vue'
import eventSearchMixin from '@/mixins/eventSearchMixin'

export default {
  mixins: [eventSearchMixin],

  components: {
    SfBaseView,
    SfEventDetails,
    SfFormItem,
    SfSelect,
    SfStack,
    SfStackItem,
    SfComboBox,
    SfEventList,
  },

  filters: {
    timestamp,
  },

  async preload({ route, store }) {
    const { id: eventIdParam, ...queryParams } = route.query

    let params = {
      sensor: store.getters.id,
      source: '',
      type: '',
      age: 60 * 60 * 24,
      limit: 25,
      page: 1,
      ...queryParams,
    }

    /* Fetch events, sensors and event types parallel.
       Events promise is awaited later on so it can run
       in background with fetching instruments too. */
    const eventsPromise = apiService.getEvents(
      {
        ...params,
        offset: (params.page - 1) * params.limit,
      },
      { localErrorHandlers: [504] }
    )

    const [{ data: sensors }, { data: eventTypes }] = await Promise.all([
      apiService.getSensors(),
      route.query.source
        ? apiService.getInstrumentEventTypes(
            route.query.sensor,
            route.query.source
          )
        : apiService.getEventTypes(),
    ])

    const sensorsAndInstruments = await instrumentsForSensors(sensors)
    let events
    let total

    try {
      const result = await eventsPromise
      events = result.data.events
      total = result.data.meta.total
    } catch (err) {
      if (err !== Handled && err.response.status === 504) {
        await store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Event search timeout',
        })
      } else if (err !== Handled) {
        await store.dispatch('toasts/raise', SOMETHING_WENT_WRONG)
      }
      events = []
      total = 0
    }

    let event = null
    if (eventIdParam) {
      const [eventSensor, eventId] = eventIdParam.split('_')
      event = (await apiService.getEventById(eventSensor, eventId)).data
    }

    return {
      event,
      events,
      total,
      params,
      sensors,
      sensorsAndInstruments,
      eventTypes,
      sensorsPoller: null,
    }
  },

  data() {
    return {
      ageOptions: [
        { label: 'Last hour', value: 60 * 60 },
        { label: 'Last 24 hours', value: 60 * 60 * 24 },
        { label: 'Last 7 days', value: 60 * 60 * 24 * 7 },
        { label: 'Last 30 days', value: 60 * 60 * 24 * 30 },
      ],
    }
  },

  watch: {
    params: {
      deep: true,
      handler(value) {
        this.$router.push({ path: '/events/basic', query: withoutEmpty(value) })
      },
    },
  },

  computed: {
    ...mapState(['mode', 'id']),

    sourceOptions() {
      return [
        { label: 'Any', value: '' },
        ...this.instruments.map((instrument) => ({
          label: instrument.friendly_name || instrument.id,
          value: instrument.id,
        })),
      ]
    },

    instruments() {
      if (this.mode === 'sensor') {
        const sensorId = Object.keys(this.sensorsAndInstruments)[0]
        return sensorId ? this.sensorsAndInstruments[sensorId] : []
      }
      return this.sensorsAndInstruments[this.params.sensor]
    },

    eventsForInstrument() {
      // Convert to label:value pairs for dropdown
      return this.eventTypes.map((event) => {
        return {
          label: event,
          value: event,
        }
      })
    },
  },

  methods: {
    updateParam(key, value) {
      this.params[key] = value
      // Reset the page on param changes.
      if (key !== 'page') {
        this.params.page = 1
      }
      // Reset the source and type on sensor changes.
      if (key === 'sensor') {
        this.params.source = ''
        this.params.type = ''
      }
      // Reset the type on source instrument changes.
      if (key === 'source') {
        this.params.type = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filters {
  margin-bottom: 2rem;
}
</style>
