<template>
  <div>
    <div class="flex items-center mb-8">
      <div class="flex-1">
        <h1 class="text-4xl">Users</h1>
      </div>
      <div class="w-96">
        <sf-input v-model="nameQuery" placeholder="Filter users by name" />
      </div>
      <div class="ml-4">
        <sf-button @click="openAddDialog">Add user</sf-button>
      </div>
    </div>
    <sf-card v-if="filteredUsers.length">
      <sf-table
        :columns="columns"
        :data="filteredUsers"
        :page-size="1000"
        responsive
      >
        <template slot="column-name" slot-scope="{ row: user }">
          <div class="flex items-center">
            <sf-avatar :name="user.username" />
            <div>
              <div class="flex items-baseline space-x-2">
                <div class="font-bold">{{ user.username }}</div>
                <div v-if="user.full_name" class="text-2xl">
                  {{ user.full_name }}
                </div>
              </div>
              <div v-if="user.email" class="text-2xl text-gray-400">
                {{ user.email || 'Email address not set' }}
              </div>
            </div>
          </div>
        </template>
        <template slot="column-created" slot-scope="{ row: user }">
          <div>
            {{ user.created | timestamp }}
          </div>
          <div class="text-2xl text-gray-400">
            {{ user.created | relativeTime }}
          </div>
        </template>
        <template slot="column-privileges" slot-scope="{ row: user }">
          <div class="flex flex-wrap gap-2 sm:flex-nowrap">
            <div
              class="bg-gray-100 border-b border-gray-300 px-2 py-px rounded text-2xl"
              v-for="privilege in user.privileges"
              :key="privilege"
            >
              {{ privilege }}
            </div>
          </div>
        </template>
        <template slot="column-actions" slot-scope="{ row: user }">
          <sf-button-group segmented>
            <sf-button plain size="small" @click="editUser(user)">
              Edit
            </sf-button>
            <sf-button
              plain
              size="small"
              @click="deleteUser(user.id, user.username)"
            >
              Delete
            </sf-button>
          </sf-button-group>
        </template>
      </sf-table>
    </sf-card>
    <div v-else>
      <sf-notification title="No users found">
        No results found for "{{ nameQuery }}".
        <button class="link" @click="nameQuery = ''">Clear query</button>
      </sf-notification>
    </div>
    <sf-add-user-dialog
      v-if="addDialog.visible"
      :initial-data="addDialog.data"
      @close="addDialog.visible = false"
      @success="onAddSuccess"
      @error="onAddError"
    />
    <sf-edit-user-dialog
      v-if="editDialog.visible"
      :initial-data="editDialog.data"
      :user-id="editDialog.userId"
      @close="editDialog.visible = false"
      @success="onEditSuccess"
      @error="onEditError"
    />
    <sf-confirm-dialog ref="deleteDialog" title="Delete user?">
      Are you sure you want to delete user
      <span class="font-bold">{{ deleteDialog.username }}</span
      >? This action cannot be undone.
    </sf-confirm-dialog>
  </div>
</template>

<script>
import apiService from '@/services/apiService'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCard from '@/components/SfCard'
import SfConfirmDialog from '@/components/ConfirmDialog'
import SfInput from '@/components/SfInput'
import SfNotification from '@/components/SfNotification'
import SfTable from '@/components/SfTable'
import relativeTime from '@/filters/relativeTime'
import timestamp from '@/filters/timestamp'
import SfAddUserDialog from './_components/AddUserDialog'
import SfAvatar from './_components/SfAvatar'
import SfEditUserDialog from './_components/EditUserDialog'
import { mapState } from 'vuex'

export default {
  inject: ['$progressBar'],
  components: {
    SfAddUserDialog,
    SfAvatar,
    SfButton,
    SfButtonGroup,
    SfCard,
    SfConfirmDialog,
    SfEditUserDialog,
    SfInput,
    SfNotification,
    SfTable,
  },
  filters: {
    relativeTime,
    timestamp,
  },
  async preload({ store }) {
    await store.dispatch('users/getUsers')
  },
  data() {
    return {
      addDialog: {
        initialData: {},
        visible: false,
      },
      deleteDialog: {
        username: null,
      },
      editDialog: {
        visible: false,
        data: null,
        userId: null,
      },
      columns: [
        {
          field: 'name',
          label: 'Name',
          width: '99%',
        },
        {
          field: 'privileges',
          label: 'Privileges',
        },
        {
          field: 'created',
          label: 'Created',
        },
        {
          field: 'actions',
          label: 'Actions',
        },
      ],
      nameQuery: '',
    }
  },
  computed: {
    ...mapState('users', ['users']),
    filteredUsers() {
      return [...this.users]
        .filter((user) => {
          const query = this.nameQuery ? this.nameQuery.toLowerCase() : ''
          return (
            !query ||
            user.username.toLowerCase().indexOf(query) !== -1 ||
            user.full_name.toLowerCase().indexOf(query) !== -1 ||
            user.email.toLowerCase().indexOf(query) !== -1
          )
        })
        .sort((a, b) => a.username.localeCompare(b.username))
    },
  },
  methods: {
    async deleteUser(id, username) {
      this.deleteDialog.username = username
      try {
        this.$refs.deleteDialog.open(async () => {
          await this.$store.dispatch('users/deleteUser', { userId: id })
          this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: `User ${username} deleted`,
          })
        })
      } catch (error) {
        this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: `Failed to delete user ${username}`,
        })
      }
    },
    editUser(user) {
      const { username, full_name, email, privileges } = user
      this.editDialog.visible = true
      this.editDialog.data = { username, full_name, email, privileges }
      this.editDialog.userId = user.id
    },
    async openAddDialog() {
      this.$progressBar.start()
      try {
        const {
          data: { password },
        } = await apiService.getRandomPassword()
        this.addDialog.data = { password }
      } catch (error) {
        this.addDialog.data = {}
      } finally {
        this.addDialog.visible = true
        this.$progressBar.finish()
      }
    },
    onAddError() {
      this.$store.dispatch('toasts/raise', {
        status: 'alert',
        message: 'Failed to add user',
      })
    },
    onAddSuccess(user) {
      this.addDialog.visible = false
      this.$store.dispatch('toasts/raise', {
        status: 'success',
        message: `User ${user.username} added`,
      })
    },
    onEditSuccess(user) {
      this.editDialog.visible = false
      this.editDialog.data = null
      this.editDialog.userId = null
      this.$store.dispatch('toasts/raise', {
        status: 'success',
        message: `User ${user.username} updated`,
      })
    },
    onEditError() {
      this.$store.dispatch('toasts/raise', {
        status: 'alert',
        message: 'Failed to update user',
      })
    },
  },
}
</script>
