'
<template>
  <sf-form @submit.prevent="save">
    <sf-annotated-section title="General settings">
      <template slot="help">
        Give the instrument a friendly name for easier identification.
      </template>
      <sf-card>
        <sf-card-section>
          <sf-form-item label="Friendly name" cy="delete-events">
            <sf-input
              v-model.trim="settings.friendly_name"
              :placeholder="instrument.default_name"
            />
          </sf-form-item>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-annotated-section title="Static routes">
      <template slot="help">
        Routes to networks that should be reachable from within the instrument
        container.
      </template>
      <sf-card>
        <sf-card-section>
          <div class="grid">
            <div class="row is-header">
              <div class="input">
                <sf-context-help>
                  Network
                  <template slot="help">
                    Network to be routed through the gateway. In CIDR format
                    e.g. "0.0.0.0/0".
                  </template>
                </sf-context-help>
              </div>
              <div class="input">
                <sf-context-help>
                  Gateway
                  <template slot="help">
                    What IP address to use as the gateway.
                  </template>
                </sf-context-help>
              </div>
              <div class="actions">&nbsp;</div>
            </div>
            <div
              class="row"
              v-for="(staticRoute, index) in settings.routes"
              :key="index"
            >
              <div class="input">
                <sf-input v-model.trim="staticRoute.network" />
              </div>
              <div class="input">
                <sf-input v-model.trim="staticRoute.gateway" />
              </div>
              <div class="actions">
                <sf-button
                  plain
                  full-width
                  type="button"
                  @click="removeStaticRoute(index)"
                >
                  {{ settings.routes.length === 1 ? 'Clear' : 'Remove' }}
                </sf-button>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <div class="actions">
              <sf-button type="button" @click="addStaticRoute">
                New route
              </sf-button>
            </div>
          </div>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-annotated-section title="DNS servers">
      <template slot="help">
        DNS address resolvers the instrument container should use.
      </template>
      <sf-card>
        <sf-card-section>
          <div class="grid">
            <div class="row is-header">
              <div class="input">Nameserver</div>
              <div class="actions">&nbsp;</div>
            </div>
            <div
              v-for="(dnsServer, index) in settings.dns.nameservers"
              class="row"
              :key="index"
            >
              <div class="input">
                <sf-input v-model.trim="settings.dns.nameservers[index]" />
              </div>
              <div class="actions">
                <sf-button
                  plain
                  full-width
                  type="button"
                  @click="removeDnsServer(index)"
                >
                  {{
                    settings.dns.nameservers.length === 1 ? 'Clear' : 'Remove'
                  }}
                </sf-button>
              </div>
            </div>
            <div class="mt-8">
              <sf-button type="button" @click="addDnsServer">
                New server
              </sf-button>
            </div>
          </div>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-annotated-section title="Home network overrides">
      <template slot="help">
        Some Sensor functionalities, such as Suricata IDS require home network
        configuration. Add Instrument specific overrides for monitored network
        ranges here in CIDR format (e.g. "10.0.0.0/8").
      </template>
      <sf-stack column>
        <sf-stack-item>
          <sf-card>
            <div
              class="bg-gray-100 border-b flex h-20 items-center px-8 py-4 rounded-t"
            >
              <div class="font-medium">Network overrides</div>
              <div
                v-if="settings.homenets_enabled"
                class="bg-green-600 font-medium ml-4 px-3 rounded-full text-white text-xl uppercase"
              >
                Enabled
              </div>
              <div
                v-else
                class="bg-gray-300 font-medium ml-4 px-3 rounded-full text-gray-700 text-xl uppercase"
              >
                Disabled
              </div>
            </div>
            <div class="px-8 py-6">
              <label class="flex items-baseline space-x-4">
                <input type="checkbox" v-model="settings.homenets_enabled" />
                <span> Enable home network overrides for this Instrument</span>
              </label>
            </div>
            <div class="border-t px-8 py-6">
              <div class="font-medium mb-4">
                Add network overrides (optional)
              </div>
              <div class="flex space-x-4">
                <sf-input
                  class="flex-1"
                  ref="newHomenetName"
                  placeholder="New network name"
                  v-model.trim="newHomenetName"
                  :disabled="!settings.homenets_enabled"
                  @keydown.enter.prevent="addHomenet"
                />
                <sf-button
                  type="button"
                  :disabled="!newHomenetName || !settings.homenets_enabled"
                  @click="addHomenet"
                >
                  Add network
                </sf-button>
              </div>
            </div>
          </sf-card>
        </sf-stack-item>
        <template>
          <sf-stack-item v-for="(_, index) in settings.homenets" :key="index">
            <sf-homenet
              :enabled="settings.homenets_enabled"
              :ref="setHomenetRef"
              v-model="settings.homenets[index]"
              @remove="removeHomenet(index)"
            />
          </sf-stack-item>
        </template>
      </sf-stack>
    </sf-annotated-section>
    <sf-annotated-section>
      <div class="flex items-center space-x-8">
        <sf-button :disabled="!!errorMessage">Save changes</sf-button>
        <div class="text-red-700 mb-2">
          {{ errorMessage }}
        </div>
      </div>
    </sf-annotated-section>
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </sf-form>
</template>

<script>
import SfAnnotatedSection from '@/components/AnnotatedSection'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfContextHelp from '@/components/ContextHelp'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import apiService from '@/services/apiService'
import confirmUnsaved from '@/mixins/confirmUnsaved'
import isCidr from 'is-cidr'
import isValidDomain from 'is-valid-domain'
import { isIP } from 'is-ip'
import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import SfHomenet from '@/components/SfHomenet'
import { validateHomenets, cleanupHomenets } from '@/helpers/validateHomenets'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfAnnotatedSection,
    SfButton,
    SfCard,
    SfCardSection,
    SfContextHelp,
    SfForm,
    SfFormItem,
    SfInput,
    SfHomenet,
    SfStack,
    SfStackItem,
  },

  async preload({ route }) {
    const { data: settings } = await apiService.getInstrumentSettings(
      route.params.id.split('@')[1],
      route.params.id
    )
    const { data: instrument } = await apiService.getInstrument(
      route.params.id.split('@')[1],
      route.params.id
    )

    // Add empty static route and DNS if they don't exist at all.
    // It's removed at save if left unchanged.
    if (!settings.routes || settings.routes.length === 0) {
      Vue.set(settings, 'routes', [
        {
          network: '',
          gateway: '',
        },
      ])
    }
    if (
      !settings.dns ||
      !settings.dns.nameservers ||
      settings.dns.nameservers.length === 0
    ) {
      Vue.set(settings, 'dns', { nameservers: [''] })
    }

    return {
      settings,
      instrument,
    }
  },

  data() {
    return {
      newHomenetName: '',
      homenetRefs: [],
    }
  },

  watch: {
    settings: {
      deep: true,
      handler() {
        this.setDirty()
      },
    },
    newHomenetName() {
      this.setDirty()
    },
    'settings.homenets_enabled'(newValue, oldValue) {
      this.setDirty()
      if (newValue && !oldValue) {
        this.$nextTick(() => {
          this.$refs.newHomenetName.focus()
        })
      }
    },
  },

  computed: {
    dnsServersValid() {
      const nameservers = this.settings.dns.nameservers

      for (const server of nameservers) {
        if (server === '') {
          //Allow empty ones. We remove them when saving.
          continue
        }
        if (!isValidDomain(server) && !isIP(server)) {
          return false
        }
      }

      return true
    },

    staticRoutesValid() {
      const routes = this.settings.routes
      for (const route of routes) {
        if (this.routeIsEmpty(route)) {
          //Extra empty routes are removed on save
          continue
        }
        if (!isCidr(route.network) || !isIP(route.gateway)) {
          return false
        }
      }
      return true
    },

    errorMessage() {
      if (!this.dnsServersValid) {
        return 'Invalid DNS server!'
      } else if (!this.staticRoutesValid) {
        return 'Invalid static route!'
      } else if (!validateHomenets(this.settings.homenets)) {
        return 'Invalid home network range!'
      }
      return ''
    },
  },

  methods: {
    addDnsServer() {
      this.settings.dns.nameservers.push('')
    },

    addStaticRoute() {
      this.settings.routes.push({
        network: '',
        gateway: '',
      })
    },

    cleanupDnsServers(dnsServers, minItems) {
      if (dnsServers.length <= minItems) {
        return
      }
      for (const dns of cloneDeep(dnsServers)) {
        if (dns === '') {
          //dns server is empty and should be removed
          dnsServers.splice(
            dnsServers.findIndex((e) => e === ''),
            1
          )
        }
      }
    },

    cleanupStaticRoutes(routes, minItems) {
      if (routes.length <= minItems) {
        return
      }
      for (const route of cloneDeep(routes)) {
        if (this.routeIsEmpty(route)) {
          //route is empty and should be removed
          routes.splice(
            routes.findIndex((e) => this.routeIsEmpty(e)),
            1
          )
        }
      }
    },

    routeIsEmpty(route) {
      return route.network === '' && route.gateway === ''
    },

    isValidDnsServer(dnsServer) {
      return !!dnsServer
    },

    removeDnsServer(index) {
      this.settings.dns.nameservers.splice(index, 1)
      if (this.settings.dns.nameservers.length === 0) {
        this.settings.dns.nameservers.push('')
      }
    },

    removeStaticRoute(index) {
      this.settings.routes.splice(index, 1)
      if (this.settings.routes.length === 0) {
        this.settings.routes.push({ network: '', gateway: '' })
      }
    },

    addHomenet() {
      if (!this.newHomenetName) {
        return
      }
      this.settings.homenets.push({ name: this.newHomenetName, networks: [''] })
      this.newHomenetName = ''
      this.$nextTick(() => {
        const lastHomenetRef = this.homenetRefs[this.homenetRefs.length - 1]
        lastHomenetRef.focusFirstRangeInput()
        lastHomenetRef.$el.scrollIntoView()
      })
    },

    removeHomenet(index) {
      this.settings.homenets.splice(index, 1)
    },

    async save() {
      //Remove all empty routes, dns servers and homenets from edited data,
      //except always leave one empty row.
      this.cleanupStaticRoutes(this.settings.routes, 1)
      this.cleanupDnsServers(this.settings.dns.nameservers, 1)
      cleanupHomenets(this.settings.homenets, 1)

      //create a deep copy of settings that we can edit when saving,
      //without affecting the UX
      let settings = cloneDeep(this.settings)

      //Remove all empty routes, dns servers and homenets from saved data
      this.cleanupStaticRoutes(settings.routes, 0)
      this.cleanupDnsServers(settings.dns.nameservers, 0)
      cleanupHomenets(settings.homenets, 0)

      // Do saving
      try {
        await apiService.updateInstrumentSettings(
          this.$route.params.id.split('@')[1],
          this.$route.params.id,
          settings
        )
        this.$emit('refresh')
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Configuration saved',
        })
        this.clearDirty()
      } catch (err) {
        await this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Failed to save configuration',
        })
      }
    },

    setHomenetRef(el) {
      if (el) {
        this.homenetRefs.push(el)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.grid > .row {
  display: flex;
}

.grid > .row + .row {
  margin-top: 2rem;
}

.grid > .row > .input {
  flex: 1;
}

.grid > .row > * + * {
  margin-left: 2rem;
}

.grid > .row > .actions {
  flex: 0 0 140px;
}

.grid > .is-header {
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}
</style>
