<template>
  <div class="app">
    <div class="header">
      <sf-navbar
        @change-password="changePassword = true"
        @edit-profile="editProfile = true"
      />
    </div>
    <div class="body">
      <router-view />
    </div>
    <sf-toast-center />
    <sf-change-password-dialog
      v-if="changePassword"
      :force-change="user.change_password"
      :user-id="user.user_id"
      @error="onChangePasswordError"
      @success="onChangePasswordSuccess"
      @close="changePassword = false"
    />
    <sf-edit-profile-dialog
      v-if="editProfile"
      :user-id="user.user_id"
      :initial-data="{ ...user }"
      @error="onEditProfileError"
      @success="onEditProfileSuccess"
      @close="editProfile = false"
    />
    <portal-target name="dropdown" multiple />
    <portal-target name="modal" />
    <portal-target name="drawer" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SfChangePasswordDialog from '@/components/ChangePasswordDialog'
import SfEditProfileDialog from '@/components/EditProfileDialog'
import SfNavbar from '@/components/SfNavbar.vue'
import SfToastCenter from '@/components/ToastCenter'

export default {
  components: {
    SfChangePasswordDialog,
    SfEditProfileDialog,
    SfNavbar,
    SfToastCenter,
  },
  data() {
    return {
      changePassword: false,
      editProfile: false,
    }
  },
  created() {
    if (this.user && this.user.change_password) {
      this.changePassword = true
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    onChangePasswordError() {
      this.$store.dispatch('toasts/raise', {
        status: 'alert',
        message: 'Failed to update password',
      })
    },
    onChangePasswordSuccess() {
      this.changePassword = false
      this.$store.dispatch('toasts/raise', {
        status: 'success',
        message: 'Password updated',
      })
    },
    onEditProfileError() {
      this.$store.dispatch('toasts/raise', {
        status: 'alert',
        message: 'Failed to update profile information',
      })
    },
    onEditProfileSuccess() {
      this.editProfile = false
      this.$store.dispatch('toasts/raise', {
        status: 'success',
        message: 'Profile information updated',
      })
    },
  },
}
</script>
