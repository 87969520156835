<template>
  <sf-base-view title="Settings">
    <sf-tabs slot="tabs">
      <sf-tabs-item to="/settings/users">Users</sf-tabs-item>
      <sf-tabs-item to="/settings/api-access">API access</sf-tabs-item>
    </sf-tabs>
    <router-view />
  </sf-base-view>
</template>

<script>
import SfBaseView from '@/components/BaseView.vue'
import SfTabs from '@/components/SfTabs.vue'
import SfTabsItem from '@/components/TabsItem.vue'

export default {
  components: {
    SfBaseView,
    SfTabs,
    SfTabsItem,
  },
}
</script>
