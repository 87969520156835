<template>
  <sf-modal
    v-if="visible"
    v-on="busy ? {} : { close: cancel }"
    :title="title"
    width="narrow"
  >
    <slot />
    <template slot="actions">
      <sf-button-group>
        <sf-button full-width :disabled="busy" plain @click="cancel">
          Cancel
        </sf-button>
        <sf-button full-width :loading="busy" @click="confirm">
          {{ action }}
        </sf-button>
      </sf-button-group>
    </template>
  </sf-modal>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfModal from '@/components/SfModal.vue'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfModal,
  },
  props: {
    title: {
      type: String,
    },
    action: {
      type: String,
      default: 'Delete',
    },
  },
  data() {
    return {
      fn: null,
      busy: false,
      visible: false,
      reject: null,
      resolve: null,
    }
  },
  methods: {
    open(fn) {
      this.visible = true
      return new Promise((resolve, reject) => {
        this.fn = typeof fn === 'function' ? fn : () => true
        this.reject = reject
        this.resolve = resolve
      })
    },
    async confirm() {
      this.busy = true
      try {
        const result = await this.fn()
        this.visible = false
        this.resolve(result)
      } catch (err) {
        this.reject(err)
      } finally {
        this.busy = false
      }
    },
    cancel() {
      this.resolve(false)
      this.visible = false
    },
  },
}
</script>
