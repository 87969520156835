<template>
  <sf-modal title="Add token" width="narrow" @close="$emit('close')">
    <sf-form @submit.prevent="save">
      <sf-form-item label="Description">
        <sf-input ref="description" v-model="form.data.description" required />
      </sf-form-item>
      <sf-form-item label="Privileges">
        <sf-checkbox
          v-for="option in privilegeOptions"
          :key="option"
          v-model="form.data.privileges"
          :label="option"
          :value="option"
          required
        />
      </sf-form-item>
      <div class="flex justify-end mt-4">
        <sf-button-group>
          <sf-button
            :disabled="form.busy"
            plain
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </sf-button>
          <sf-button
            :disabled="!form.data.privileges.length"
            :loading="form.busy"
          >
            Save
          </sf-button>
        </sf-button-group>
      </div>
    </sf-form>
  </sf-modal>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCheckbox from '@/components/SfCheckbox'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import { TOKEN_PRIVILEGES } from '@/helpers/privileges'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfCheckbox,
    SfForm,
    SfFormItem,
    SfInput,
    SfModal,
  },
  data() {
    return {
      form: {
        busy: false,
        data: {
          description: '',
          privileges: [],
        },
      },
    }
  },
  created() {
    this.privilegeOptions = TOKEN_PRIVILEGES
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.description.focus()
    })
  },
  methods: {
    async save() {
      this.form.busy = true
      try {
        const token = await this.$store.dispatch('apiTokens/createToken', {
          data: this.form.data,
        })
        this.$emit('success', token)
      } catch (error) {
        this.$emit('error')
      } finally {
        this.form.busy = false
      }
    },
  },
}
</script>
