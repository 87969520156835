import apiService from '@/services/apiService'

export default {
  namespaced: true,
  state: {
    users: null,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
  },
  actions: {
    async createUser({ dispatch }, { data }) {
      await apiService.createUser(data)
      await dispatch('getUsers')
    },
    async deleteUser({ dispatch }, { userId }) {
      await apiService.deleteUser(userId)
      await dispatch('getUsers')
    },
    async getUser(_, userId) {
      await apiService.getUser(userId)
    },
    async getUsers({ commit }) {
      const { data: users } = await apiService.getUsers()
      commit('setUsers', users)
    },
    async updateUser({ dispatch, rootState, rootGetters }, { userId, data }) {
      await apiService.updateUser(userId, data)
      if (rootState.auth.user.user_id === userId) {
        await dispatch('auth/getSession', null, { root: true })
      }
      if (rootGetters['auth/isAdmin']) {
        await dispatch('getUsers')
      }
    },
  },
}
