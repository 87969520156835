<template>
  <sf-card>
    <sf-card-section>
      <sf-schema-form-item :label="title" :help="help">
        <sf-choice-group>
          <sf-checkbox
            v-for="(option, index) in options"
            :label="option"
            :value="option"
            :key="index"
            :model="value"
            v-on="$listeners"
          />
        </sf-choice-group>
      </sf-schema-form-item>
    </sf-card-section>
  </sf-card>
</template>
<script>
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfSchemaFormItem from '@/components/SchemaFormItem'
import SfCheckbox from '@/components/SfCheckbox'
import SfChoiceGroup from '@/components/ChoiceGroup'

export default {
  components: {
    SfCard,
    SfCardSection,
    SfSchemaFormItem,
    SfCheckbox,
    SfChoiceGroup,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    help: {
      type: String,
    },
    value: {
      type: [Array],
    },
    options: {
      type: [Array],
    },
    cy: {
      type: String,
    },
  },
}
</script>
