<script>
export default {
  props: {
    handle: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    const listener = (evt) => {
      if (evt.target === this.$el || this.$el.contains(evt.target)) {
        return
      }
      this.handle()
    }
    document.addEventListener('click', listener)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('click', listener)
    })
  },
  render() {
    return this.$slots.default[0]
  },
}
</script>
