<template>
  <div class="form-item" :cy="cy">
    <label class="label" v-if="label">{{ label }}</label>
    <div class="help" v-if="help">{{ help }}</div>
    <div class="input">
      <slot />
    </div>
    <div class="mt-1 text-red-600" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    cy: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-item + .form-item {
  margin-top: 2rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
}

.help {
  color: $grey-600;
  display: block;
  margin-bottom: 0.75rem;
  margin-top: -0.25rem;
}
</style>
