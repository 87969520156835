import sensorName from '@/filters/sensorName'
import apiService from '@/services/apiService'

export default {
  computed: {
    sensorOptions() {
      let options = [
        ...this.sensors.map((sensor) => ({
          label:
            sensorName(sensor.id, sensor.friendly_name) +
            this.sensorOfflineText(sensor.id),
          value: sensor.id,
          disabled: !!this.sensorOfflineText(sensor.id),
        })),
      ]

      if (this.mode === 'sensor') {
        // Show 'Any' selection only for sensor because we cannot
        // search for events in all sensors at the moment
        options = [{ label: 'Any', value: '' }, ...options]
      }

      return options
    },
  },

  async created() {
    await this.updateSensors()
  },

  methods: {
    sensorOfflineText(id) {
      return this.sensors.filter((sensor) => sensor.id === id)[0].online
        ? ''
        : ' (offline!)'
    },

    async updateSensors() {
      this.sensorsPoller = setInterval(async () => {
        const { data: sensors } = await apiService.getSensors()
        this.sensors = sensors
      }, 10000)
    },
  },

  beforeDestroy() {
    clearInterval(this.sensorsPoller)
  },
}
