<template>
  <sf-modal title="Edit user" width="narrow" @close="$emit('close')">
    <sf-form @submit.prevent="save">
      <sf-form-item label="Username">
        <sf-input ref="username" v-model="form.data.username" required />
      </sf-form-item>
      <sf-form-item label="Full name">
        <sf-input v-model="form.data.full_name" />
      </sf-form-item>
      <sf-form-item label="Email address">
        <sf-input v-model="form.data.email" type="email" />
      </sf-form-item>
      <sf-form-item label="Privileges">
        <sf-checkbox
          v-for="option in privilegeOptions"
          :key="option"
          v-model="form.data.privileges"
          :label="option"
          :value="option"
          required
        />
      </sf-form-item>
      <div class="flex justify-end mt-4">
        <sf-button-group>
          <sf-button
            :disabled="form.busy"
            plain
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </sf-button>
          <sf-button :loading="form.busy"> Save </sf-button>
        </sf-button-group>
      </div>
    </sf-form>
  </sf-modal>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCheckbox from '@/components/SfCheckbox'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import { USER_PRIVILEGES } from '@/helpers/privileges'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfCheckbox,
    SfForm,
    SfFormItem,
    SfInput,
    SfModal,
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        busy: false,
        data: {
          username: this.initialData.username || '',
          full_name: this.initialData.full_name || '',
          email: this.initialData.email || '',
          privileges: this.initialData.privileges || [],
        },
      },
    }
  },
  created() {
    this.privilegeOptions = USER_PRIVILEGES
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.username.focus()
    })
  },
  methods: {
    async save() {
      this.form.busy = true
      try {
        await this.$store.dispatch('users/updateUser', {
          userId: this.userId,
          data: this.form.data,
        })
        this.$emit('success', { username: this.form.data.username })
      } catch (error) {
        this.$emit('error')
      } finally {
        this.form.busy = false
      }
    },
  },
}
</script>
