<template>
  <sf-drawer title="Event details" @close="$emit('close')">
    <sf-card>
      <sf-card-section>
        <sf-json-pretty :data="event" />
      </sf-card-section>
    </sf-card>
  </sf-drawer>
</template>

<script>
import SfJsonPretty from '@/components/JsonPretty'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfDrawer from '@/components/SfDrawer'

export default {
  components: {
    SfJsonPretty,
    SfCard,
    SfCardSection,
    SfDrawer,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
}
</script>
