<template>
  <div class="card-section">
    <div v-if="title" class="header">
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 2rem 2rem 0;
}

.title {
  color: $grey-700;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.body {
  padding: 2rem;
}

.card-section:not(:first-child) {
  border-top: 1px solid $grey-200;
}
</style>
