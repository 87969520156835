<template>
  <div>
    <div class="flex items-center mb-8">
      <div class="flex-1">
        <h1 class="text-4xl">Tokens</h1>
      </div>
      <div class="w-96">
        <sf-input
          v-model="tokenQuery"
          placeholder="Filter tokens by description"
        />
      </div>
      <div class="ml-4">
        <sf-button @click="addDialog.visible = true">Add token</sf-button>
      </div>
    </div>
    <sf-card v-if="filteredTokens.length">
      <sf-table
        :columns="columns"
        :data="filteredTokens"
        :page-size="1000"
        responsive
      >
        <template slot="column-description" slot-scope="{ row: token }">
          <div>
            {{ token.description }}
          </div>
        </template>
        <template slot="column-created" slot-scope="{ row: token }">
          <div>
            {{ token.created | timestamp }}
          </div>
          <div class="text-2xl text-gray-400">
            {{ token.created | relativeTime }}
          </div>
        </template>
        <template slot="column-privileges" slot-scope="{ row: token }">
          <div class="flex flex-wrap gap-2 sm:flex-nowrap">
            <div
              class="bg-gray-100 border-b border-gray-300 px-2 py-px rounded text-2xl"
              v-for="privilege in token.privileges"
              :key="privilege"
            >
              {{ privilege }}
            </div>
          </div>
        </template>
        <template slot="column-actions" slot-scope="{ row: token }">
          <sf-button
            plain
            size="small"
            @click="deleteToken(token.id, token.description)"
          >
            Delete
          </sf-button>
        </template>
      </sf-table>
    </sf-card>
    <div v-else-if="tokens.length && !filteredTokens.length">
      <sf-notification title="No tokens found">
        No results found for "{{ tokenQuery }}".
        <button class="link" @click="tokenQuery = ''">Clear query</button>
      </sf-notification>
    </div>
    <div v-else>
      <sf-notification title="No tokens found">
        You don't seem to have any tokens yet.
        <button class="link" @click="addDialog.visible = true">
          Add token
        </button>
      </sf-notification>
    </div>
    <sf-add-token-dialog
      v-if="addDialog.visible"
      @close="addDialog.visible = false"
      @success="onAddSuccess"
      @error="onAddError"
    />
    <sf-modal
      v-if="addedDialog.visible"
      title="Token created"
      width="narrow"
      @close="addedDialog.visible = false"
    >
      <p>
        Your token has been created. Token can be used with
        <span class="bg-gray-100 border-b px-2 py-0.5 rounded whitespace-nowrap"
          >X-Sensorfleet-Api-Token</span
        >
        HTTP header or with
        <span class="bg-gray-100 border-b px-2 py-0.5 whitespace-nowrap"
          >api-token</span
        >
        URL parameter.
        <span class="font-bold"
          >Make sure to copy the token, as this is the only time it is
          shown.</span
        >
      </p>
      <sf-textarea
        ref="token"
        class="block mb-4 mt-4 w-full"
        :value="addedDialog.token"
        rows="2"
        readonly
        @click="selectToken"
      />
      <sf-button full-width @click="copyToken">
        <font-awesome-icon slot="icon" :icon="icons.faCopy" />
        Copy
      </sf-button>
    </sf-modal>
    <sf-confirm-dialog ref="deleteDialog" title="Delete token?">
      Are you sure you want to delete token
      <span class="font-bold">{{ deleteDialog.description }}</span
      >? This action cannot be undone.
    </sf-confirm-dialog>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfConfirmDialog from '@/components/ConfirmDialog'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import SfNotification from '@/components/SfNotification'
import SfTable from '@/components/SfTable'
import SfTextarea from '@/components/SfTextarea'
import relativeTime from '@/filters/relativeTime'
import timestamp from '@/filters/timestamp'
import SfAddTokenDialog from './_components/AddTokenDialog'
import { mapState } from 'vuex'

export default {
  inject: ['$progressBar'],
  components: {
    FontAwesomeIcon,
    SfAddTokenDialog,
    SfButton,
    SfCard,
    SfConfirmDialog,
    SfInput,
    SfModal,
    SfNotification,
    SfTable,
    SfTextarea,
  },
  filters: {
    relativeTime,
    timestamp,
  },
  async preload({ store }) {
    await store.dispatch('apiTokens/getTokens')
  },
  data() {
    return {
      addDialog: {
        visible: false,
      },
      addedDialog: {
        visible: false,
        token: null,
      },
      deleteDialog: {
        description: null,
      },
      columns: [
        {
          field: 'description',
          label: 'Description',
          width: '99%',
        },
        {
          field: 'privileges',
          label: 'Privileges',
        },
        {
          field: 'created',
          label: 'Created',
        },
        {
          field: 'actions',
          label: 'Actions',
        },
      ],
      tokenQuery: '',
    }
  },
  created() {
    this.icons = {
      faCopy,
    }
  },
  computed: {
    ...mapState('apiTokens', ['tokens']),
    filteredTokens() {
      return [...this.tokens]
        .filter((token) => {
          const query = this.tokenQuery ? this.tokenQuery.toLowerCase() : ''
          return !query || token.description.toLowerCase().indexOf(query) !== -1
        })
        .sort((a, b) => a.description.localeCompare(b.description))
    },
  },
  methods: {
    async copyToken() {
      try {
        await navigator.clipboard.writeText(this.addedDialog.token)
        this.selectToken()
        document.execCommand('copy')
        this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Token copied to clipboard',
        })
      } catch (error) {
        this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Failed to copy the token, please copy it manually',
        })
      }
    },
    async deleteToken(id, description) {
      this.deleteDialog.description = description
      try {
        await this.$refs.deleteDialog.open(async () => {
          await this.$store.dispatch('apiTokens/deleteToken', { tokenId: id })
          this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Token deleted',
          })
        })
      } catch (error) {
        this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Failed to delete token',
        })
      }
    },
    selectToken() {
      this.$refs.token.select()
    },
    onAddError() {
      this.$store.dispatch('toasts/raise', {
        status: 'alert',
        message: 'Failed to add token',
      })
    },
    onAddSuccess(token) {
      this.addDialog.visible = false
      this.addedDialog.visible = true
      this.addedDialog.token = token
      this.tokenQuery = ''
    },
  },
}
</script>
