<template>
  <div>
    <sf-annotated-section title="Software repository">
      <template slot="help">
        Software repository and release branch currently in use.
      </template>
      <sf-card>
        <sf-card-section>
          <sf-stack>
            <sf-stack-item>
              <strong>{{ this.repository || 'Unknown' }}</strong>
            </sf-stack-item>
          </sf-stack>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-annotated-section title="Software upgrade">
      <template slot="help">
        Keep the Sensor software updated by checking for updates periodically.
      </template>
      <sf-card>
        <sf-card-section>
          <sf-stack>
            <sf-stack-item>
              Current version:
              <strong>{{ this.currentVersion }}</strong>
            </sf-stack-item>
            <sf-stack-item
              v-if="
                this.availableVersion &&
                this.currentVersion !== this.availableVersion
              "
            >
              Available version: <strong>{{ this.availableVersion }}</strong>
            </sf-stack-item>
            <sf-stack-item v-else>Sensor is up-to-date.</sf-stack-item>
          </sf-stack>
          <sf-stack v-if="this.instrumentUpgrades">
            <sf-stack-item
              ><strong>Instrument upgrades available.</strong></sf-stack-item
            >
          </sf-stack>
          <sf-stack v-if="this.baseSystemUpgrades">
            <sf-stack-item
              ><strong>Base system upgrades available.</strong></sf-stack-item
            >
          </sf-stack>
        </sf-card-section>
        <sf-card-section>
          <sf-button-group>
            <sf-button
              type="button"
              @click="checkForUpdates"
              :disabled="!this.socketConnected"
            >
              Upgrade Sensor
            </sf-button>
          </sf-button-group>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-upgrade-dialog
      v-if="upgrading"
      :updateStatus="updateStatus"
      :upgradeStatus="upgradeStatus"
      @close="closeUpgradeDialog"
      @submit="startUpgrade"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SfAnnotatedSection from '@/components/AnnotatedSection'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import apiService from '@/services/apiService'
import SfUpgradeDialog from './_components/UpgradeDialog'
import { Handled } from '@/helpers/errors'
import confirmUnsaved from '@/mixins/confirmUnsaved'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfAnnotatedSection,
    SfButton,
    SfButtonGroup,
    SfCard,
    SfCardSection,
    SfStack,
    SfStackItem,
    SfUpgradeDialog,
  },

  async preload({ route }) {
    const { data: version } = await apiService.getSensorVersion(route.params.id)

    return {
      currentVersion: version['current_version'],
      availableVersion: version['available_version'],
      instrumentUpgrades: version['instruments'] || null,
      baseSystemUpgrades: version['base_system'] || null,
      repository: version['repository'],
    }
  },

  data() {
    return {
      updateJobId: null,
      upgradeJobId: null,
      upgrading: false,
    }
  },

  computed: {
    ...mapState(['id', 'mode', 'jobs', 'socketConnected']),

    updateStatus() {
      if (!this.updateJobId || !this.jobs[this.updateJobId]) {
        return null
      } else {
        return this.jobs[this.updateJobId]
      }
    },

    upgradeStatus() {
      if (!this.upgradeJobId || !this.jobs[this.upgradeJobId]) {
        return null
      } else {
        return this.jobs[this.upgradeJobId]
      }
    },
  },

  methods: {
    async checkForUpdates() {
      this.upgrading = true
      await this.$store.dispatch('removeFinishedJobs')
      try {
        const { data: resp } = await apiService.createCheckSensorUpdatesJob(
          this.$route.params.id
        )
        this.updateJobId = resp.job.id
        this.$socket.sendObj({
          type: 'job_subscription',
          id: resp.job.id,
        })
      } catch (err) {
        this.upgrading = false
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to check for updates',
          })
        }
      }
    },

    async closeUpgradeDialog() {
      const { data: version } = await apiService.getSensorVersion(
        this.$route.params.id
      )
      this.currentVersion = version['current_version']
      this.availableVersion = version['available_version']
      this.instrumentUpgrades = version['instruments']
      this.baseSystemUpgrades = version['base_system']
      this.updateJobId = null
      this.upgrading = false
      await this.$store.dispatch('removeFinishedJobs')
    },

    async startUpgrade() {
      try {
        const { data: resp } = await apiService.createSensorUpgradeJob(
          this.$route.params.id
        )
        this.upgradeJobId = resp.job.id
        this.$socket.sendObj({
          type: 'job_subscription',
          id: resp.job.id,
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to upgrade Sensor',
          })
        }
      }
    },
  },
}
</script>
