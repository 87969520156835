import { render, staticRenderFns } from "./SfTooltip.vue?vue&type=template&id=1bac0074&scoped=true&"
import script from "./SfTooltip.vue?vue&type=script&lang=js&"
export * from "./SfTooltip.vue?vue&type=script&lang=js&"
import style0 from "./SfTooltip.vue?vue&type=style&index=0&id=1bac0074&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bac0074",
  null
  
)

export default component.exports