<template>
  <sf-form @submit.prevent="$emit('submit')">
    <sf-annotated-section title="Retention">
      <template slot="help" v-if="hideEvents">
        Delete Instrument data older than the defined time period.
      </template>
      <template slot="help" v-else>
        Delete event and Instrument data older than the defined time period.
        Instrument configuration can override time period for data.
      </template>
      <sf-card>
        <sf-card-section>
          <sf-form-item
            label="Delete events older than"
            v-if="!hideEvents"
            cy="delete-events"
          >
            <sf-duration-input v-model="rules.delete_event_age" />
          </sf-form-item>
          <sf-form-item
            label="Delete Instrument data older than"
            cy="delete-data"
          >
            <sf-duration-input
              v-model="rules.delete_data_age"
              :placeholder="placeholder.delete_data_age"
            />
          </sf-form-item>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-annotated-section title="Alerts">
      <template slot="help" v-if="hideEvents">
        Raise alerts for Instrument data older than the defined time period.
      </template>
      <template slot="help" v-else>
        Raise alerts for event and Instrument data older than the defined time
        period. Instrument configuration can override time period for data.
      </template>
      <sf-card>
        <sf-card-section>
          <sf-form-item
            label="Raise alerts for events older than"
            v-if="!hideEvents"
            cy="alert-events"
          >
            <sf-duration-input v-model="rules.alert_event_age" />
          </sf-form-item>
          <sf-form-item
            label="Raise alerts for Instrument data older than"
            cy="alert-data"
          >
            <sf-duration-input
              v-model="rules.alert_data_age"
              :placeholder="placeholder.alert_data_age"
            />
          </sf-form-item>
        </sf-card-section>
      </sf-card>
    </sf-annotated-section>
    <sf-annotated-section>
      <sf-button> Save changes </sf-button>
    </sf-annotated-section>
  </sf-form>
</template>

<script>
import SfAnnotatedSection from '@/components/AnnotatedSection'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfDurationInput from '@/components/DurationInput'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'

export default {
  components: {
    SfAnnotatedSection,
    SfButton,
    SfCard,
    SfCardSection,
    SfDurationInput,
    SfForm,
    SfFormItem,
  },

  props: {
    value: {
      type: Object,
    },
    placeholder: {
      type: Object,
      default: function () {
        return {}
      },
    },
    submit: {
      type: Function,
    },
    hideEvents: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    rules: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
        this.$emit('dirty')
      },
    },
  },

  data() {
    return {
      rules: {
        delete_event_age: undefined,
        delete_data_age: undefined,
        alert_event_age: undefined,
        alert_data_age: undefined,
        ...this.value,
      },
    }
  },
}
</script>
