<template>
  <sf-table :columns="columns" :data="interfaces">
    <template slot="column-name" slot-scope="{ row }">
      {{ row.friendly_name }}
    </template>
    <template slot="column-type" slot-scope="{ row }">
      {{ row.type }}
    </template>
    <template slot="column-ip_address" slot-scope="{ row }">
      {{ row.ip_addresses ? row.ip_addresses[0] : '' }}
    </template>
    <template slot="column-actions" slot-scope="{ row }">
      <sf-button-group segmented>
        <sf-button plain size="small" @click="$emit('edit', row)">
          Edit
        </sf-button>
        <sf-tooltip :disabled="!row.users">
          <sf-button
            slot="trigger"
            plain
            size="small"
            :disabled="!!row.users"
            @click="$emit('delete', row.id)"
          >
            Delete
          </sf-button>
          {{ getInterfaceUserString(row.users) }}
        </sf-tooltip>
      </sf-button-group>
    </template>
  </sf-table>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfTable from '@/components/SfTable'
import SfTooltip from '@/components/SfTooltip'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfTable,
    SfTooltip,
  },

  props: {
    interfaces: {
      type: Array,
    },
  },

  data() {
    return {
      columns: [
        {
          field: 'name',
          label: 'Name',
        },
        {
          field: 'type',
          label: 'Type',
        },
        {
          field: 'ip_address',
          label: 'IP address',
          width: '99%',
        },
        {
          field: 'actions',
          label: 'Actions',
          align: 'right',
        },
      ],
    }
  },

  methods: {
    getInterfaceUserString(users) {
      if (!users) {
        return null
      }
      let str = 'Bridge is used by '
      for (let i = 0; i < users.length; i++) {
        str += users[i].split('@')[0]
        if (i < users.length - 2) {
          str += ', '
        } else if (i < users.length - 1) {
          str += ' and '
        }
      }
      return str
    },
  },
}
</script>
