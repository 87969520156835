<template>
  <label :class="classes">
    <div v-if="$slots.prepend" class="prepend">
      <slot name="prepend" />
    </div>
    <input
      ref="input"
      class="control"
      :autocapitalize="autocapitalize"
      :disabled="disabled"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      :required="required"
      :type="type"
      :value="value"
      :cy="cy"
      :name="name"
      :autocomplete="autocomplete"
      :readonly="readonly"
      @change="$emit('change', $event)"
      @focus="focused = true"
      @blur="focused = false"
      @input="handleInput"
      @keydown="$emit('keydown', $event)"
      @keyup.enter="$emit('keyup', $event)"
    />
    <div v-if="$slots.append" class="append">
      <slot name="append" />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    autocapitalize: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
    placeholder: {
      type: [String, Number],
    },
    required: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    width: {
      type: String,
    },
    cy: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
  },

  data() {
    return {
      focused: false,
    }
  },

  computed: {
    classes() {
      return {
        input: true,
        'is-focused': this.focused,
        'is-disabled': this.disabled,
        'is-appended': this.$slots.append,
        'is-numeric': this.type === 'number',
      }
    },
  },

  methods: {
    focus() {
      this.$refs.input.focus()
    },

    handleInput(event) {
      this.$emit('input', event.target.value)
    },

    select() {
      this.$refs.input.select()
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  align-items: center;
  background: $white;
  border: 1px solid $grey-400;
  border-radius: 3px;
  display: flex;
  width: 100%;
}

.control {
  background: none;
  border: 0;
  padding: 0.6rem 1.2rem;
  width: 100%;
}

.control::placeholder {
  color: $grey-500;
}

.control:focus {
  outline: 0;
  position: relative;
}

.prepend {
  color: $grey-600;
  font-size: 1.4rem;
  font-weight: 500;
  padding-left: 1.4rem;
  text-transform: uppercase;
}

.append {
  color: $grey-600;
  font-size: 1.4rem;
  font-weight: 500;
  padding-right: 1.4rem;
  text-transform: uppercase;
}

.is-focused {
  border-color: $blue-500;
  box-shadow: 0 0 0 2px $blue-100;
}

.is-disabled {
  background: $grey-000;
}

.is-disabled .control {
  cursor: not-allowed;
}

.is-numeric.is-appended .control {
  text-align: right;
}
</style>
