export const Privilege = Object.freeze({
  ADMIN_READ: 'admin:read',
  ADMIN_WRITE: 'admin:write',
  USER_READ: 'user:read',
  USER_WRITE: 'user:write',
  INSTRUMENT_ALL: 'instrument:all',
})

export const TOKEN_PRIVILEGES = [
  Privilege.ADMIN_READ,
  Privilege.ADMIN_WRITE,
  Privilege.USER_READ,
  Privilege.USER_WRITE,
  Privilege.INSTRUMENT_ALL,
]

export const USER_PRIVILEGES = [
  Privilege.ADMIN_READ,
  Privilege.ADMIN_WRITE,
  Privilege.USER_READ,
  Privilege.USER_WRITE,
  Privilege.INSTRUMENT_ALL,
]
