<template>
  <sf-table :columns="columns" :data="interfaces">
    <template slot="column-name" slot-scope="{ row }">
      {{ row.friendly_name }}
    </template>
    <template slot="column-sensor_bridge" slot-scope="{ row }">
      {{ sensorBridgeFriendlyName(row.sensor_bridge_name) }}
    </template>
    <template slot="column-capabilities" slot-scope="{ row }">
      {{ capabilities(row.capabilities) }}
    </template>
    <template slot="column-ip_address" slot-scope="{ row }">
      {{ getIpAddress(row) }}
    </template>
    <template slot="column-actions" slot-scope="{ row }">
      <sf-button-group segmented>
        <sf-button plain size="small" @click="$emit('edit', row)">
          Edit
        </sf-button>
        <sf-button plain size="small" @click="$emit('delete', row.id)">
          Delete
        </sf-button>
      </sf-button-group>
    </template>
  </sf-table>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfTable from '@/components/SfTable'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfTable,
  },

  props: {
    interfaces: {
      type: Array,
    },
    sensorInterfaces: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          field: 'name',
          label: 'Name',
        },
        {
          field: 'sensor_bridge',
          label: 'Sensor bridge',
        },
        {
          field: 'capabilities',
          label: 'Capabilities',
        },
        {
          field: 'ip_address',
          label: 'IP address',
          width: '99%',
        },
        {
          field: 'actions',
          label: 'Actions',
          align: 'right',
        },
      ],
    }
  },

  methods: {
    getIpAddress(row) {
      if (row.dhcp_client) {
        return 'DHCP in use'
      }
      return row.ip_addresses ? row.ip_addresses[0] : ''
    },

    capabilities(capabilities) {
      return capabilities.map((c) => c.toUpperCase()).join(',')
    },

    sensorBridgeFriendlyName(bridge) {
      if (!bridge) {
        return ''
      }
      return (
        this.sensorInterfaces.filter((iface) => iface.id === bridge)[0]
          .friendly_name || ''
      )
    },
  },
}
</script>
