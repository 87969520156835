<template>
  <paginate
    container-class="pagination"
    :page-count="Number(pageCount)"
    :value="Number(value)"
    @input="(v) => $emit('input', v)"
  />
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  components: {
    Paginate,
  },

  props: {
    pageCount: {
      type: [Number, String],
    },
    value: {
      type: [Number, String],
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}

::v-deep li:not(:first-child) {
  margin-left: 1rem;
}

::v-deep a {
  background: linear-gradient(180deg, $grey-100, $grey-200);
  border: 1px solid $grey-500;
  border-radius: 3px;
  box-shadow: 0px 1px 2px $grey-200;
  color: $grey-700;
  display: block;
  outline: 0;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
}

::v-deep .active a {
  background: $grey-600;
  border-color: $grey-700;
  box-shadow: none;
  color: $white;
}

::v-deep .disabled a {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
