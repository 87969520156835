const statusesBestToWorst = ['info', 'ok', 'warning', 'error', 'critical']

export function worstStatus(statuses) {
  return [...statuses].sort(
    (a, b) => statusesBestToWorst.indexOf(b) - statusesBestToWorst.indexOf(a)
  )[0]
}

export function sortByStatus(extractStatus) {
  return function (a, b) {
    return (
      statusesBestToWorst.indexOf(extractStatus(b)) -
      statusesBestToWorst.indexOf(extractStatus(a))
    )
  }
}
