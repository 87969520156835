<template>
  <div>
    <sf-table
      :columns="columns"
      :data="instruments"
      :header="header"
      :row-cy="(row) => row.id"
    >
      <template #column-health="{ row }">
        <sf-tooltip :disabled="row.health.messages.length === 0">
          <sf-health slot="trigger" :status="row.health.status">
            {{ row.health.label }}
          </sf-health>
          <sf-health-messages :health="row.health" mode="instrument" />
        </sf-tooltip>
      </template>
      <template #column-name="{ row }" class="disabled">
        <div :class="disabled ? 'text-gray-500' : ''">
          {{ row.friendly_name || row.default_name || row.id.split('@')[0] }}
        </div>
      </template>
      <template #column-actions="{ row }">
        <sf-button-group segmented>
          <sf-button
            v-if="row.ui_path"
            plain
            size="small"
            :href="row.ui_path"
            target="_blank"
            :disabled="disabled"
          >
            <font-awesome-icon slot="icon" :icon="icons.faTv" /> UI
          </sf-button>
          <sf-button
            plain
            size="small"
            :to="{
              path: '/events/basic',
              query: { sensor: sensorId, source: row.id },
            }"
            :disabled="disabled"
          >
            <font-awesome-icon slot="icon" :icon="icons.faList" /> Events
          </sf-button>
          <sf-dropdown ref="dropdown">
            <template #trigger="{ toggleOpen }">
              <sf-button
                slot="trigger"
                plain
                size="small"
                :disabled="disabled"
                @click="toggleOpen"
              >
                <font-awesome-icon slot="icon" :icon="icons.faCaretDown" />
              </sf-button>
            </template>
            <sf-menu>
              <sf-menu-item :to="`/instruments/${row.id}/configure`">
                Configure
              </sf-menu-item>
              <sf-menu-item @click.stop="restartInstrument(row.id)">
                Restart
              </sf-menu-item>
              <sf-menu-item @click.stop="deleteInstrument(row.id)">
                Delete
              </sf-menu-item>
            </sf-menu>
          </sf-dropdown>
        </sf-button-group>
      </template>
    </sf-table>
    <sf-confirm-dialog ref="deleteConfirm" title="Delete Instrument?">
      <p>This action cannot be undone.</p>
    </sf-confirm-dialog>
    <sf-confirm-dialog
      action="Restart"
      ref="restartConfirm"
      title="Restart Instrument?"
    >
    </sf-confirm-dialog>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCaretDown, faList, faTv } from '@fortawesome/free-solid-svg-icons'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfConfirmDialog from '@/components/ConfirmDialog'
import SfDropdown from '@/components/SfDropdown'
import SfHealth from '@/components/SfHealth'
import SfMenu from '@/components/SfMenu.vue'
import SfMenuItem from '@/components/MenuItem'
import SfTable from '@/components/SfTable.vue'
import SfTooltip from '@/components/SfTooltip.vue'
import apiService from '@/services/apiService'
import SfHealthMessages from '@/components/SfHealthMessages'
import { Handled } from '@/helpers/errors'

export default {
  components: {
    SfHealthMessages,
    FontAwesomeIcon,
    SfButton,
    SfButtonGroup,
    SfConfirmDialog,
    SfDropdown,
    SfHealth,
    SfMenu,
    SfMenuItem,
    SfTable,
    SfTooltip,
  },
  props: {
    header: {
      type: Boolean,
      default: true,
    },
    sensorId: {
      type: String,
      required: true,
    },
    instruments: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Health',
          field: 'health',
          minWidth: '140px',
        },
        {
          label: 'Name',
          field: 'name',
          width: '99%',
        },
        {
          label: 'Actions',
          field: 'actions',
          align: 'right',
        },
      ],
    }
  },
  async created() {
    this.icons = {
      faCaretDown,
      faList,
      faTv,
    }
  },
  watch: {
    disabled(disabled) {
      if (!disabled) {
        this.$refs.dropdown.close()
      }
    },
  },
  methods: {
    async deleteInstrument(id) {
      try {
        await this.$refs.deleteConfirm.open(async () => {
          await apiService.deleteInstrument(this.sensorId, id)
          const { data } = await apiService.getInstruments(this.sensorId)
          this.$emit('on-instrument-delete', id, data)
          await this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Instrument deleted',
          })
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to delete Instrument',
          })
        }
      }
    },

    async restartInstrument(id) {
      try {
        await this.$refs.restartConfirm.open(async () => {
          await apiService.restartInstrument(this.sensorId, id)
          await this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Instrument restarting',
          })
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to restart Instrument',
          })
        }
      }
    },
  },
}
</script>
