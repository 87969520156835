<template>
  <ClipLoader
    :loading="this.loading"
    color="#343a40"
    class="spinner"
    :size="31"
  ></ClipLoader>
</template>

<script>
import { ClipLoader } from '@saeris/vue-spinners'

export default {
  components: {
    ClipLoader,
  },

  props: {
    loading: {
      type: Boolean,
    },
  },
}
</script>
<style scoped>
.spinner {
}
</style>
