export const VERSION_CONFLICT = {
  status: 'alert',
  message:
    'Version conflict, please reload the page!\n' +
    'Unfortunately unsaved changes will be lost.',
}

export const SOMETHING_WENT_WRONG = {
  status: 'alert',
  message: 'Something went wrong, please reload the page!',
}

export const SENSOR_OFFLINE = {
  status: 'alert',
  message: 'Sensor is unavailable!',
}

export const SESSION_EXPIRED = {
  status: 'alert',
  message: 'Your session has expired, please log in',
}

export const ACCESS_FORBIDDEN = {
  status: 'alert',
  message: "You don't have the required privileges to perform that action",
}
