<template>
  <sf-card>
    <sf-card-section>
      <sf-schema-form-item :label="label" :help="help" :cy="cy">
        <sf-yaml-editor :value="value" v-on="$listeners" />
      </sf-schema-form-item>
    </sf-card-section>
  </sf-card>
</template>
<script>
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfSchemaFormItem from '@/components/SchemaFormItem'
import SfYamlEditor from '@/components/YamlEditor'

export default {
  components: {
    SfCard,
    SfCardSection,
    SfSchemaFormItem,
    SfYamlEditor,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
    },
    value: {
      type: [Object, String, Array],
    },
    cy: {
      type: String,
    },
  },
}
</script>
