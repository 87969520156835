<template>
  <router-link class="tabs-item" :to="to">
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-item {
  border-bottom: 3px solid transparent;
  color: $grey-600;
  padding: 0 0 0.5rem;
  text-decoration: none;
}

.tabs-item + .tabs-item {
  margin-left: 2rem;
}

.router-link-active {
  border-bottom-color: $yellow-500;
  color: $grey-800;
  font-weight: 500;
}
</style>
