<template>
  <div class="configure-interfaces">
    <template v-if="interfaces.length">
      <div class="header">
        <h2 class="title">Interfaces</h2>
        <div class="actions">
          <sf-button @click="openAddDialog"> Add interface </sf-button>
        </div>
      </div>
      <sf-card>
        <sf-interface-list
          :interfaces="interfaces"
          :sensor-interfaces="sensorInterfaces"
          @edit="openEditDialog"
          @delete="deleteInterface"
        />
      </sf-card>
    </template>
    <template v-else>
      <sf-notification title="No interfaces found">
        <p class="mb-4">This Instrument has no interfaces configured.</p>
        <sf-button @click="openAddDialog"> Add interface </sf-button>
      </sf-notification>
    </template>
    <sf-interface-dialog
      v-if="newInterface"
      :initial-config="newInterface"
      :physical-interfaces="physicalInterfaces"
      :sensor-interfaces="sensorInterfaces"
      :save="createInterface"
      @close="closeAddDialog"
      @dirty="setDirty"
    />
    <sf-interface-dialog
      v-if="editedInterface"
      :editing="true"
      :initial-config="editedInterface"
      :physical-interfaces="physicalInterfaces"
      :sensor-interfaces="sensorInterfaces"
      :save="updateInterface"
      @close="closeEditDialog"
      @dirty="setDirty"
    />
    <sf-confirm-dialog ref="deleteConfirm" title="Delete interface?">
      <p>This action cannot be undone.</p>
    </sf-confirm-dialog>
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import apiService from '@/services/apiService'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfConfirmDialog from '@/components/ConfirmDialog'
import SfInterfaceDialog from './_components/InterfaceDialog'
import SfInterfaceList from './_components/InterfaceList'
import SfNotification from '@/components/SfNotification'
import { Handled } from '@/helpers/errors'
import confirmUnsaved from '@/mixins/confirmUnsaved'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfButton,
    SfCard,
    SfConfirmDialog,
    SfInterfaceDialog,
    SfInterfaceList,
    SfNotification,
  },

  async preload({ route }) {
    const { data: sensorInterfaces } = await apiService.getSensorInterfaces(
      route.params.id.split('@')[1]
    )
    const { data: interfaces } = await apiService.getInstrumentInterfaces(
      route.params.id.split('@')[1],
      route.params.id
    )
    const { data: physicalInterfaces } = await apiService.getPhysicalInterfaces(
      route.params.id.split('@')[1]
    )
    return {
      interfaces,
      sensorInterfaces,
      physicalInterfaces,
    }
  },

  data() {
    return {
      editedInterface: null,
      newInterface: null,
    }
  },

  computed: {
    newInterfaceName() {
      const currentNames = this.interfaces.map((iface) => {
        return iface.friendly_name || ''
      })

      for (let i = this.interfaces.length + 1; i < 9999; i++) {
        let name = 'Interface ' + i
        if (!currentNames.includes(name)) {
          return name
        }
      }
      return ''
    },
  },

  methods: {
    async updateInterfaces() {
      // Update instrument interfaces
      let response = await apiService.getInstrumentInterfaces(
        this.$route.params.id.split('@')[1],
        this.$route.params.id
      )
      this.interfaces = response.data

      // Update physical interfaces
      response = await apiService.getPhysicalInterfaces(
        this.$route.params.id.split('@')[1]
      )
      this.physicalInterfaces = response.data
    },

    openAddDialog() {
      this.newInterface = {
        capabilities: [],
        dhcp_client: true,
        friendly_name: this.newInterfaceName,
        ip_addresses: [],
        sensor_bridge_name: 'none',
        sensor_physical_interface_name: 'none',
        type: 'bridge',
      }
    },

    openEditDialog(iface) {
      this.editedInterface = {
        capabilities: iface.capabilities || [],
        dhcp_client: !!iface.dhcp_client,
        friendly_name: iface.friendly_name,
        ip_addresses: iface.ip_addresses || [],
        sensor_bridge_name: iface.sensor_bridge_name || 'none',
        sensor_physical_interface_name: iface.id || 'none',
        type: iface.type,
      }
    },

    closeAddDialog() {
      this.clearDirty()
      this.newInterface = null
    },

    closeEditDialog() {
      this.clearDirty()
      this.editedInterface = null
    },

    prunedConfig(config) {
      const result = cloneDeep(config)
      if (result.ip_addresses[0] === '') {
        delete result.ip_addresses
      }

      if (result.ip_addresses[0] === null) {
        delete result.ip_addresses
      }

      if (result.sensor_bridge_name === 'none') {
        delete result.sensor_bridge_name
      }

      if (result.type === 'physical') {
        delete result.sensor_bridge_name
      } else {
        delete result.sensor_physical_interface_name
      }
      return result
    },

    async createInterface(config) {
      try {
        await apiService.createInstrumentInterface(
          this.$route.params.id.split('@')[1],
          this.$route.params.id,
          this.prunedConfig(config)
        )

        await this.updateInterfaces()

        this.closeAddDialog()
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Interface created',
        })
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to create interface',
          })
        }
        throw err
      }
    },

    async deleteInterface(name) {
      try {
        await this.$refs.deleteConfirm.open(async () => {
          await apiService.deleteInstrumentInterface(
            this.$route.params.id.split('@')[1],
            this.$route.params.id,
            name
          )
          await this.updateInterfaces()
          await this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Interface deleted',
          })
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to delete interface',
          })
        }
      }
    },

    async updateInterface(config) {
      try {
        await apiService.updateInstrumentInterface(
          this.$route.params.id.split('@')[1],
          this.$route.params.id,
          this.editedInterface.sensor_physical_interface_name,
          this.prunedConfig(config)
        )

        await this.updateInterfaces()

        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Interface updated',
        })
        this.closeEditDialog()
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to update interface',
          })
        }
        throw err
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  display: flex;
  margin: 0 0 20px;
}

.title {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}

.actions {
  margin-left: auto;
}
</style>
