<template>
  <sf-modal
    v-on="forceChange ? {} : { close: onClose }"
    title="Change password"
    width="narrow"
  >
    <sf-form @submit.prevent="save">
      <div
        v-if="forceChange"
        class="bg-blue-100 border border-blue-300 mb-6 px-4 py-3 rounded text-blue-800"
      >
        Please change your password before continuing.
      </div>
      <sf-form-item label="Current password">
        <sf-input
          ref="currentPassword"
          v-model="form.data.current_password"
          type="password"
          required
        />
      </sf-form-item>
      <sf-form-item label="New password">
        <sf-input v-model="form.data.new_password" type="password" required />
      </sf-form-item>
      <sf-form-item label="New password again" :error="passwordError">
        <sf-input
          v-model="form.data.new_password_again"
          type="password"
          required
        />
      </sf-form-item>
      <div class="flex justify-end mt-4">
        <sf-button-group>
          <sf-button
            v-if="!forceChange"
            :disabled="form.busy"
            plain
            type="button"
            @click="onClose"
          >
            Cancel
          </sf-button>
          <sf-button :loading="form.busy">Update password</sf-button>
        </sf-button-group>
      </div>
    </sf-form>
  </sf-modal>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput.vue'
import SfModal from '@/components/SfModal.vue'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfForm,
    SfFormItem,
    SfInput,
    SfModal,
  },
  props: {
    forceChange: {
      type: Boolean,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        busy: false,
        data: {
          current_password: '',
          new_password: '',
          new_password_again: '',
        },
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.currentPassword.focus()
    })
  },
  computed: {
    passwordError() {
      return this.form.data.new_password &&
        this.form.data.new_password_again &&
        this.form.data.new_password !== this.form.data.new_password_again
        ? 'Passwords must match'
        : null
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    async save() {
      this.form.busy = true
      try {
        await this.$store.dispatch('auth/changePassword', {
          data: this.form.data,
        })
        this.$emit('success')
      } catch (error) {
        this.$emit('error')
      } finally {
        this.form.busy = false
      }
    },
  },
}
</script>
