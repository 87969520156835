<template>
  <portal to="drawer">
    <transition appear name="slide-fade" @after-leave="$emit('close')">
      <div v-if="open" class="drawer">
        <div class="overlay" @click="open = false" />
        <div class="panel">
          <div class="header">
            <h1 v-if="title" class="title">{{ title }}</h1>
            <div class="close" @click="open = false">&times;</div>
          </div>
          <div class="body">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },

  data() {
    return {
      open: true,
    }
  },

  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    document.body.style.overflowY = 'hidden'
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown)
    document.body.style.overflowY = ''
  },

  methods: {
    close() {
      this.open = false
    },

    onKeydown(evt) {
      if (evt.key === 'Escape') {
        this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.15s;
}

.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
}

.slide-fade-enter-active .panel,
.slide-fade-leave-active .panel {
  transition: transform 0.15s;
}

.slide-fade-enter .panel,
.slide-fade-leave-active .panel {
  transform: translateX(100px);
}

.drawer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.overlay {
  background: rgba($grey-900, 0.3);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.panel {
  background: $grey-100;
  box-shadow: -1px 0 6px rgba($grey-900, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 580px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.header {
  align-items: center;
  background: $white;
  border-bottom: 1px solid $grey-400;
  box-shadow: 0 1px 3px rgba($grey-900, 0.05);
  display: flex;
  height: 6rem;
}

.body {
  flex: 1;
  overflow-y: auto;
}

.close {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  font-size: 2.4rem;
  margin-left: auto;
  padding: 0 2rem;
}

.title {
  align-items: center;
  display: flex;
  font-size: 1.8rem;
  font-weight: 400;
  height: 100%;
  margin: 0;
  padding: 0 2rem;
}

.body {
  padding: 2rem;
}
</style>
