<template>
  <label class="radio">
    <input
      class="input"
      :checked="model === value"
      type="radio"
      :value="value"
      @change="handleChange"
    />
    <span class="label">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'model',
    event: 'change',
  },

  props: {
    label: {
      type: String,
    },
    model: {
      type: [Number, String],
    },
    value: {
      type: [Number, String],
    },
  },

  methods: {
    handleChange(evt) {
      if (evt.target.checked) {
        this.$emit('change', this.value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.radio {
  align-items: center;
  display: flex;
}

.input {
  margin: 0;
}

.label {
  margin-left: 1rem;
}
</style>
