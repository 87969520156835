import isCidr from 'is-cidr'
import cloneDeep from 'lodash/cloneDeep'

/**
 * Checks if homenets are valid
 *
 * @param homenets Home networks to validate
 * @returns true if valid
 */
export function validateHomenets(homenets) {
  for (const homenet of homenets) {
    var nonempty = 0
    for (const net of homenet.networks) {
      //allow saving empty items, they will be removed during save
      if (net != '') {
        if (!isCidr(net)) {
          return false
        }
        nonempty++
      }
    }

    if (nonempty == 0) {
      //every homenet must contain at least 1 valid network
      return false
    }
  }
  return true
}

/**
 * Remove empty cidr networks in given homenets,
 * but keep at least minItems items
 *
 * @param homenets Home networks to clean up
 * @param minItems Minimum items to keep. Set to 1 to allow keeping a single empty homenet.
 */
export function cleanupHomenets(homenets, minItems) {
  for (const homenet of homenets) {
    if (homenet.length <= minItems) {
      continue
    }
    for (const net of cloneDeep(homenet.networks)) {
      if (net === '') {
        homenet.networks.splice(
          homenet.networks.findIndex((e) => e === ''),
          1
        )
      }
    }
  }
}
