<template>
  <sf-table :columns="columns" :data="downloads">
    <template slot="column-output-file-name" slot-scope="{ row }">
      {{ row.output_filename }}
    </template>
    <template slot="column-download-url" slot-scope="{ row }">
      {{ row.download_url }}
    </template>
    <template slot="column-poll-interval" slot-scope="{ row }">
      {{ row.poll_interval_sec }}
    </template>
    <template slot="column-actions" slot-scope="{ row }">
      <sf-button-group segmented>
        <sf-button
          plain
          size="small"
          :disabled="!allowEdit"
          @click="$emit('edit', row)"
        >
          Edit
        </sf-button>
        <sf-button
          plain
          size="small"
          :disabled="!allowEdit"
          @click="$emit('delete', row.id)"
        >
          Delete
        </sf-button>
      </sf-button-group>
    </template>
  </sf-table>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfTable from '@/components/SfTable'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfTable,
  },

  props: {
    downloads: {
      type: Array,
    },
    allowEdit: {
      type: Boolean,
    },
  },

  data() {
    return {
      columns: [
        {
          field: 'output-file-name',
          label: 'Output file name',
        },
        {
          field: 'download-url',
          label: 'Download URL',
        },
        {
          field: 'poll-interval',
          label: 'Poll Interval',
          width: '99%',
        },
        {
          field: 'actions',
          label: 'Actions',
          align: 'right',
        },
      ],
    }
  },
}
</script>
