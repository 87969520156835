<template>
  <div class="sensor" :cy="sensor.id">
    <sf-card>
      <div class="header">
        <div class="health">
          <sf-tooltip :disabled="health.status === 'ok'">
            <sf-health :status="health.status" slot="trigger">
              {{ health.label }}
            </sf-health>
            <sf-health-messages :health="health" mode="sensor" />
          </sf-tooltip>
        </div>
        <h1 class="title">{{ friendlyName }}</h1>
        <div class="actions">
          <sf-button-group segmented>
            <sf-button
              v-if="sensor.ui_path"
              :href="sensor.ui_path"
              target="_blank"
              :disabled="!online"
            >
              <font-awesome-icon :icon="icons.faTv" /> UI
            </sf-button>
            <sf-dropdown ref="dropdown">
              <template #trigger="{ toggleOpen }">
                <sf-button
                  :cy="`${sensor.id}-utils`"
                  :disabled="!online"
                  @click="toggleOpen"
                >
                  <font-awesome-icon :icon="icons.faCaretDown" />
                </sf-button>
              </template>
              <sf-menu>
                <sf-menu-item :to="`/sensors/${sensor.id}/configure`">
                  Configure
                </sf-menu-item>
                <sf-menu-item :to="`/sensors/${sensor.id}/add_instrument`">
                  Add Instrument
                </sf-menu-item>
                <sf-menu-item @click.stop="restartSensor(sensor.id)">
                  Restart
                </sf-menu-item>
              </sf-menu>
            </sf-dropdown>
          </sf-button-group>
        </div>
      </div>
      <template v-if="externalInstruments.length">
        <sf-instrument-list
          :sensor-id="sensor.id"
          :instruments="externalInstruments"
          v-on="$listeners"
          :disabled="!online"
        />
      </template>
      <template v-if="internalInstruments.length">
        <div class="flex items-center justify-center bg-gray-200 h-2">
          <span
            class="bg-white border border-gray-300 px-5 py-2 relative rounded-full text-gray-600 text-xl z-10"
          >
            Platform Instruments ({{ internalInstruments.length }})
          </span>
        </div>
        <sf-instrument-list
          :header="false"
          :sensor-id="sensor.id"
          :instruments="filteredInternalInstruments"
          v-on="$listeners"
          :disabled="!online"
          v-if="filteredInternalInstruments.length"
        />
      </template>
    </sf-card>
    <sf-confirm-dialog
      action="Restart"
      ref="restartConfirm"
      title="Restart sensor?"
    >
    </sf-confirm-dialog>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBan,
  faCaretDown,
  faCheck,
  faEllipsisH,
  faExclamationTriangle,
  faTv,
} from '@fortawesome/free-solid-svg-icons'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCard from '@/components/SfCard'
import SfDropdown from '@/components/SfDropdown'
import SfInstrumentList from '@/components/InstrumentList'
import SfMenu from '@/components/SfMenu.vue'
import SfMenuItem from '@/components/MenuItem'
import SfHealth from '@/components/SfHealth.vue'
import SfTooltip from '@/components/SfTooltip.vue'
import sensorName from '@/filters/sensorName'
import SfHealthMessages from '@/components/SfHealthMessages'
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import apiService from '@/services/apiService'
import { Handled } from '@/helpers/errors'
import SfConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    SfHealthMessages,
    FontAwesomeIcon,
    SfButton,
    SfButtonGroup,
    SfCard,
    SfDropdown,
    SfInstrumentList,
    SfMenu,
    SfMenuItem,
    SfHealth,
    SfTooltip,
    SfConfirmDialog,
  },

  props: {
    sensor: {
      type: Object,
      required: true,
    },
    instruments: {
      type: Array,
      required: true,
    },
    showAllInstruments: {
      type: Boolean,
      required: true,
    },
  },

  created() {
    this.icons = {
      faBan,
      faCaretDown,
      faCheck,
      faEllipsisH,
      faExclamationTriangle,
      faTv,
    }
  },

  watch: {
    online(online) {
      if (!online) {
        this.$refs.dropdown.close()
      }
    },
  },

  computed: {
    friendlyName() {
      return sensorName(this.sensor.id, this.sensor.friendly_name)
    },

    health() {
      let health = cloneDeep(
        this.$store.getters['health/sensorHealth'](this.sensor.id)
      )
      if (!health.components) {
        return health
      }
      health.friendly_name = this.friendlyName
      for (const instrument of Object.values(this.instruments)) {
        if (has(health, `components.${instrument.id}`)) {
          health.components[instrument.id].friendly_name =
            instrument.friendly_name || instrument.default_name
        }
      }

      return health
    },

    externalInstruments() {
      return this.instruments.filter((i) => !i.internal)
    },

    internalInstruments() {
      return this.instruments.filter((instrument) => instrument.internal)
    },

    filteredInternalInstruments() {
      return this.internalInstruments.filter(
        (instrument) =>
          this.showAllInstruments ||
          ['info', 'ok'].indexOf(instrument.health.status) === -1
      )
    },

    online() {
      return this.health.online
    },
  },

  methods: {
    async restartSensor(id) {
      try {
        await this.$refs.restartConfirm.open(async () => {
          await apiService.restartSensor(id)
          await this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Sensor restarting',
          })
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to restart Sensor',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  background: $grey-000;
  border-bottom: 1px solid $grey-300;
  display: flex;
  padding: 1.25rem 2rem;
}

.header > .health {
  min-width: 140px;
}

.header > .title {
  color: $grey-800;
  font-size: 1.8rem;
  font-weight: 600;
}

.header > .actions {
  margin-left: auto;
}

.details {
  display: flex;
  margin: -1.2rem 0 2rem;
}

.details > .item {
  display: flex;
}

.details > .item > .label {
  color: $grey-700;
  margin-right: 0.8rem;
}
</style>
