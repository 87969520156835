<template>
  <div class="header">
    <h1 v-if="title" class="title">{{ title }}</h1>
    <div class="health" v-if="$slots.health">
      <slot name="health" />
    </div>
    <div v-if="$slots.actions" class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0 2rem 0 0;
}

.actions {
  margin-left: auto;
}
</style>
