<template>
  <div class="bg-gray-200 min-h-screen">
    <div class="flex h-full items-center p-6">
      <div class="max-w-2xl mx-auto w-full">
        <img
          class="mb-12 mx-auto max-w-xs w-full"
          src="../assets/sensorfleet-black.png"
          alt="Sensorfleet"
        />
        <form
          class="bg-white border-orange-400 border-t-4 p-12 rounded shadow-xl"
          @submit.prevent="logIn"
        >
          <div class="space-y-8">
            <div>
              <label class="block font-medium mb-2 text-gray-800"
                >Username</label
              >
              <sf-input
                type="text"
                autocapitalize="none"
                v-model="form.username"
              />
            </div>
            <div>
              <label class="block font-medium mb-2 text-gray-800"
                >Password</label
              >
              <sf-input type="password" v-model="form.password" />
            </div>
            <div>
              <label class="flex items-center space-x-4 text-gray-800">
                <input type="checkbox" v-model="form.rememberMe" />
                <span>Remember me</span>
              </label>
            </div>
            <div>
              <sf-button :loading="isLoading">Log in</sf-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SfUnauthenticated from '@/layouts/SfUnauthenticated'
import SfButton from '@/components/SfButton'
import SfInput from '@/components/SfInput'

export default {
  route: {
    meta: {
      layout: SfUnauthenticated,
    },
  },
  components: {
    SfButton,
    SfInput,
  },
  data() {
    return {
      isLoading: false,
      form: {
        username: '',
        password: '',
        rememberMe: false,
      },
    }
  },
  methods: {
    async logIn() {
      this.isLoading = true
      try {
        await this.$store.dispatch('auth/logIn', this.form)
        this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Welcome back!',
        })
        this.$router.push('/')
      } catch (error) {
        this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Login failed, please check your credentials',
        })
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-custom {
  background: $grey-900;
}
</style>
