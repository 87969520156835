const removeEmpty = (obj) =>
  Object.entries(obj).forEach(([key, val]) => {
    if (val !== undefined && typeof val === 'object') removeEmpty(val)
    else if (val === undefined) delete obj[key]
  })

/*
 * Get equivalent for JSON schema types
 */
function convertInputType(type) {
  switch (type) {
    case 'string':
      return 'text'
    case 'boolean':
      return 'boolean'
    case 'integer':
    case 'number':
      return 'number'
    case 'object':
      return 'text'
    default:
      console.warn('unhandled type: ' + type)
      return type
  }
}

/*
 * Create text or number input component for 'input' type variable
 */
function inputProperty(name, values) {
  return {
    component: 'SfSchemaInput',
    model: name,
    fieldOptions: {
      on: ['input'],
      props: {
        label: values.title,
        type: convertInputType(values.type),
        help: values.description || undefined,
      },
    },
  }
}

/*
 * Create Checkbox component for 'boolean' type variable.
 */
function booleanProperty(name, values) {
  return {
    component: 'SfSchemaCheckbox',
    model: name,
    fieldOptions: {
      on: ['input'],
      props: {
        label: values.title,
        help: values.description || undefined,
      },
    },
  }
}

/*
 * Create component for 'array' type variable.
 * It's stack of checkboxes if possible values are known. Otherwise text editor.
 */
function arrayProperty(name, values) {
  if (values.items.enum) {
    // Array with known options, create checkbox group
    return {
      component: 'SfSchemaChoiceGroup',
      model: name,
      fieldOptions: {
        on: ['change'],
        props: {
          label: values.title,
          title: values.title,
          help: values.description || undefined,
          options: values.items.enum,
        },
      },
    }
  } else {
    // Not known options, create yaml editor
    return objectProperty(name, values)
  }
}

/*
 * Create text editor component for 'object' type variable.
 */
function objectProperty(name, values) {
  return {
    component: 'SfSchemaObject',
    model: name,
    fieldOptions: {
      on: ['input'],
      props: {
        label: values.title,
        type: 'json',
        help: values.description || undefined,
      },
    },
  }
}

/*
 * Convert property object found from JSON schema to a corresponding Vue
 * component.
 */
export function convertProperty(name, values) {
  let result = {}
  switch (values.type) {
    case 'string':
    case 'number':
    case 'integer':
      result = inputProperty(name, values)
      break
    case 'boolean':
      result = booleanProperty(name, values)
      break
    case 'array':
      result = arrayProperty(name, values)
      break
    case 'object':
      result = objectProperty(name, values)
      break
  }
  if (Object.keys(result).length === 0) {
    console.warn(`Generation failure, "${name}" resulted empty object`)
  }
  removeEmpty(result)
  return result
}

/**
 * Create section for Schema that act as a container for one or more variables.
 *
 * @param title Title for UI component, mandatory
 * @param description, Description for UI component, used as help. Optional.
 * @param children Primitive variable components that are port of this section
 * @returns Section with pre-given children inside it
 */
function annotatedSection(title, description, children) {
  let section = {
    component: 'SfSchemaAnnotatedSection',
    fieldOptions: {
      props: {
        title: title,
        help: description,
      },
    },
    children: children,
  }
  removeEmpty(section)
  return section
}

/**
 * Convert 'object' type. It can be either primitive variable that is handled as
 * single UI component. Or it can contain sub-variables. Decision is made based
 * it there is 'property' field or not.
 *
 * @param title Title for object, mandatory
 * @param description Optional description, handled as help
 * @param namePrefix Prefix for sub-variables. "" At main level, "foo.bar.baz"
 *                   When going deeper.
 * @param obj Object to be converted
 * @param output Output where results are appended
 * @returns Annotated section with sorted results
 */
function convertObject(title, description, namePrefix, obj, output) {
  const section = []

  Object.entries(obj).forEach(([name, values]) => {
    let fullName = namePrefix === '' ? name : `${namePrefix}.${name}`

    if (values.type && values.type === 'object' && values.properties) {
      // Object has sub-properties, we must go deeper
      convertSection(values, output, fullName)
    } else if (values.type) {
      // Bottom is found, add UI component for it
      section.push(convertProperty(fullName, values))
    }
  })

  section.sort((n1, n2) => {
    const a = n1.fieldOptions.props.label.toLowerCase()
    const b = n2.fieldOptions.props.label.toLowerCase()
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  })
  return annotatedSection(title, description, section)
}

/**
 * Convert main or sub section from schema
 * @param sectionIn Piece of schema under conversion
 * @param output Array for results
 * @param namePrefix prefix for sub-sections
 */
export function convertSection(sectionIn, output, namePrefix) {
  let sectionOut = convertObject(
    sectionIn.title,
    sectionIn.description,
    namePrefix,
    sectionIn.properties,
    output
  )

  /*
   Skip section without own settings. Top-level section
   is an exception, it's added as title and description
   for the whole config.
  */
  if (!hasSubProperties(sectionIn) || sectionIn.$schema) {
    sectionOut.general = namePrefix === ''
    output.push(sectionOut)
  }
}

/**
 * Return true if given section has own sub-properties
 */
function hasSubProperties(section) {
  const subProperties = section.properties
  if (!subProperties) {
    return false
  }

  const key = Object.keys(subProperties)[0]
  if (!key) {
    return false
  }

  return !!subProperties[key].properties
}

/**
 * Convert instrument config JSON schema to UI schema.
 * @param schema Input instrument config schema
 * @returns UI schema for Vue-form-json-schema component
 */
export function generateUiSchema(schema) {
  const output = []
  convertSection(schema, output, '')
  output.sort((n1, n2) => {
    const a = n1.fieldOptions.props.title.toLowerCase()
    const b = n2.fieldOptions.props.title.toLowerCase()
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  })

  // Move general to first
  output.some(
    (item, idx) =>
      'general' in item &&
      item.general === true &&
      output.unshift(
        // remove the found item, in-place (by index with splice),
        // returns an array of a single item removed
        output.splice(idx, 1)[0]
      )
  )

  // remove general flags
  output.forEach((item) => {
    if ('general' in item) {
      delete item.general
    }
  })
  return output
}
