<template>
  <div>
    <sf-header class="refresh" title="Available Instruments">
      <div slot="actions">
        <sf-button-group>
          <sf-button
            @click="refreshInstruments"
            :loading="availableInstrumentsUpdating"
            class="refresh-button"
            >Update list</sf-button
          >
        </sf-button-group>
      </div>
    </sf-header>
    <sf-stack column>
      <sf-stack-item v-for="(instrument, index) in instruments" :key="index">
        <sf-card>
          <sf-card-section :title="instrument.name">
            {{ instrument.title }}
          </sf-card-section>
          <sf-card-section>
            <sf-button
              @click="addInstrument(instrument.id)"
              :disabled="!!instrumentAdded"
              :loading="instrumentAdded === instrument.id"
              :cy="instrument.id"
            >
              Add Instrument
            </sf-button>
          </sf-card-section>
        </sf-card>
      </sf-stack-item>
    </sf-stack>
  </div>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import SfHeader from '@/components/SfHeader'
import SfButtonGroup from '@/components/ButtonGroup'
import apiService from '@/services/apiService'
import { mapState } from 'vuex'
import { Handled } from '@/helpers/errors'

export default {
  components: {
    SfButtonGroup,
    SfButton,
    SfCard,
    SfCardSection,
    SfStack,
    SfStackItem,
    SfHeader,
  },

  async preload({ route }) {
    await apiService.getInstruments(route.params.id)
    const { data } = await apiService.getAvailableInstruments()
    return {
      instruments: data,
    }
  },

  data() {
    return {
      instrumentAdded: null,
    }
  },

  watch: {
    async availableInstrumentsUpdating(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        const { data } = await apiService.getAvailableInstruments()
        this.instruments = data
      }
    },
  },

  computed: {
    ...mapState(['availableInstrumentsUpdating']),
  },

  methods: {
    async addInstrument(instrument) {
      this.instrumentAdded = instrument
      try {
        await apiService.createInstrument(
          this.$route.params.id,
          {
            instrument,
          },
          { localErrorHandlers: [403] }
        )
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Instrument added',
        })
      } catch (err) {
        if (err !== Handled && err.response.status === 403) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'License limit exceeded!',
          })
        } else if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to add Instrument',
          })
        }
      } finally {
        this.instrumentAdded = null
      }
    },

    async refreshInstruments() {
      try {
        this.$store.commit('availableInstrumentsUpdating', true)
        await apiService.refreshAvailableInstruments()
      } catch (err) {
        await this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Failed to update available instruments',
        })
        this.$store.commit('availableInstrumentsUpdating', false)
      }
    },
  },
}
</script>
