<template>
  <div class="sf-configure-bridges">
    <template v-if="interfaces.length">
      <sf-card>
        <div class="header">
          <h2 class="title">Bridges</h2>
          <div class="actions">
            <sf-button @click="openAddDialog" cy="add-bridge">
              Add bridge
            </sf-button>
          </div>
        </div>
        <sf-interface-list
          :interfaces="interfaces"
          @edit="openEditDialog"
          @delete="deleteInterface"
        />
      </sf-card>
    </template>
    <template v-else>
      <sf-notification title="No bridges found">
        <p class="mb-4">This Sensor has no bridges configured.</p>
        <sf-button @click="openAddDialog" cy="add-bridge">
          Add bridge
        </sf-button>
      </sf-notification>
    </template>
    <sf-interface-dialog
      v-if="newInterface"
      :initial-config="newInterface"
      :save="createInterface"
      @close="closeAddDialog"
      @dirty="setDirty"
    />
    <sf-interface-dialog
      v-if="editedInterface"
      :editing="true"
      :initial-config="editedInterface"
      :save="updateInterface"
      @close="closeEditDialog"
      @dirty="setDirty"
    />
    <sf-confirm-dialog ref="deleteConfirm" title="Delete bridge?">
      <p>This action cannot be undone.</p>
    </sf-confirm-dialog>
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </div>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfConfirmDialog from '@/components/ConfirmDialog'
import SfNotification from '@/components/SfNotification'
import apiService from '@/services/apiService'
import SfInterfaceDialog from './_components/InterfaceDialog'
import SfInterfaceList from './_components/InterfaceList'
import { Handled } from '@/helpers/errors'
import confirmUnsaved from '@/mixins/confirmUnsaved'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfButton,
    SfCard,
    SfConfirmDialog,
    SfInterfaceDialog,
    SfInterfaceList,
    SfNotification,
  },

  async preload({ route }) {
    const { data: interfaces } = await apiService.getSensorInterfaces(
      route.params.id
    )
    return {
      interfaces,
    }
  },

  data() {
    return {
      editedInterface: null,
      newInterface: null,
    }
  },

  computed: {
    newBridgeName() {
      const currentNames = this.interfaces.map((iface) => {
        return iface.friendly_name || ''
      })

      for (let i = this.interfaces.length + 1; i < 9999; i++) {
        let name = 'Bridge ' + i
        if (!currentNames.includes(name)) {
          return name
        }
      }
      return ''
    },
  },

  methods: {
    async createInterface(config) {
      try {
        await apiService.createSensorInterface(this.$route.params.id, config)
        const response = await apiService.getSensorInterfaces(
          this.$route.params.id
        )
        this.interfaces = response.data
        this.closeAddDialog()
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Bridge created',
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'error',
            message: 'Failed to create bridge',
          })
        }
      }
    },

    openAddDialog() {
      this.newInterface = {
        friendly_name: this.newBridgeName,
        type: 'mirror-bridge',
        ip_addresses: [],
      }
    },

    openEditDialog(iface) {
      this.editedInterface = {
        iface: iface.id,
        friendly_name: iface.friendly_name,
        type: iface.type,
        ip_addresses: iface.ip_addresses || [],
      }
    },

    closeAddDialog() {
      this.clearDirty()
      this.newInterface = null
    },

    closeEditDialog() {
      this.clearDirty()
      this.editedInterface = null
    },

    async deleteInterface(iface) {
      try {
        await this.$refs.deleteConfirm.open(async () => {
          await apiService.deleteSensorInterface(this.$route.params.id, iface)
          const response = await apiService.getSensorInterfaces(
            this.$route.params.id
          )
          this.interfaces = response.data
          await this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Bridge deleted',
          })
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to delete bridge',
          })
        }
      }
    },

    async updateInterface({ iface, ...config }) {
      try {
        await apiService.updateSensorInterface(
          this.$route.params.id,
          iface,
          config
        )
        const response = await apiService.getSensorInterfaces(
          this.$route.params.id
        )
        this.interfaces = response.data
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Bridge updated',
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to update bridge',
          })
        }
      } finally {
        this.closeEditDialog()
        this.clearDirty()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  background: $grey-000;
  border-bottom: 1px solid $grey-300;
  display: flex;
  padding: 1.5rem 2rem;
}

.title {
  color: $grey-800;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
}

.actions {
  margin-left: auto;
}
</style>
