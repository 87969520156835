<template>
  <label class="checkbox">
    <input
      class="input"
      :checked="checked"
      type="checkbox"
      :value="value"
      @change="handleChange"
    />
    <span class="label">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'model',
    event: 'change',
  },

  props: {
    label: {
      type: String,
    },
    model: {
      type: [Array, Number, String, Boolean],
    },
    value: {
      type: [Array, Number, String, Boolean],
    },
  },

  computed: {
    checked() {
      if (Array.isArray(this.model)) {
        return this.model.indexOf(this.value) !== -1
      } else if (typeof this.model === 'boolean') {
        return this.model
      } else {
        return this.model === this.value
      }
    },
  },

  methods: {
    handleChange(evt) {
      const { checked } = evt.target
      if (Array.isArray(this.model) && checked) {
        this.$emit('change', [...this.model, this.value])
      } else if (Array.isArray(this.model)) {
        this.$emit(
          'change',
          [...this.model].filter((v) => v !== this.value)
        )
      } else {
        this.$emit('change', checked)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox {
  align-items: center;
  display: flex;
}

.input {
  margin: 0;
}

.label {
  margin-left: 1rem;
}
</style>
