<template>
  <div class="bg-gray-100 flex flex-col h-full">
    <div
      class="bg-gray-200 border-b border-gray-300 flex flex-wrap items-center px-8 py-7 relative z-30"
    >
      <h1 class="text-3xl">Overview</h1>
      <div class="ml-auto my-4 flex">
        <div class="mr-4 text-gray-600 whitespace-nowrap">Overall health:</div>
        <sf-tooltip :disabled="fleetHealth.status === 'ok'">
          <sf-health :status="fleetHealth.status" slot="trigger">
            {{ fleetHealth.label }}
          </sf-health>
          <sf-health-messages :health="fleetHealth" mode="fm" />
        </sf-tooltip>
      </div>
      <div class="mb-4 w-full md:mb-0 md:w-auto md:ml-8">
        <label class="inline-flex items-center space-x-2 text-gray-600 z-10">
          <input type="checkbox" v-model="showAvailableInstruments" />
          <span>Show available instruments</span>
        </label>
      </div>
      <div class="w-full lg:ml-8 lg:w-96">
        <sf-input
          v-model="nameQuery"
          placeholder="Filter sensors by name"
          type="search"
        >
          <template slot="prepend">
            <font-awesome-icon class="icon" :icon="icons.faSearch" />
          </template>
        </sf-input>
      </div>
    </div>
    <div v-if="loading" class="px-8 py-16 text-center">
      <sf-loading-message>
        Waiting for health information...
      </sf-loading-message>
    </div>
    <div v-else-if="filteredSensors.length" class="flex-1 overflow-auto">
      <div class="inline-block min-w-full shadow-md">
        <div class="bg-white border-b flex sticky top-0 z-20">
          <div class="flex items-center min-h-full px-8 w-96">
            <div>
              <h2 class="font-bold mb-4 text-gray-600 text-2xl">Legend</h2>
              <div class="space-y-2">
                <div class="flex items-center space-x-4">
                  <div>
                    <sf-status-indicator status="info" />
                  </div>
                  <div class="leading-tight text-2xl text-gray-600">
                    Pending health
                  </div>
                </div>
                <div class="flex items-center space-x-4">
                  <div>
                    <sf-status-indicator status="ok" />
                  </div>
                  <div class="leading-tight text-2xl text-gray-600">OK</div>
                </div>
                <div class="flex items-center space-x-4">
                  <div>
                    <sf-status-indicator status="warning" />
                  </div>
                  <div class="leading-tight text-2xl text-gray-600">
                    Warning
                  </div>
                </div>
                <div class="flex items-center space-x-4">
                  <div>
                    <sf-status-indicator status="error" />
                  </div>
                  <div class="leading-tight text-2xl text-gray-600">
                    Error/Critical
                  </div>
                </div>
                <div class="flex items-center space-x-4">
                  <div>
                    <div
                      class="bg-white h-8 flex items-center justify-center rounded shadow text-gray-700 text-lg w-8"
                    >
                      N
                    </div>
                  </div>
                  <div class="leading-tight text-2xl text-gray-600">
                    Instance count
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-1 h-80 pr-96">
            <div
              v-for="(instrument, index) in filteredInstruments"
              :key="instrument.name"
              class="flex items-end justify-center relative"
            >
              <div
                class="h-96 left-48 relative w-24"
                :class="[index % 2 === 0 && 'bg-gray-100']"
                :style="{ transform: 'skew(-45deg, 0deg)' }"
              >
                <div
                  class="absolute bottom-4 font-medium left-16 leading-none origin-bottom-left text-gray-600 truncate w-96"
                  :style="{ transform: 'skew(45deg, 0deg) rotate(-45deg)' }"
                >
                  {{ instrument.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="divide-y left-0 relative shadow-lg sticky w-96">
            <div
              v-for="sensor in filteredSensors"
              :key="sensor.id"
              class="bg-white flex h-16"
            >
              <div
                class="flex h-full items-center left-0 min-w-0 px-8 space-x-4"
              >
                <sf-tooltip :disabled="sensorHealth[sensor.id].status === 'ok'">
                  <sf-status-indicator
                    slot="trigger"
                    :status="sensorHealth[sensor.id].status"
                  />
                  <sf-health-messages
                    :health="sensorHealth[sensor.id]"
                    mode="sensor"
                  />
                </sf-tooltip>
                <div class="flex-1 min-w-0">
                  <sf-tooltip>
                    <router-link
                      slot="trigger"
                      class="block font-medium no-underline text-gray-600 text-2xl truncate hover:underline"
                      :to="{ path: '/', query: { sensor: sensor.id } }"
                    >
                      {{ sensorNames[sensor.id] }}
                    </router-link>
                    <div>
                      {{ sensorNames[sensor.id] }}
                    </div>
                  </sf-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="divide-y flex-1">
            <div
              v-for="sensor in filteredSensors"
              :key="sensor.id"
              class="bg-gray-50 flex h-16 pr-96"
            >
              <div
                v-for="(instrument, index) in filteredInstruments"
                :key="instrument.name"
                class="flex flex-shrink-0 h-full items-center justify-center w-24"
                :class="[index % 2 === 0 && 'bg-gray-100']"
              >
                <sf-instrument-health
                  :status="instrumentHealth[instrument.id][sensor.id].status"
                  :count="instrumentHealth[instrument.id][sensor.id].count"
                  :healths="instrumentHealth[instrument.id][sensor.id].healths"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="max-w-screen-lg mx-auto p-8 w-full">
      <sf-notification title="No sensors found">
        No results found for "{{ nameQuery }}".
        <button class="link" @click="nameQuery = ''">Clear query</button>
      </sf-notification>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import { mapState } from 'vuex'
import SfHealth from '@/components/SfHealth'
import SfHealthMessages from '@/components/SfHealthMessages'
import SfInput from '@/components/SfInput'
import SfInstrumentHealth from './_components/InstrumentHealth'
import SfLoadingMessage from '@/components/LoadingMessage'
import SfNotification from '@/components/SfNotification'
import SfTooltip from '@/components/SfTooltip'
import sensorName from '@/filters/sensorName'
import instrumentsForSensors from '@/helpers/instrumentsForSensors'
import { sortByStatus, worstStatus } from '@/helpers/status'
import { defaultStorage } from '@/services/browserStorage'
import apiService from '@/services/apiService'
import SfStatusIndicator from './_components/StatusIndicator'

const Storage = {
  SHOW_AVAILABLE_INSTRUMENTS: 'overview.showAvailableInstruments',
}

export default {
  components: {
    FontAwesomeIcon,
    SfHealth,
    SfHealthMessages,
    SfInput,
    SfInstrumentHealth,
    SfLoadingMessage,
    SfNotification,
    SfStatusIndicator,
    SfTooltip,
  },
  async preload() {
    const { data: sensors } = await apiService.getSensors()
    const { data: availableInstruments } =
      await apiService.getAvailableInstruments()
    const instruments = await instrumentsForSensors(sensors)
    return {
      sensors,
      instruments,
      availableInstruments,
    }
  },
  data() {
    return {
      nameQuery: '',
      showAvailableInstruments: defaultStorage.get(
        Storage.SHOW_AVAILABLE_INSTRUMENTS,
        false
      ),
    }
  },
  created() {
    this.icons = { faSearch }
  },
  watch: {
    showAvailableInstruments(value) {
      defaultStorage.set(Storage.SHOW_AVAILABLE_INSTRUMENTS, value)
    },
  },
  computed: {
    ...mapState({
      loading: (state) => !state.health.initialized,
    }),
    filteredInstruments() {
      return this.showAvailableInstruments
        ? this.availableInstruments
        : this.availableInstruments.filter(
            (instrument) => this.instrumentHealth[instrument.id]._count
          )
    },
    filteredSensors() {
      return [...this.sensors]
        .filter((sensor) => {
          if (!this.normalizedNameQuery) {
            return true
          }
          return sensorName(sensor.id, sensor.friendly_name)
            .toLowerCase()
            .match(this.normalizedNameQuery)
        })
        .sort(sortByStatus((sensor) => this.sensorHealth[sensor.id].status))
    },
    fleetHealth() {
      const health = cloneDeep(this.$store.getters['health/fleetHealth'])
      if (!health.sensors) {
        return health
      }
      for (const [sensorId, sensorInstruments] of Object.entries(
        this.instruments
      )) {
        sensorInstruments.forEach((instrument) => {
          if (has(health, `sensors.${sensorId}.components.${instrument.id}`)) {
            health.sensors[sensorId].components[instrument.id].friendly_name =
              instrument.friendly_name || instrument.default_name
          }
        })
        if (has(health, `sensors.${sensorId}`)) {
          health.sensors[sensorId].friendly_name =
            this.sensorFriendlyName(sensorId)
        }
      }
      return health
    },
    instrumentHealth() {
      const health = {}
      for (const instrument of this.availableInstruments) {
        health[instrument.id] = {
          _count: 0,
        }
        for (const sensor of this.sensors) {
          const healths = this.instruments[sensor.id]
            .filter(
              (sensorInstrument) =>
                sensorInstrument.id.indexOf(instrument.id) === 0
            )
            .map((sensorInstrument) => ({
              ...this.$store.getters['health/instrumentHealth'](
                sensorInstrument.id
              ),
              instrument_id: sensorInstrument.id,
            }))
          health[instrument.id]._count += healths.length
          health[instrument.id][sensor.id] = {
            status: worstStatus(healths.map((health) => health.status)),
            count: healths.length,
            healths: healths,
          }
        }
      }
      return health
    },
    normalizedNameQuery() {
      return this.nameQuery.trim().toLowerCase()
    },
    sensorHealth() {
      const health = {}
      for (const sensor of this.sensors) {
        health[sensor.id] = {
          ...cloneDeep(this.$store.getters['health/sensorHealth'](sensor.id)),
        }
      }
      return health
    },
    sensorNames() {
      const names = {}
      for (const sensor of this.sensors) {
        names[sensor.id] = sensorName(sensor.id, sensor.friendly_name)
      }
      return names
    },
  },
  methods: {
    sensorFriendlyName(id) {
      return sensorName(
        id,
        this.sensors.filter((sensor) => sensor.id === id)[0].friendly_name
      )
    },
  },
}
</script>
