<template>
  <vue-json-pretty :data="data" />
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: { VueJsonPretty },

  props: {
    data: {
      required: true,
      type: [String, Number, Boolean, Array, Object],
    },
  },
}
</script>
