<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    span: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        'stack-item': true,
        [`is-span-${this.span}`]: this.span,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.is-span-fill {
  flex: 1;
}

.is-span-1 {
  flex: 1;
}

.is-span-2 {
  flex: 2;
}

.is-span-3 {
  flex: 3;
}

.is-span-4 {
  flex: 4;
}

.is-span-5 {
  flex: 5;
}
</style>
