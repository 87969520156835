<template>
  <div>
    <sf-form @submit.prevent="save">
      <sf-annotated-section title="General settings">
        <template slot="help">
          Give the Sensor a friendly name for easier identification.
        </template>
        <sf-card>
          <sf-card-section>
            <sf-form-item label="Friendly name" cy="delete-events">
              <sf-input v-model.trim="settings.friendly_name" />
            </sf-form-item>
          </sf-card-section>
        </sf-card>
      </sf-annotated-section>
      <sf-annotated-section title="Home networks">
        <template slot="help">
          Some Sensor functionalities, such as Suricata IDS require home network
          configuration. Add your own monitored network ranges here in CIDR
          format (e.g. "10.0.0.0/8").
        </template>
        <sf-stack column>
          <sf-stack-item v-for="(_, index) in settings.homenets" :key="index">
            <sf-homenet
              :enabled="true"
              v-model="settings.homenets[index]"
              @remove="removeHomenet(index)"
            />
          </sf-stack-item>
          <sf-stack-item>
            <sf-card>
              <sf-card-section>
                <sf-form-item label="Network friendly name">
                  <sf-stack>
                    <sf-stack-item span="4">
                      <sf-input
                        v-model.trim="newHomenetName"
                        @keydown.enter.prevent="addHomenet"
                      />
                    </sf-stack-item>
                    <sf-stack-item span="1">
                      <sf-button
                        full-width
                        type="button"
                        :disabled="!newHomenetName"
                        @click="addHomenet"
                      >
                        Add network
                      </sf-button>
                    </sf-stack-item>
                  </sf-stack>
                </sf-form-item>
              </sf-card-section>
            </sf-card>
          </sf-stack-item>
        </sf-stack>
      </sf-annotated-section>
      <sf-annotated-section>
        <div class="text-red-700 mb-2">
          {{ errorMessage }}
        </div>
        <sf-button :disabled="!!errorMessage"> Save changes </sf-button>
      </sf-annotated-section>
    </sf-form>
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SfAnnotatedSection from '@/components/AnnotatedSection'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import apiService from '@/services/apiService'
import SfHomenet from '@/components/SfHomenet'
import { Handled } from '@/helpers/errors'
import confirmUnsaved from '@/mixins/confirmUnsaved'
import cloneDeep from 'lodash/cloneDeep'
import { validateHomenets, cleanupHomenets } from '@/helpers/validateHomenets'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfAnnotatedSection,
    SfButton,
    SfCard,
    SfCardSection,
    SfForm,
    SfFormItem,
    SfHomenet,
    SfInput,
    SfStack,
    SfStackItem,
  },

  async preload({ route }) {
    let { data: settings } = await apiService.getSensorSettings(route.params.id)
    return {
      settings,
    }
  },

  data() {
    return {
      newHomenetName: '',
    }
  },

  watch: {
    settings: {
      deep: true,
      handler() {
        this.setDirty()
      },
    },
    newHomenetName() {
      this.setDirty()
    },
  },

  computed: {
    ...mapState(['id', 'mode', 'jobs', 'socketConnected']),

    errorMessage() {
      if (!validateHomenets(this.settings.homenets)) {
        return 'Invalid home network range!'
      }
      return ''
    },
  },

  methods: {
    addHomenet() {
      if (!this.newHomenetName) {
        return
      }
      this.settings.homenets.push({ name: this.newHomenetName, networks: [''] })
      this.newHomenetName = ''
    },

    removeHomenet(index) {
      this.settings.homenets.splice(index, 1)
    },

    async save() {
      try {
        //We allow empty networks but remove them during save.
        //Always leave single empty homenet for the UX.
        cleanupHomenets(this.settings.homenets, 1)

        //create a deep copy of settings that we can edit when saving
        //without affecting the UX.
        var settings = cloneDeep(this.settings)

        //Remove all empty homenets from saved data.
        cleanupHomenets(settings.homenets, 0)

        //Save settings using deepcopied & modified data
        await apiService.updateSensorSettings(this.$route.params.id, settings)
        const { friendly_name } = this.settings
        this.$store.commit('setFriendlyName', friendly_name)
        if (this.mode === 'sensor') {
          document.title = `${friendly_name || this.id} - SensorFleet`
        }
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Configuration saved',
        })
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to save configuration',
          })
        }
      }
    },
  },
}
</script>
