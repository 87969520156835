import Vue from 'vue'
import Router from 'vue-router'
import ProgressBar from '@badrap/bar-of-progress'
import preload from '@badrap/preload'
import routdir from '@badrap/routdir'
import store from '@/store'

Vue.use(Router)

export const progressBar = new ProgressBar({})

const router = new Router({
  routes: preload(
    [
      ...routdir(require.context('./routes', true, /\.vue$/)),
      {
        path: '*',
        component: () => import('./views/NotFound'),
      },
    ],
    {
      context: {
        store,
      },

      beforePreload() {
        progressBar.start()
      },

      afterPreload() {
        progressBar.finish()
      },
    }
  ),
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const userManagement = store.state['userManagement']
  const isLoggedIn = store.getters['auth/isLoggedIn']
  const guestRoutes = ['/login']

  if (userManagement && !isLoggedIn && guestRoutes.indexOf(to.path) === -1) {
    next('/login')
  } else if (!userManagement && to.path === '/login') {
    next('/')
  } else if (isLoggedIn && to.path === '/login') {
    next('/')
  } else {
    next()
  }
})

export function safeReplaceRoute(path) {
  if (router.currentRoute.path !== path) {
    router.replace(path)
  }
}

export default router
