<script>
import { createPopper } from '@popperjs/core'

export default {
  props: {
    config: {
      type: Object,
      default: () => ({ placement: 'bottom' }),
    },
  },
  data() {
    return {
      isOpen: false,
      triggerEl: null,
      popperEl: null,
    }
  },
  beforeDestroy() {
    if (!this.popper) {
      return
    }
    this.popper.destroy()
  },
  mounted() {
    this.triggerEl = this.$el.querySelector('[data-trigger]')
    this.popperEl = this.$el.querySelector('[data-popper]')
  },
  methods: {
    open() {
      if (this.isOpen) {
        return
      }
      this.isOpen = true
      this.$nextTick(() => {
        this.setup()
      })
    },
    close() {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    toggle() {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    setup() {
      if (this.popper == null) {
        this.popper = createPopper(this.triggerEl, this.popperEl, this.config)
      } else {
        this.popper.update()
      }
    },
  },
  render(createElement) {
    return createElement(
      'div',
      this.$scopedSlots.default({
        isOpen: this.isOpen,
        open: this.open,
        close: this.close,
        toggle: this.toggle,
      })
    )
  },
}
</script>
