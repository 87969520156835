<template>
  <vue2-ace-editor
    :value="text"
    class="editor"
    lang="yaml"
    theme="chrome"
    width="100%"
    @init="editorInit"
    @input="handleInput"
    :options="options"
  />
</template>

<script>
import Vue2AceEditor from 'vue2-ace-editor'
import yaml from 'js-yaml'

function isYamlEncodable(val) {
  if (val === null) {
    return false
  } else if (typeof val === 'object') {
    return Object.keys(val).length > 0
  } else if (Array.isArray(val)) {
    return val.length > 0
  } else {
    //unknown type
    return false
  }
}

function jsToYaml(val) {
  return isYamlEncodable(val) ? yaml.dump(val) : ''
}

export default {
  components: {
    Vue2AceEditor,
  },

  props: {
    emptyValue: {
      type: [Object, String, Array],
      default: () => ({}),
    },
    value: {
      type: [Object, String, Array],
    },
    cy: {
      type: String,
    },
  },

  data() {
    return {
      text: jsToYaml(this.value),
      options: {
        useSoftTabs: true, // not working?
        tabSize: 2,
        minLines: 10,
        maxLines: 50,
        showGutter: true,
        showLineNumbers: false,
        highlightGutterLine: false,
      },
    }
  },

  methods: {
    editorInit() {
      require('brace/mode/yaml')
      require('brace/theme/chrome')
    },

    handleInput(e) {
      try {
        const jsonInput = yaml.load(e)
        this.$emit('input', jsonInput || this.emptyValue)
      } catch (yamlException) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.editor {
  align-items: center;
  background: $white;
  border: 1px solid $grey-400;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px $grey-200;
  display: flex;
  width: 100%;
}
</style>
