<template>
  <div
    class="align-baseline bg-white h-8 inline-flex items-center justify-center overflow-hidden rounded shadow"
  >
    <div
      class="flex h-full items-center justify-center leading-none text-lg w-8"
      :class="classes"
    >
      <font-awesome-icon :icon="icon" />
    </div>
    <div v-if="$slots.default" class="text-center text-gray-600 text-xl w-8">
      <slot />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCheck,
  faExclamation,
  faInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    status: String,
  },

  computed: {
    classes() {
      return {
        'bg-sky-600 text-white': this.status === 'info',
        'bg-green-600 text-white': this.status === 'ok',
        'bg-orange-400 text-white': this.status === 'warning',
        'bg-red-600 text-white':
          this.status === 'error' || this.status === 'critical',
        'text-gray-400': !this.status,
      }
    },

    icon() {
      switch (this.status) {
        case 'info':
          return faInfo
        case 'ok':
          return faCheck
        case 'warning':
          return faExclamation
        default:
          return faTimes
      }
    },
  },
}
</script>
