<template>
  <div>
    <sf-table :columns="columns" :data="headers" wide>
      <template slot="column-key" slot-scope="{ row }">
        <sf-input
          v-model.trim="row.key"
          @input="onInput($event, row.index, 'key')"
        />
      </template>
      <template slot="column-value" slot-scope="{ row }">
        <sf-input
          v-model.trim="row.value"
          @input="onInput($event, row.index, 'value')"
        />
      </template>
      <template slot="column-actions" slot-scope="{ row }">
        <sf-button
          :disabled="!value.length"
          plain
          full-width
          class="remove"
          size="small"
          @click="removeHeader(row.index)"
        >
          {{ headers.length === 1 ? 'Clear' : 'Remove' }}
        </sf-button>
      </template>
    </sf-table>
    <div class="px-2">
      <sf-button type="button" size="small" @click="addHeader"> Add </sf-button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import SfButton from '@/components/SfButton'
import SfTable from '@/components/SfTable'
import SfInput from '@/components/SfInput'

export default {
  components: {
    SfButton,
    SfInput,
    SfTable,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Array,
    },
  },

  computed: {
    headers() {
      return this.value.map((x, index) => {
        const entries = Object.entries(x)
        return {
          key: entries[0][0],
          value: entries[0][1],
          index: index,
        }
      })
    },
  },

  data() {
    return {
      columns: [
        {
          field: 'key',
          label: 'Key',
          width: '45%',
        },
        {
          field: 'value',
          label: 'Value',
          width: '45%',
        },
        {
          field: 'actions',
          label: 'Actions',
          width: '10%',
        },
      ],
    }
  },

  methods: {
    onInput(value, index, type) {
      // Modify correct value in original headers array
      let headers = cloneDeep(this.value)
      const entries = Object.entries(headers[index])
      if (type === 'key') {
        headers[index] = { [value]: entries[0][1] }
      } else if (type === 'value') {
        headers[index] = { [entries[0][0]]: value }
      }
      this.$emit('dirty')
      this.$emit('change', headers)
    },

    addHeader() {
      let headers = cloneDeep(this.value)
      headers.push({
        '': '',
      })
      this.$emit('change', headers)
      this.$emit('dirty')
    },

    removeHeader(index) {
      let headers = cloneDeep(this.value)
      headers.splice(index, 1)

      if (headers.length === 0) {
        headers.push({ '': '' })
      }

      this.$emit('change', headers)
      this.$emit('dirty')
    },
  },
}
</script>

<style scoped>
.remove {
  min-width: 8rem;
  text-align: right;
  align-content: center;
  horiz-align: right;
}
</style>
