<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        stack: true,
        'is-column': this.column,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.stack {
  align-items: center;
  display: flex;
}

.stack > * + * {
  margin: 0 0 0 2rem;
}

.is-column {
  display: block;
}

.is-column > * + * {
  margin: 2rem 0 0;
}
</style>
