import { sortByStatus } from '@/helpers/status'

export default function healthForInstruments(instruments, instrumentHealth) {
  return instruments
    .map((instrument) => ({
      ...instrument,
      health: instrumentHealth(instrument.id),
    }))
    .sort(sortByStatus((instrument) => instrument.health.status))
}
