<template>
  <div class="bg-gray-100 flex flex-col h-full">
    <div
      class="bg-gray-200 border-b border-gray-300 flex flex-wrap items-center px-8 py-7 relative z-30"
    >
      <h1 class="text-3xl">Sensors</h1>
      <template v-if="mode === 'fm'">
        <div class="ml-auto my-4 flex">
          <div class="mr-4 text-gray-600 whitespace-nowrap">
            Overall health:
          </div>
          <sf-tooltip :disabled="health.status === 'ok'">
            <sf-health :status="health.status" slot="trigger">
              {{ health.label }}
            </sf-health>
            <sf-health-messages :health="health" mode="fm" />
          </sf-tooltip>
        </div>
        <div class="mb-4 w-full md:mb-0 md:w-auto md:ml-8">
          <label
            slot="trigger"
            class="inline-flex items-center space-x-2 text-gray-600 z-10"
          >
            <input type="checkbox" v-model="showAllInstruments" />
            <span>Show all instruments</span>
          </label>
        </div>
        <div class="w-full lg:ml-8 lg:w-96">
          <sf-input
            v-model="nameQuery"
            placeholder="Filter sensors by name"
            type="search"
          >
            <template slot="prepend">
              <font-awesome-icon class="icon" :icon="icons.faSearch" />
            </template>
          </sf-input>
        </div>
      </template>
    </div>
    <div class="px-8 py-16">
      <div v-if="loading" class="text-center">
        <sf-loading-message>
          Waiting for health information...
        </sf-loading-message>
      </div>
      <div
        v-else-if="filteredSensors.length"
        class="max-w-screen-lg mx-auto space-y-12"
      >
        <sf-sensor
          v-for="sensor in filteredSensors"
          :key="sensor.id"
          :ref="sensor.id"
          :sensor="sensor"
          :instruments="instrumentsWithHealth[sensor.id]"
          :show-all-instruments="showAllInstruments"
          @on-instrument-delete="instrumentDeleted"
        />
      </div>
      <div v-else class="max-w-screen-lg mx-auto">
        <sf-notification title="No sensors found">
          No results found for "{{ nameQuery }}".
          <button class="link" @click="nameQuery = ''">Clear query</button>
        </sf-notification>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { mapGetters, mapState } from 'vuex'
import SfHealth from '@/components/SfHealth'
import SfHealthMessages from '@/components/SfHealthMessages'
import SfInput from '@/components/SfInput.vue'
import SfLoadingMessage from '@/components/LoadingMessage'
import SfNotification from '@/components/SfNotification'
import SfSensor from '@/components/SfSensor'
import SfTooltip from '@/components/SfTooltip'
import sensorName from '@/filters/sensorName'
import instrumentsForSensors from '@/helpers/instrumentsForSensors'
import { defaultStorage } from '@/services/browserStorage'
import apiService from '@/services/apiService'
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import healthForInstruments from '@/helpers/healthForInstruments'

const Storage = {
  SHOW_ALL_INSTRUMENTS: 'overview.showAllInstruments',
}

export default {
  components: {
    FontAwesomeIcon,
    SfHealth,
    SfHealthMessages,
    SfInput,
    SfLoadingMessage,
    SfNotification,
    SfSensor,
    SfTooltip,
  },

  async preload({ redirect, store }) {
    if (store.getters['userManagement'] && !store.getters['auth/isAdmin']) {
      return redirect('/simple')
    }
    if (store.state.mode === 'sensor') {
      return redirect(`/sensors/${store.state.id}/instruments`)
    } else {
      const { data: sensors } = await apiService.getSensors()
      const instruments = await instrumentsForSensors(sensors)
      return {
        sensors,
        instruments,
      }
    }
  },

  data() {
    return {
      nameQuery: '',
      sensors: [],
      showAllInstruments: defaultStorage.get(
        Storage.SHOW_ALL_INSTRUMENTS,
        false
      ),
    }
  },

  created() {
    this.icons = { faSearch }
  },

  mounted() {
    const { sensor } = this.$route.query
    if (sensor) {
      this.scrollToSensor(sensor)
    }
  },

  watch: {
    showAllInstruments(value) {
      defaultStorage.set(Storage.SHOW_ALL_INSTRUMENTS, value)
    },
  },

  computed: {
    ...mapState(['mode']),
    ...mapState({
      loading: (state) => !state.health.initialized,
    }),
    ...mapGetters('health', ['fleetHealth', 'instrumentHealth']),

    filteredSensors() {
      if (!this.normalizedNameQuery) {
        return this.sensors
      }
      return this.sensors.filter((sensor) => {
        const name = sensorName(sensor.id, sensor.friendly_name).toLowerCase()
        return name.match(this.normalizedNameQuery)
      })
    },

    normalizedNameQuery() {
      return this.nameQuery.trim().toLowerCase()
    },

    health() {
      let health = cloneDeep(this.fleetHealth)

      if (!health.sensors) {
        return health
      }

      for (const [sensorId, sensorInstruments] of Object.entries(
        this.instruments
      )) {
        sensorInstruments.forEach((instrument) => {
          if (has(health, `sensors.${sensorId}.components.${instrument.id}`)) {
            health.sensors[sensorId].components[instrument.id].friendly_name =
              instrument.friendly_name || instrument.default_name
          }
        })

        if (has(health, `sensors.${sensorId}`)) {
          health.sensors[sensorId].friendly_name =
            this.sensorFriendlyName(sensorId)
        }
      }

      return health
    },

    instrumentsWithHealth() {
      const instrumentsWithHealth = {}
      for (const [sensorId, instruments] of Object.entries(this.instruments)) {
        instrumentsWithHealth[sensorId] = healthForInstruments(
          instruments,
          this.instrumentHealth
        )
      }
      return instrumentsWithHealth
    },
  },

  methods: {
    instrumentDeleted(id, instruments) {
      let sensor = id.split('@')[1]
      this.instruments[sensor] = instruments
    },

    sensorFriendlyName(id) {
      return sensorName(
        id,
        this.sensors.filter((sensor) => sensor.id === id)[0].friendly_name
      )
    },

    scrollToSensor(id) {
      const sensorCard = (this.$refs[id] || [])[0]
      if (sensorCard) {
        this.$nextTick(() => {
          window.scrollTo(0, sensorCard.$el.offsetTop - 20)
        })
      }
    },
  },
}
</script>
