import SfUnsavedDialog from '@/components/UnsavedDialog'

export default {
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav)
  },

  components: {
    SfUnsavedDialog,
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.dirty) {
      next()
      return
    }
    next(await this.$refs.confirmUnsaved.open(async () => true))
  },

  data() {
    return {
      dirty: false,
    }
  },

  methods: {
    preventNav(event) {
      if (!this.dirty) return
      event.preventDefault()
      event.returnValue = ''
    },

    setDirty() {
      this.dirty = true
    },

    clearDirty() {
      this.dirty = false
    },
  },
}
