<template>
  <div>
    <sf-card v-if="events.length">
      <sf-table :columns="columns" :data="events" @click-row="openEvent">
        <template #column-timestamp="{ row }">
          {{ row.timestamp | timestamp }}
        </template>
      </sf-table>
      <sf-card-section>
        <div class="footer">
          <div class="per-page">
            <span class="label">Per page</span>
            <sf-select
              :options="limitOptions"
              :value="params.limit"
              @change="(e) => updateParam('limit', e.target.value)"
            />
          </div>
          <sf-pagination
            :page-count="pageCount"
            :value="params.page"
            @input="(v) => updateParam('page', v)"
          />
        </div>
      </sf-card-section>
    </sf-card>
    <sf-notification v-else title="No events found">
      <p>No events found for the given parameters.</p>
    </sf-notification>
    <sf-event-details v-if="event" :event="event" @close="closeEvent" />
  </div>
</template>

<script>
import SfJsonPretty from '@/components/JsonPretty'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfDrawer from '@/components/SfDrawer'
import SfNotification from '@/components/SfNotification.vue'
import SfSelect from '@/components/SfSelect.vue'
import SfPagination from '@/components/SfPagination.vue'
import SfTable from '@/components/SfTable.vue'
import timestamp from '@/filters/timestamp'
import SfEventDetails from './EventDetails'

export default {
  components: {
    SfEventDetails,
    SfTable,
    SfPagination,
    SfSelect,
    SfNotification,
    SfJsonPretty,
    SfCard,
    SfCardSection,
    SfDrawer,
  },

  filters: {
    timestamp,
  },

  props: {
    events: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: false, // See comment in pageCount()
    },
    event: {
      type: Object,
      required: false,
    },
    updateParam: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          field: 'timestamp',
          label: 'timestamp',
        },
        {
          field: 'type',
          label: 'type',
        },
        {
          field: 'message',
          label: 'message',
          width: '99%',
        },
      ],
      limitOptions: [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '250', value: 250 },
        { label: '500', value: 500 },
      ],
    }
  },

  computed: {
    pageCount() {
      // return Math.ceil(this.total / this.params.limit)
      // Hardcode page count to avoid manually counting pages.
      // doing a count() on database is slow, at least with current indexes.
      // Also, searching events past the page 1000 will become increasingly slower
      // at some point.
      // If more pages are needed, an advanced user can hack the url for page > 1000.
      // See ticket SF-1647
      return 1000
    },
  },

  methods: {
    openEvent(event) {
      this.$router.push({
        query: {
          ...this.$route.query,
          id: `${event.source_sensor}_${event.sensor_event_id}`,
        },
      })
    },

    closeEvent() {
      const { id, ...query } = this.$route.query
      this.$router.push({ query })
    },
  },
}
</script>
<style lang="scss" scoped>
.footer {
  align-items: center;
  display: flex;
}

.per-page {
  align-items: center;
  display: flex;
}

.per-page > .label {
  margin-right: 1rem;
  white-space: nowrap;
}

.pagination {
  margin-left: auto;
}
</style>
