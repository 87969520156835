<template>
  <div>
    <input
      class="input"
      :value="value"
      @change="handleChange"
      list="options"
      :cy="cy"
    />
    <datalist id="options" class="datalist">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === value"
      >
        {{ option.label }}
      </option>
    </datalist>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
    },
    cy: {
      type: String,
    },
  },

  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('change', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  border: 1px solid $grey-400;
  border-radius: 3px;
  box-shadow: 0 1px 2px $grey-200;
  display: inline-flex;
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  width: 100%;
}
</style>
