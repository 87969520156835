import get from 'lodash/get'

export default {
  namespaced: true,

  state: {
    initialized: false,
    health: [],
  },

  mutations: {},

  getters: {
    /**
     * Get object containing health info for given instrument
     */
    instrumentHealth: (state, getters, rootState) => (instrument) => {
      const sensor = instrument.split('@')[1]

      let health = {
        status: 'ok',
        label: healthLabel('ok'),
        container_status: 'unknown',
        messages: [],
      }
      const special = specialStatuses(state.health, rootState.socketConnected)
      if (special) {
        return { ...health, ...special }
      } else if (!get(state.health, `sensors.${sensor}.online`)) {
        return {
          status: 'error',
          label: healthLabel('error'),
          container_status: 'unknown',
          messages: [
            {
              status: 'error',
              label: healthLabel('error'),
              title: 'Cannot connect to Sensor',
            },
          ],
        }
      }

      const instrumentHealth = get(
        state.health,
        `sensors.${sensor}.components.${instrument}`,
        null
      )

      return {
        status: get(instrumentHealth, 'status', 'error'),
        label: healthLabel(get(instrumentHealth, 'status', 'error')),
        container_status: get(
          state.health,
          `sensors.${sensor}.${instrument}.run_status`,
          'unknown'
        ),
        messages: get(
          state.health,
          `sensors.${sensor}.components.${instrument}.messages`,
          [
            {
              status: 'error',
              label: healthLabel('error'),
              title: 'Unknown error',
            },
          ]
        ).map((item) => {
          return {
            title: item.title,
            status: item.status,
          }
        }),
      }
    },

    /**
     * Get object containing health info for given sensor.
     * Contains sensor's internal components and health of
     * all instruments from instrumentHealth().
     */
    sensorHealth: (state, getters, rootState) => (sensor) => {
      let health = {
        id: sensor,
        status: 'ok',
        label: healthLabel('ok'),
        online: true,
        messages: [],
      }

      const special = specialStatuses(state.health, rootState.socketConnected)
      if (special) {
        return {
          ...health,
          ...special,
          online: state.health.length === 0 || rootState.socketConnected,
        }
      }

      // Errors happened during performing health check
      health.messages = get(state.health, `sensors.${sensor}.messages`, [])

      // Worst status of the sensor
      health.status = get(state.health, `sensors.${sensor}.status`, 'ok')
      health.label = healthLabel(health.status)
      health.online = get(state.health, `sensors.${sensor}.online`)

      // Messages for instruments and platform components (sensor-ui,
      // sensor-orchestrator)
      health.components = {}
      Object.entries(
        get(state.health, `sensors.${sensor}.components`, {})
      ).forEach(([name]) => {
        health.components[name] = getters.instrumentHealth(name)
      })

      return health
    },

    /**
     * Get health for whole fleet. Combination of the sensorHealth() for
     * all sensors along with general errors if any
     */
    fleetHealth: (state, getters, rootState) => {
      let health = {
        status: 'error',
        label: healthLabel('error'),
        sensors: {},
        messages: [],
      }

      const special = specialStatuses(state.health, rootState.socketConnected)
      if (special) {
        return { ...health, ...special }
      }

      health.status = state.health.status
      health.label = healthLabel(state.health.status)

      Object.entries(get(state.health, `sensors`, {})).forEach(([name]) => {
        health.sensors[name] = getters.sensorHealth(name)
      })

      return health
    },
  },
}

/*
 * Helper functions
 */

/**
 * Create special health reply if needed
 * @param health Health from state
 * @param socketConnected Is websocket connected
 * @returns {null|{messages: [{label: string, title: string, status: string}], label, status: string}}
 */
function specialStatuses(health, socketConnected) {
  if (health.length === 0) {
    return {
      status: 'info',
      label: healthLabel('info'),
      messages: [
        {
          status: 'info',
          label: healthLabel('info'),
          title: 'Pending health check',
        },
      ],
    }
  } else if (!socketConnected) {
    return {
      status: 'error',
      label: healthLabel('error'),
      messages: [
        {
          status: 'error',
          label: healthLabel('error'),
          title: 'Connection to backend lost',
        },
      ],
    }
  } else {
    return null
  }
}

function healthLabel(status) {
  return {
    info: 'Info',
    ok: 'OK',
    warning: 'Warning',
    error: 'Error',
    critical: 'Error',
  }[status]
}
