<template>
  <div
    class="h-16 inline-flex items-center justify-center mr-6 rounded-full text-2xl text-white to-gray-700 w-16"
    :style="{ background: backgroundColor }"
  >
    {{ initials }}
  </div>
</template>

<script>
export default {
  props: {
    name: String,
  },
  computed: {
    backgroundColor() {
      let hash = 0
      for (var i = 0; i < this.name.length; i++) {
        hash = this.name.charCodeAt(i) + ((hash << 5) - hash)
      }
      return `hsl(${hash % 360}, 35%, 50%)`
    },
    initials() {
      return this.name
        .split(/\s/)
        .slice(0, 2)
        .map((part) => part[0].toUpperCase())
        .join('')
    },
  },
}
</script>
