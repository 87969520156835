<template>
  <div>
    <div v-if="this.schema">
      <sf-form @submit.prevent="save">
        <vue-form-json-schema
          :model="config"
          :schema="schema"
          :ui-schema="uiSchema"
          :components="components"
          :options="options"
          @change="onChange"
          @state-change="onChangeState"
          @validated="onValidated"
        />
        <sf-annotated-section />
        <sf-annotated-section>
          <sf-button :disabled="!valid || saving"> Save changes </sf-button>
        </sf-annotated-section>
      </sf-form>
    </div>
    <div v-else>
      <sf-notification title="No custom configuration">
        <p class="mb-4">This Instrument does not have custom configuration.</p>
      </sf-notification>
    </div>

    <div v-if="$route.query.debug">
      <h4>Valid</h4>
      <div>{{ valid }}</div>

      <h4>Config</h4>
      <sf-json-pretty :data="config"></sf-json-pretty>

      <h4>Schema</h4>
      <sf-json-pretty :data="schema"></sf-json-pretty>

      <h4>UI Schema</h4>
      <sf-json-pretty :data="uiSchema"></sf-json-pretty>

      <h4>State</h4>
      <sf-json-pretty :data="state"></sf-json-pretty>
    </div>
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </div>
</template>

<script>
import VueFormJsonSchema from 'vue-form-json-schema'
import SfForm from '@/components/SfForm'
import SfButton from '@/components/SfButton'
import SfInput from '@/components/SfInput'
import SfSchemaCheckbox from '@/components/SchemaCheckbox'
import SfSchemaInput from '@/components/SchemaInput'
import SfChoiceGroup from '@/components/ChoiceGroup'
import SfSchemaChoiceGroup from '@/components/SchemaChoiceGroup'
import SfSchemaObject from '@/components/SchemaObject'
import SfSchemaHelp from '@/components/SchemaHelp'
import SfAnnotatedSection from '@/components/AnnotatedSection'
import SfSchemaAnnotatedSection from '@/components/SchemaAnnotatedSection'
import SfJsonPretty from '@/components/JsonPretty'
import SfUnsavedDialog from '@/components/UnsavedDialog'
import apiService from '@/services/apiService'
import { generateUiSchema } from '@/helpers/instrumentUiSchema'
import confirmUnsaved from '@/mixins/confirmUnsaved'
import { Handled } from '@/helpers/errors'
import SfNotification from '@/components/SfNotification'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfNotification,
    SfForm,
    SfButton,
    SfAnnotatedSection,
    SfJsonPretty,
    SfUnsavedDialog,
    VueFormJsonSchema,
  },

  async preload({ route }) {
    const { data: schema } = await apiService.getInstrumentConfigSchema(
      route.params.id.split('@')[1],
      route.params.id
    )

    if (schema === null) {
      return {
        schema: null,
        config: null,
      }
    }

    const { data: config } = await apiService.getInstrumentConfig(
      route.params.id.split('@')[1],
      route.params.id
    )

    return {
      schema,
      config,
    }
  },

  computed: {
    uiSchema() {
      return this.schema ? generateUiSchema(this.schema) : []
    },
  },

  data() {
    return {
      schema: {},
      config: {},
      state: {},
      valid: false,
      options: {
        castToSchemaType: true,
      },
      components: {
        SfInput,
        SfSchemaInput,
        SfChoiceGroup,
        SfSchemaChoiceGroup,
        SfSchemaObject,
        SfAnnotatedSection,
        SfSchemaAnnotatedSection,
        SfSchemaHelp,
        SfSchemaCheckbox,
      },
      saving: false,
    }
  },
  methods: {
    onChange(value) {
      this.config = value
      this.setDirty()
    },

    onChangeState(value) {
      this.state = value
    },

    onValidated(value) {
      this.valid = value
    },

    async save() {
      this.saving = true
      try {
        await apiService.updateInstrumentConfig(
          this.$route.params.id.split('@')[1],
          this.$route.params.id,
          this.config
        )
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Instrument configuration saved',
        })
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to save the config!',
          })
        }
      } finally {
        this.saving = false
      }
    },
  },
}
</script>
