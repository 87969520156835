<template>
  <div class="toast-center">
    <transition-group name="fade-slide">
      <div v-for="toast in toasts" :key="toast.id" class="item">
        <sf-toast :status="toast.status">
          {{ toast.message }}
        </sf-toast>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SfToast from '@/components/SfToast'

export default {
  components: {
    SfToast,
  },

  computed: {
    ...mapState('toasts', {
      toasts: (state) => state.toasts,
    }),
  },
}
</script>

<style lang="scss" scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.25s;
}

.fade-slide-enter {
  opacity: 0;
  transform: translateY(30px);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.toast-center {
  bottom: 30px;
  left: 50%;
  max-width: 360px;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
  z-index: 200;
}

.item {
  margin-top: 1rem;
}
</style>
