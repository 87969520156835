<template>
  <sf-confirm-dialog ref="confirm" title="Unsaved changes" action="OK">
    <p>Are you sure? Your changes will be lost.</p>
  </sf-confirm-dialog>
</template>

<script>
import SfConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    SfConfirmDialog,
  },

  methods: {
    open() {
      return this.$refs.confirm.open()
    },
  },
}
</script>
