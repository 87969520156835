<template>
  <sf-modal :title="title" width="narrow" @keyup.enter="submit" @close="close">
    <sf-stack column>
      <template>
        <sf-stack-item>
          <sf-form-item label="Download URL">
            <sf-input v-model.trim="config.download_url" cy="download-url" />
          </sf-form-item>
        </sf-stack-item>
        <sf-stack-item>
          <sf-form-item label="Output file name">
            <sf-input
              v-model.trim="config.output_filename"
              cy="output-file-name"
            />
          </sf-form-item>
        </sf-stack-item>
        <sf-stack-item>
          <sf-form-item label="Optional usage hint">
            <sf-input
              v-model.trim="config.meta.usage_hint"
              cy="output-file-name"
            />
          </sf-form-item>
        </sf-stack-item>
        <sf-form-item label="Custom HTTP headers">
          <header-edit v-model="config.headers" @dirty="emitDirty" />
        </sf-form-item>
        <sf-stack-item>
          <sf-form-item label="Poll interval in seconds" class="poll-interval">
            <sf-input
              v-model.trim="config.poll_interval_sec"
              type="number"
              cy="poll-interval"
            />
          </sf-form-item>
        </sf-stack-item>
      </template>
    </sf-stack>
    <template slot="actions">
      <div class="text-red-700 mb-2 m-3">
        {{ errorMessage }}
      </div>
      <sf-button-group>
        <sf-button plain type="button" @click="close"> Cancel </sf-button>
        <sf-button :disabled="!!errorMessage" :loading="saving" @click="submit">
          {{ saveButtonText }}
        </sf-button>
      </sf-button-group>
    </template>
  </sf-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import headerEdit from '@/routes/instruments/[id]/configure/_components/headerEdit'
import isEqual from 'lodash/isEqual'
import Vue from 'vue'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfFormItem,
    SfInput,
    SfModal,
    SfStack,
    SfStackItem,
    headerEdit,
  },

  props: {
    editing: {
      type: Boolean,
    },
    initialConfig: {
      type: Object,
    },
    save: {
      type: Function,
      required: true,
    },
  },

  data() {
    let config = cloneDeep(this.initialConfig)
    if (!config.headers || config.headers.length === 0) {
      Vue.set(config, 'headers', [{ '': '' }])
    }
    return {
      config: config,
      saving: false,
    }
  },

  watch: {
    config: {
      deep: true,
      handler() {
        this.emitDirty()
      },
    },
  },

  computed: {
    saveButtonText() {
      return this.editing ? 'Save' : 'Create'
    },

    title() {
      return this.editing ? 'Edit download' : 'Add download'
    },

    errorMessage() {
      if (!this.headersValid) {
        return 'Invalid header!'
      } else if (!this.config.download_url) {
        return 'Download URL is missing!'
      } else if (!this.config.output_filename) {
        return 'Output file name is missing!'
      } else if (!this.config.poll_interval_sec) {
        return 'Poll interval is missing!'
      }
      return ''
    },

    headersValid() {
      // Return false if any header has empty key. Placeholder is accepted
      if (
        this.config.headers.length === 1 &&
        isEqual(this.config.headers[0], { '': '' })
      ) {
        return true
      }
      return (
        this.config.headers.reduce((result, x) => {
          const entries = Object.entries(x)
          if (entries[0][0] === '') {
            return result + 1
          } else {
            return result
          }
        }, 0) === 0
      )
    },
  },

  methods: {
    emitDirty() {
      this.$emit('dirty')
    },

    async submit() {
      if (this.errorMessage) {
        return
      }
      this.saving = true

      // Remove placeholder header if it's not edited
      let headers = this.config.headers
      if (headers.length === 1 && isEqual(headers[0], { '': '' })) {
        headers.pop()
      }

      if (this.config.poll_interval_sec) {
        this.config.poll_interval_sec = parseInt(this.config.poll_interval_sec)
      }

      // Remove empty keys from meta
      Object.keys(this.config.meta).forEach(
        (key) =>
          this.config.meta[key] == null ||
          (this.config.meta[key] === '' && delete this.config.meta[key])
      )

      try {
        await this.save({ ...this.config })
      } finally {
        this.saving = false
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.poll-interval {
  width: 50%;
}
</style>
