<template>
  <div class="form-item" :cy="cy">
    <label class="label" v-if="label">{{ label }}</label>
    <label class="help" v-if="help">
      <sf-markdown>{{ help }}</sf-markdown>
    </label>
    <div class="input">
      <slot />
    </div>
  </div>
</template>

<script>
import SfMarkdown from '@/components/SfMarkdown'
export default {
  components: { SfMarkdown },
  props: {
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    cy: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-item + .form-item {
  margin-top: 2rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
}

.help {
  color: $grey-600;
}
</style>
