<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import SfAuthenticated from '@/layouts/SfAuthenticated'

export default {
  computed: {
    layout() {
      let layout = SfAuthenticated
      this.$route.matched.forEach((route) => {
        layout = route.meta.layout || layout
      })
      return layout
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v19-latin-regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('./assets/fonts/roboto-v19-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/roboto-v19-latin-regular.woff2') format('woff2'),
    url('./assets/fonts/roboto-v19-latin-regular.woff') format('woff'),
    url('./assets/fonts/roboto-v19-latin-regular.ttf') format('truetype'),
    url('./assets/fonts/roboto-v19-latin-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v19-latin-500.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./assets/fonts/roboto-v19-latin-500.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/roboto-v19-latin-500.woff2') format('woff2'),
    url('./assets/fonts/roboto-v19-latin-500.woff') format('woff'),
    url('./assets/fonts/roboto-v19-latin-500.ttf') format('truetype'),
    url('./assets/fonts/roboto-v19-latin-500.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v19-latin-700.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./assets/fonts/roboto-v19-latin-700.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/roboto-v19-latin-700.woff2') format('woff2'),
    url('./assets/fonts/roboto-v19-latin-700.woff') format('woff'),
    url('./assets/fonts/roboto-v19-latin-700.ttf') format('truetype'),
    url('./assets/fonts/roboto-v19-latin-700.svg#Roboto') format('svg');
}

html {
  box-sizing: border-box;
  font: 10px/1.5 Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.link {
  background: none;
  border: 0;
  color: #303030;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

body {
  background: $grey-100;
  font-size: 1.6rem;
  margin: 0;
}

button,
input,
select {
  font: inherit;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app > .body {
  flex: 1;
  height: 0;
}

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .hidden-xs-up {
    display: none !important;
  }
}
</style>
