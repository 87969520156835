<template>
  <sf-card>
    <sf-card-section>
      <sf-schema-form-item :label="label" :help="help" :cy="cy">
        <sf-input :value="value" v-on="$listeners" :type="type" />
      </sf-schema-form-item>
    </sf-card-section>
  </sf-card>
</template>
<script>
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfInput from '@/components/SfInput'
import SfSchemaFormItem from '@/components/SchemaFormItem'

export default {
  components: { SfSchemaFormItem, SfCard, SfCardSection, SfInput },

  props: {
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    type: {
      type: String,
      required: true,
    },
    cy: {
      type: String,
    },
  },

  data() {
    return {}
  },
}
</script>
