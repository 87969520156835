<template>
  <sf-modal :title="title" width="narrow" @keyup.enter="submit" @close="close">
    <sf-stack column>
      <sf-stack-item>
        <sf-form-item label="Interface name">
          <sf-input
            ref="name"
            v-model.trim="config.friendly_name"
            cy="name"
            @click.native="$event.target.select()"
            @input="emitDirty"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item>
        <sf-form-item label="Interface type">
          <sf-select
            :options="typeOptions"
            v-model="config.type"
            cy="type"
            @input="emitDirty"
          />
        </sf-form-item>
      </sf-stack-item>
      <sf-stack-item>
        <sf-form-item label="IP address and netmask">
          <sf-input
            v-model.trim="config.ip_addresses[0]"
            placeholder="10.10.0.1/24"
            cy="ip"
            @input="emitDirty"
          />
        </sf-form-item>
      </sf-stack-item>
    </sf-stack>
    <template slot="actions">
      <sf-button-group>
        <sf-button plain type="button" @click="close"> Cancel </sf-button>
        <sf-button :disabled="this.disabled" :loading="saving" @click="submit">
          {{ saveText }}
        </sf-button>
      </sf-button-group>
    </template>
  </sf-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import SfSelect from '@/components/SfSelect'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfFormItem,
    SfInput,
    SfModal,
    SfSelect,
    SfStack,
    SfStackItem,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    initialConfig: {
      type: Object,
    },
    save: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      config: cloneDeep(this.initialConfig),
      saving: false,
      types: ['mirror-bridge', 'bridge'],
    }
  },

  computed: {
    disabled() {
      return !this.config.friendly_name
    },

    saveText() {
      return this.editing ? 'Save' : 'Add'
    },

    title() {
      return this.editing ? 'Edit bridge' : 'Add bridge'
    },

    typeOptions() {
      return this.types.map((type) => ({
        label: type,
        value: type,
      }))
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    async submit() {
      this.saving = true
      try {
        await this.save(this.config)
      } finally {
        this.saving = false
      }
    },

    emitDirty() {
      this.$emit('dirty')
    },
  },
}
</script>
