<template>
  <sf-popper
    ref="popper"
    :config="{ placement: 'bottom-end', strategy: 'fixed' }"
  >
    <div slot-scope="{ isOpen, close, toggle }">
      <sf-on-outside-click :handle="close">
        <div data-trigger>
          <slot name="trigger" v-bind="{ toggleOpen: toggle }" />
        </div>
      </sf-on-outside-click>
      <div
        data-popper
        v-show="isOpen"
        class="bg-white border border-gray-300 cursor-auto divide-y overflow-auto rounded shadow-lg top-full z-50"
        :style="{ maxHeight: '420px', maxWidth: '320px' }"
      >
        <slot />
      </div>
    </div>
  </sf-popper>
</template>

<script>
import SfOnOutsideClick from '@/components/OnOutsideClick'
import SfPopper from '@/components/SfPopper.vue'

export default {
  components: {
    SfOnOutsideClick,
    SfPopper,
  },
}
</script>
