import Vue from 'vue'
import PortalVue from 'portal-vue'
import './styles/tailwind.css'
import App from './App.vue'
import router, { progressBar } from './router'
import store from './store'
import config from './config.json'
import VueNativeSock from 'vue-native-websocket'
import apiService from '@/services/apiService'
import Error from '@/components/SfError'
import sensorName from '@/filters/sensorName'

Vue.config.productionTip = false
Vue.use(PortalVue)

apiService
  .getInitialConfig()
  .then(({ data: initialConfig }) => {
    store.commit('setId', initialConfig.name)
    store.commit('setFriendlyName', initialConfig.friendly_name)
    store.commit('setMode', initialConfig.mode)
    store.commit('setUserManagement', initialConfig.user_management)

    if (!initialConfig.user_management) {
      return initialConfig
    }
    return store
      .dispatch('auth/getSession')
      .then(() => {
        return initialConfig
      })
      .catch(() => {
        return initialConfig
      })
  })
  .then((initialConfig) => {
    const app = new Vue({
      router,
      store,
      render: (h) => h(App),
      provide() {
        return {
          $progressBar: progressBar,
        }
      },
    })

    // Set title according to mode
    if (initialConfig.mode === 'fm') {
      document.title = 'Fleet Management - SensorFleet'
    } else if (initialConfig.mode === 'sensor') {
      document.title =
        sensorName(initialConfig.name, initialConfig.friendly_name) +
        ' - SensorFleet'
    } else {
      // eslint-disable-next-line no-console
      console.error('Unknown mode: ' + initialConfig.mode)
      document.title = 'Sensor 1 - SensorFleet'
    }

    // Create websocket
    const host = window.location.host
    const pathname = window.location.pathname
    let wsProto = 'ws://'
    if (window.location.protocol === 'https:') {
      wsProto = 'wss://'
    }

    Vue.use(
      VueNativeSock,
      wsProto + host + pathname + config.api_path + '/ws',
      {
        reconnection: true,
        format: 'json',
        store: store,
      }
    )
    store.$socket = Vue.prototype.$socket

    app.$mount('#app')
  })
  .catch(() => {
    // Show error page if backend fails
    new Vue(Error).$mount('#app')
  })
