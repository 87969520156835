<template>
  <div :class="classes">
    <div class="icon">
      <font-awesome-icon :icon="icon" />
    </div>
    <div class="message">
      <slot />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBan,
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon,
  },

  props: {
    status: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        toast: true,
        [`is-status-${this.status}`]: !!this.status,
      }
    },

    icon() {
      return {
        alert: faBan,
        success: faCheck,
        warning: faExclamationTriangle,
      }[this.status]
    },
  },
}
</script>

<style lang="scss">
.toast {
  background: rgba($grey-900, 0.9);
  border-radius: 3px;
  color: $white;
  display: inline-flex;
  padding: 1rem 1.6rem;
}

.message {
  margin-left: 1rem;
}

.is-status-success .icon {
  color: $green-400;
}

.is-status-warning .icon {
  color: $yellow-400;
}

.is-status-alert .icon {
  color: $red-500;
}
</style>
