<template>
  <div class="tooltip">
    <div
      class="trigger"
      @mouseenter="handleMouseEnter"
      @mousemove="handleMouseMove"
      @mouseleave="handleMouseLeave"
    >
      <slot name="trigger" />
    </div>
    <transition name="fade">
      <div v-if="visible" class="bubble" ref="bubble" :style="styles">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      visible: false,
      x: 0,
      y: 0,
    }
  },

  watch: {
    disabled(val) {
      if (this.visible && val) {
        this.visible = false
      }
    },
  },

  computed: {
    styles() {
      return {
        top: `${this.y}px`,
        left: `${this.x}px`,
      }
    },
  },

  methods: {
    handleMouseEnter() {
      if (!this.disabled) {
        this.visible = true
      }
    },

    handleMouseLeave() {
      if (!this.disabled) {
        this.visible = false
      }
    },

    handleMouseMove(event) {
      if (!this.disabled) {
        let x = event.clientX
        let y = event.clientY

        const { offsetWidth } = this.$refs.bubble
        if (x + offsetWidth >= window.innerWidth) {
          x = x - offsetWidth
        }

        this.x = x
        this.y = y
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bubble {
  background: $grey-darker;
  border-radius: 3px;
  color: $white;
  font-size: 1.4rem;
  font-weight: 400;
  max-width: 480px;
  padding: 0.8rem 1rem;
  pointer-events: none;
  position: fixed;
  text-align: left;
  text-transform: none;
  white-space: initial;
  z-index: 20;
}

.bubble > ul {
  list-style: none;
}
</style>
