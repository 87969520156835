<template>
  <component
    :cy="cy"
    :is="tagName"
    :class="classes"
    :disabled="disabled || loading"
    v-bind="props"
    @click="onClick"
  >
    <span class="inner">
      <span v-if="$slots.icon" class="icon">
        <slot name="icon" />
      </span>
      <span v-if="$slots.default" class="text">
        <slot />
      </span>
    </span>
    <span v-if="loading" class="loader">
      <span />
      <span />
      <span />
    </span>
  </component>
</template>

<script>
export default {
  props: {
    cy: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    fullWidth: {
      type: Boolean,
    },
    href: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    plain: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    target: {
      type: String,
    },
    to: {
      type: [Object, String],
    },
    type: {
      type: String,
    },
  },

  computed: {
    tagName() {
      if (this.disabled) {
        return 'button'
      } else if (this.href) {
        return 'a'
      } else if (this.to) {
        return 'router-link'
      }
      return 'button'
    },

    props() {
      if (this.disabled) {
        return {
          type: 'button',
        }
      } else if (this.href) {
        return {
          href: this.href,
          target: this.target,
          rel: this.target && 'noreferrer noopener',
        }
      } else if (this.to) {
        return { to: this.to }
      } else if (this.type) {
        return { type: this.type }
      }
      return {}
    },

    classes() {
      return {
        button: true,
        'is-disabled': this.disabled || this.loading,
        'is-full-width': this.fullWidth,
        'is-loading': this.loading,
        'is-plain': this.plain,
        [`is-${this.size}`]: !!this.size,
      }
    },
  },

  methods: {
    onClick(evt) {
      if (!this.disabled && !this.loading) {
        this.$emit('click', evt)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  background: linear-gradient(180deg, $grey-100, $grey-200);
  border: 1px solid $grey-500;
  border-radius: 3px;
  box-shadow: 0px 1px 2px $grey-200;
  color: $grey-700;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  padding: 0.6rem 1.4rem;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button:not([disabled]):hover {
  background: linear-gradient(180deg, $grey-000, $grey-100);
}

.button:not([disabled]):focus {
  border-color: $blue-500;
  box-shadow: 0 0 0 2px $blue-100;
  outline: 0;
}

.button:not([disabled]):active {
  background: $grey-200;
  box-shadow: none;
}

.inner {
  align-items: center;
  display: flex;
}

.inner > .icon + .text {
  margin-left: 0.5em;
}

.loader {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.15s;
}

.loader span {
  animation: loader 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 0.15em solid currentColor;
  border-color: currentColor transparent transparent transparent;
  border-radius: 50%;
  display: block;
  height: 1.2em;
  position: absolute;
  width: 1.2em;
}

.loader span:nth-child(1) {
  animation-delay: -0.3s;
}

.loader span:nth-child(2) {
  animation-delay: -0.2s;
}

.loader span:nth-child(3) {
  animation-delay: -0.1s;
}

.is-full-width {
  display: block;
  width: 100%;
}

.is-full-width > .inner {
  justify-content: center;
}

.is-plain {
  background: $white;
}

.is-plain:not([disabled]):hover {
  background: $white;
}

.is-plain:not([disabled]):active {
  background: $grey-200;
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.is-loading .inner {
  opacity: 0;
}

.is-small {
  font-size: 1.4rem;
  padding: 0.4rem 1.2rem;
}
</style>
