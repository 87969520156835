<template>
  <div class="annotated-section">
    <div class="header">
      <h1 v-if="title" class="title">{{ title }}</h1>
      <div class="help">
        <sf-markdown>
          {{ help }}
        </sf-markdown>
      </div>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
import SfMarkdown from '@/components/SfMarkdown'

export default {
  components: {
    SfMarkdown,
  },

  props: {
    title: {
      type: String,
    },
    help: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.annotated-section {
  display: flex;
}

.header {
  flex: 0 0 40%;
  padding: 0 3rem 0 0;
  overflow-wrap: anywhere;
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0 0 10px;
}

.help {
  color: $grey-600;
}

.body {
  flex: 1;
}

.annotated-section + .annotated-section {
  border-top: 1px solid $grey-300;
  box-shadow: inset 0 1px 0 $white;
  margin-top: 3rem;
  padding-top: 3rem;
}

@media screen and (max-width: 767px) {
  .annotated-section {
    display: block;
  }

  .header {
    padding: 0 0 2rem;
  }
}
</style>
