<template>
  <div>
    <sf-retention-config v-model="rules" @submit="save" @dirty="setDirty" />
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </div>
</template>

<script>
import apiService from '@/services/apiService'
import SfRetentionConfig from '@/views/RetentionConfig'
import { Handled } from '@/helpers/errors'
import confirmUnsaved from '@/mixins/confirmUnsaved'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfRetentionConfig,
  },

  async preload({ route }) {
    const { data: rules } = await apiService.getSensorRetentionRules(
      route.params.id
    )
    return {
      rules,
    }
  },

  methods: {
    async save() {
      try {
        await apiService.updateSensorRetentionRules(
          this.$route.params.id,
          this.rules
        )
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Configuration saved',
        })
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to save configuration',
          })
        }
      }
    },
  },
}
</script>
