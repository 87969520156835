<template>
  <div>
    <sf-header title="Instruments">
      <template slot="health">
        <sf-tooltip :disabled="this.sensorHealth(id).status === 'ok'">
          <sf-health :status="this.sensorHealth(id).status" slot="trigger">
            {{ this.sensorHealth(id).label }}
          </sf-health>
          <sf-health-messages :health="this.health" mode="sensor" />
        </sf-tooltip>
      </template>
      <div slot="actions">
        <sf-button :to="`/sensors/${id}/add_instrument`">
          Add Instrument
        </sf-button>
      </div>
    </sf-header>
    <sf-card>
      <template v-if="externalInstruments.length">
        <sf-instrument-list
          :sensor-id="id"
          :instruments="externalInstruments"
          @on-instrument-delete="instrumentDeleted"
        />
      </template>
      <template v-if="internalInstruments.length">
        <div
          class="flex items-center justify-center"
          :class="externalInstruments.length ? 'bg-gray-200 h-2' : 'h-px'"
        >
          <span
            class="bg-white border border-gray-300 px-5 py-2 relative rounded-full text-gray-600 text-xl z-10"
          >
            Platform instruments ({{ internalInstruments.length }})
          </span>
        </div>
        <sf-instrument-list
          :header="false"
          :sensor-id="id"
          :instruments="internalInstruments"
          @on-instrument-delete="instrumentDeleted"
        />
      </template>
    </sf-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfHeader from '@/components/SfHeader'
import SfTooltip from '@/components/SfTooltip'
import SfHealth from '@/components/SfHealth'
import SfHealthMessages from '@/components/SfHealthMessages'
import SfInstrumentList from '@/components/InstrumentList'
import apiService from '@/services/apiService'
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import healthForInstruments from '@/helpers/healthForInstruments'
import { defaultStorage } from '@/services/browserStorage'

const Storage = {
  SHOW_ALL_INSTRUMENTS: 'overview.showAllInstruments',
}

export default {
  components: {
    SfButton,
    SfCard,
    SfHeader,
    SfTooltip,
    SfHealth,
    SfHealthMessages,
    SfInstrumentList,
  },

  async preload({ route }) {
    const { data } = await apiService.getInstruments(route.params.id)
    const {
      data: { friendly_name },
    } = await apiService.getSensorSettings(route.params.id)
    return { instruments: data, friendly_name }
  },

  data() {
    return {
      showAllInstruments: defaultStorage.get(
        Storage.SHOW_ALL_INSTRUMENTS,
        false
      ),
    }
  },

  computed: {
    ...mapState(['id']),
    ...mapGetters('health', ['sensorHealth', 'instrumentHealth']),

    health() {
      // Set friendly names for sensor and instruments
      let health = cloneDeep(this.sensorHealth(this.id))
      health.friendly_name = this.friendly_name

      if (!health.components) {
        return health
      }

      for (const instrument of Object.values(this.instruments)) {
        if (has(health, `components.${instrument.id}`)) {
          health.components[instrument.id].friendly_name =
            instrument.friendly_name || instrument.default_name
        }
      }

      return health
    },

    instrumentsWithHealth() {
      return healthForInstruments(this.instruments, this.instrumentHealth)
    },

    externalInstruments() {
      return this.instrumentsWithHealth.filter((i) => !i.internal)
    },

    internalInstruments() {
      return this.instrumentsWithHealth.filter((i) => i.internal)
    },
  },

  methods: {
    instrumentDeleted(id, instruments) {
      this.instruments = instruments
    },
  },
}
</script>
