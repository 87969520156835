<template>
  <div class="error">
    <div class="container">
      <a class="logo" href="/">
        <img
          alt="SensorFleet"
          :src="require('@/assets/sensorfleet-white.png')"
        />
      </a>
      <sf-notification status="warning" title="Something went wrong">
        <p>
          Please try
          <a href="javascript:location.reload()">reloading the page</a>.
        </p>
      </sf-notification>
    </div>
  </div>
</template>

<script>
import SfNotification from '@/components/SfNotification.vue'

export default {
  components: {
    SfNotification,
  },
}
</script>

<style lang="scss" scoped>
.error {
  background: $grey-800;
  display: flex;
  height: 100vh;
  padding: 20vh 2rem;
  width: 100vw;
}

.container {
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
}

.logo {
  display: block;
  margin: 0 0 2rem;
}

.logo > img {
  display: block;
  margin: 0 auto;
  max-height: 30px;
}
</style>
