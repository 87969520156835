<template>
  <sf-markdown>
    {{ value }}
  </sf-markdown>
</template>
<script>
import SfMarkdown from '@/components/SfMarkdown'

export default {
  components: {
    SfMarkdown,
  },
  props: {
    value: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.help {
  color: $grey-600;
}
</style>
