<template>
  <config-tabs :sensor="sensor">
    <router-view />
  </config-tabs>
</template>

<script>
import apiService from '@/services/apiService'
import ConfigTabs from '@/routes/sensors/[id]/_components/ConfigTabs'

export default {
  components: {
    ConfigTabs,
  },

  async preload({ route }) {
    const { data: sensor } = await apiService.getSensor(route.params.id)
    return {
      sensor,
    }
  },
}
</script>
