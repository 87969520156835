<template>
  <sf-base-view :title="this.sensor.id | sensorName(this.sensor.friendly_name)">
    <sf-tabs slot="tabs">
      <sf-tabs-item
        v-if="mode === 'sensor'"
        :to="`/sensors/${$route.params.id}/instruments`"
      >
        Instruments
      </sf-tabs-item>
      <sf-tabs-item :to="`/sensors/${$route.params.id}/configure/bridges`">
        Bridges
      </sf-tabs-item>
      <sf-tabs-item :to="`/sensors/${$route.params.id}/configure/retention`">
        Data retention
      </sf-tabs-item>
      <sf-tabs-item :to="`/sensors/${$route.params.id}/configure/settings`">
        Settings
      </sf-tabs-item>
      <sf-tabs-item :to="`/sensors/${$route.params.id}/configure/system`">
        System
      </sf-tabs-item>
    </sf-tabs>
    <slot />
  </sf-base-view>
</template>

<script>
import { mapState } from 'vuex'
import SfBaseView from '@/components/BaseView'
import SfTabs from '@/components/SfTabs'
import SfTabsItem from '@/components/TabsItem'
import sensorName from '@/filters/sensorName'
import apiService from '@/services/apiService'

export default {
  components: {
    SfBaseView,
    SfTabs,
    SfTabsItem,
  },

  filters: {
    sensorName,
  },

  async preload({ route }) {
    const { data: sensor } = await apiService.getSensor(route.params.id)
    return {
      sensor,
    }
  },

  props: {
    sensor: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(['mode']),
  },
}
</script>
