<template>
  <div :class="classes">
    <div class="table-wrap">
      <div ref="scrollArea" class="scroll-area" @scroll="onScrollOrResize">
        <table class="root">
          <thead class="head" :class="{ hidden: !header }">
            <tr>
              <th
                v-for="(column, index) in columns"
                :key="column.field"
                :class="headCellClasses(column)"
                @click="onClickHeadCell(index)"
                :style="{ minWidth: column.minWidth, width: column.width }"
              >
                {{ column.label }}
                <span v-if="sortedBy(index)" class="sort-icon">
                  <font-awesome-icon :icon="sortIcon" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="body">
            <tr
              v-for="(row, index) in data"
              :key="index"
              :cy="rowCy(row)"
              class="body-row"
              @click="$emit('click-row', row)"
            >
              <td
                v-for="column in columns"
                :key="column.field"
                :class="bodyCellClasses(column)"
                :style="{ minWidth: column.minWidth, width: column.width }"
                :data-label="column.label"
              >
                <div class="body-cell-content">
                  <slot
                    :name="`column-${column.field}`"
                    :row="row"
                    :index="index"
                  >
                    {{ row[column.field] }}
                  </slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="scroll-shadow" />
    </div>
    <div v-if="footerVisible && pagination" class="footer">
      <div class="pagination">
        <div class="page-size">
          <div class="label">Items per page:</div>
          <div class="select">
            <sf-select
              size="small"
              :options="pageSizeOptions"
              :value="pageSize"
              @input="onChangePageSize"
            />
          </div>
        </div>
        <div class="prev-next">
          <div class="label">Page {{ page }}/{{ pageCount }}</div>
          <div class="buttons">
            <sf-button-group>
              <sf-button
                size="small"
                :icon="icons.faArrowLeft"
                :disabled="!prevEnabled"
                @click="goToPrevPage"
              />
              <sf-button
                size="small"
                :icon="icons.faArrowRight"
                :disabled="!nextEnabled"
                @click="goToNextPage"
              />
            </sf-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import throttle from 'lodash/throttle'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfSelect from '@/components/SfSelect.vue'

export default {
  components: {
    FontAwesomeIcon,
    SfButton,
    SfButtonGroup,
    SfSelect,
  },

  props: {
    columns: {
      type: Array,
    },
    data: {
      type: Array,
    },
    header: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    pageSizes: {
      type: Array,
      default() {
        return [25, 50, 100]
      },
    },
    responsive: {
      type: Boolean,
      default: false,
    },
    rowCy: {
      type: Function,
      default: () => {},
    },
    sortField: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      scrolledToEnd: true,
    }
  },

  created() {
    this.icons = {
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      faArrowUp,
    }

    window.addEventListener('resize', this.onScrollOrResize)
  },

  mounted() {
    this.updateScroll()
  },

  destroyed() {
    window.removeEventListener('resize', this.onScrollOrResize)
  },

  computed: {
    classes() {
      return {
        'is-clickable': this.clickable,
        'is-responsive': this.responsive,
        'is-scrolled-to-end': this.scrolledToEnd,
      }
    },

    footerVisible() {
      return this.pageCount > 1
    },

    clickable() {
      return this.$listeners && this.$listeners['click-row']
    },

    nextEnabled() {
      return this.page < this.pageCount
    },

    prevEnabled() {
      return this.page > 1
    },

    pageCount() {
      return Math.ceil(this.data.length / this.pageSize)
    },

    pageSizeOptions() {
      return this.pageSizes.map((pageSize) => ({
        label: pageSize,
        value: pageSize,
      }))
    },

    sortIcon() {
      return this.sortOrder === 'asc'
        ? this.icons.faArrowUp
        : this.icons.faArrowDown
    },
  },

  methods: {
    headCellClasses(column) {
      const padding = this.wide ? 'cell-padding-wide' : 'cell-padding-normal'
      return {
        [padding]: true,
        'head-cell': true,
        'is-sortable': column.sortable,
        [`is-align-${column.align}`]: column.align,
      }
    },

    bodyCellClasses(column) {
      const padding = this.wide ? 'cell-padding-wide' : 'cell-padding-normal'
      return {
        'body-cell': true,
        [padding]: true,
        [`is-align-${column.align}`]: column.align,
      }
    },

    columnForField(field) {
      return this.columns.find((c) => c.field === field)
    },

    columnForIndex(index) {
      return this.columns[index]
    },

    onChangePageSize(pageSize) {
      this.$emit('update:page-size', Number(pageSize))
      this.$emit('update:page', 1)
    },

    onClickHeadCell(index) {
      const column = this.columnForIndex(index)
      if (!column.sortable) {
        return
      }
      if (this.sortField === column.field && this.sortOrder === 'desc') {
        this.$emit('update:sort-field', null)
        this.$emit('update:sort-order', null)
      } else if (this.sortField === column.field) {
        this.$emit(
          'update:sort-order',
          this.sortOrder === 'desc' ? 'asc' : 'desc'
        )
      } else {
        this.$emit('update:sort-field', column.field)
        this.$emit('update:sort-order', 'asc')
      }
    },

    onScrollOrResize: throttle(function () {
      this.updateScroll()
    }),

    goToNextPage() {
      if (this.page < this.pageCount) {
        this.$emit('update:page', this.page + 1)
      }
    },

    goToPrevPage() {
      if (this.page > 1) {
        this.$emit('update:page', this.page - 1)
      }
    },

    sortedBy(index) {
      return (
        this.sortField && this.sortField === this.columnForIndex(index).field
      )
    },

    updateScroll() {
      const { clientWidth, scrollLeft, scrollWidth } = this.$refs.scrollArea
      this.scrolledToEnd = scrollLeft + clientWidth >= scrollWidth - 60
    },
  },
}
</script>

<style lang="scss" scoped>
.table-wrap {
  position: relative;
}

.root {
  border-spacing: 0;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

.scroll-area {
  overflow-x: auto;
}

.head-cell {
  background: $white;
  border-bottom: 1px solid $grey-200;
  color: $grey-700;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}

.head-cell.is-sortable {
  cursor: pointer;
}

.body-cell {
  background: $white;
  border-bottom: 1px solid $grey-200;
}

.body-row:last-child .body-cell {
  border-bottom: 0;
}

.cell-padding-normal {
  padding: 1.5rem 2rem;
}

.cell-padding-wide {
  padding: 1.5rem 0.5rem;
}

.sort-icon {
  font-size: 0.8rem;
  margin-left: 0.25rem;
}

.footer {
  border-top: 1px solid $grey-200;
  color: $grey-dark;
  padding: 1rem 2rem;
}

.pagination {
  align-items: center;
  display: flex;
}

.pagination .page-size {
  align-items: center;
  display: flex;
}

.pagination .page-size > .select {
  margin-left: 0.75rem;
}

.pagination .prev-next {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.pagination .prev-next > .buttons {
  margin-left: 0.75rem;
}

.scroll-shadow {
  background: linear-gradient(90deg, rgba($white, 0), rgba($white, 1));
  bottom: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.25s;
  width: 60px;
}

.is-align-right {
  text-align: right;
}

.is-clickable .body-row {
  cursor: pointer;
}

.is-clickable .row:hover .body-cell {
  background: $grey-000;
}

.is-scrolled-to-end .scroll-shadow {
  opacity: 0;
}

@media screen and (max-width: 1023px) {
  .is-responsive .head {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .is-responsive .body-row {
    display: block;
  }

  .is-responsive .body-row:not(:last-child) .body-cell:last-child {
    border-bottom: 4px solid $grey-400;
  }

  .is-responsive .body-row .body-cell:not(:last-child) {
    border-bottom: 1px solid $grey-300;
  }

  .is-responsive .body-cell {
    display: flex;
    width: 100% !important;
  }

  .is-responsive .body-cell::before {
    color: $grey-700;
    content: attr(data-label);
    flex: 0 0 33.33%;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
  }

  .is-responsive .scroll-shadow {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .is-responsive .body-cell {
    display: block;
  }

  .is-responsive .body-cell::before {
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
