<template>
  <vue-mark-down :emoji="false">
    <slot />
  </vue-mark-down>
</template>

<script>
import VueMarkDown from '@adapttive/vue-markdown'

export default {
  components: {
    VueMarkDown,
  },
}
</script>

<style scoped>
::v-deep p {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

::v-deep br {
  display: block;
  margin: 1.5rem 0;
  line-height: 1.5rem;
  content: ' ';
}
</style>
