<template>
  <div :class="classes">
    <div class="container">
      <div class="header">
        <div class="nav-toggle" @click="toggleExpanded">
          <font-awesome-icon :icon="icons.faBars" />
        </div>
        <div>
          <router-link class="logo" :to="logoLink">
            <img src="../assets/sensorfleet-white.png" alt="Sensorfleet" />
            <span>{{ mode === 'fm' ? 'Management' : 'Sensor' }}</span>
          </router-link>
        </div>
      </div>
      <div class="nav nav-primary">
        <template v-if="userManagement && !isLoggedIn">
          <router-link
            class="nav-link"
            exact-active-class="is-active"
            to="/login"
          >
            <span class="inner">
              <span class="icon">
                <font-awesome-icon :icon="icons.faSignOutAlt" />
              </span>
              <span class="text">Log in</span>
            </span>
          </router-link>
        </template>
        <template v-else>
          <router-link
            v-if="!userManagement || isAdmin"
            class="nav-link"
            exact-active-class="is-active"
            to="/"
          >
            <div class="inner">
              <span class="icon">
                <font-awesome-icon :icon="icons.faServer" />
              </span>
              <span class="text">{{
                mode === 'fm' ? 'Sensors' : 'Sensor'
              }}</span>
            </div>
          </router-link>
          <router-link
            v-if="!userManagement || isAdmin"
            class="nav-link"
            exact-active-class="is-active"
            to="/overview"
          >
            <div class="inner">
              <span class="icon">
                <font-awesome-icon :icon="icons.faTh" />
              </span>
              <span class="text">Overview</span>
            </div>
          </router-link>
          <router-link
            v-if="!userManagement || isAdmin"
            class="nav-link"
            active-class="is-active"
            to="/events/basic"
          >
            <div class="inner">
              <span class="icon">
                <font-awesome-icon :icon="icons.faList" />
              </span>
              <span class="text">Events</span>
            </div>
          </router-link>
          <router-link
            v-if="userManagement && isAdmin"
            class="nav-link"
            active-class="is-active"
            to="/settings/users"
          >
            <div class="inner">
              <span class="icon">
                <font-awesome-icon :icon="icons.faCog" />
              </span>
              <span class="text">Settings</span>
            </div>
          </router-link>
        </template>
      </div>
      <div v-if="userManagement && isLoggedIn" class="nav nav-secondary">
        <sf-dropdown class="nav-dropdown" ref="dropdown">
          <template #trigger="{ toggleOpen }">
            <button
              slot="trigger"
              class="nav-link nav-link-user"
              type="button"
              @click="toggleOpen"
            >
              <span class="inner">
                <span class="icon">
                  <font-awesome-icon :icon="icons.faUser" />
                </span>
                <span class="text">{{ user.username }}</span>
                <span class="icon">
                  <font-awesome-icon :icon="icons.faCaretDown" />
                </span>
              </span>
            </button>
          </template>
          <div class="bg-white shadow w-80">
            <div class="px-8 py-4">
              Signed in as
              <span class="font-semibold">{{ user.username }}</span>
            </div>
            <sf-menu class="border-t">
              <sf-menu-item @click="$emit('edit-profile')"
                >Edit profile</sf-menu-item
              >
              <sf-menu-item @click="$emit('change-password')"
                >Change password</sf-menu-item
              >
            </sf-menu>
            <sf-menu class="border-t">
              <sf-menu-item @click="logOut">Log out</sf-menu-item>
            </sf-menu>
          </div>
        </sf-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBars,
  faCaretDown,
  faCog,
  faList,
  faSignInAlt,
  faSignOutAlt,
  faServer,
  faTable,
  faTh,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import SfDropdown from '@/components/SfDropdown'
import SfMenu from '@/components/SfMenu.vue'
import SfMenuItem from '@/components/MenuItem'

export default {
  components: {
    FontAwesomeIcon,
    SfDropdown,
    SfMenu,
    SfMenuItem,
  },

  data() {
    return {
      expanded: false,
    }
  },

  created() {
    this.icons = {
      faBars,
      faCaretDown,
      faCog,
      faList,
      faServer,
      faSignInAlt,
      faSignOutAlt,
      faTable,
      faTh,
      faUser,
    }
  },

  watch: {
    $route() {
      this.expanded = false
    },
  },

  computed: {
    ...mapState(['id', 'mode', 'userManagement']),
    ...mapState('auth', ['user']),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isLoggedIn: 'auth/isLoggedIn',
    }),

    classes() {
      return {
        navbar: true,
        'is-expanded': this.expanded,
      }
    },
    logoLink() {
      return '/'
    },
  },

  methods: {
    async logOut() {
      await this.$store.dispatch('auth/logOut')
      this.$router.push('/login')
    },
    toggleExpanded() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  background: $grey-800;
  font-size: 1.8rem;
  padding: 0 2rem;
}

.container {
  align-items: center;
  display: flex;
  height: 70px;
  margin: 0 auto;
  max-width: 100%;
}

.header {
  height: 100%;
  display: flex;
}

.nav-toggle {
  display: none;
}

.logo {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.logo > img {
  display: block;
  max-height: 2.8rem;
}

.logo > span {
  color: #adb5bd;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.nav {
  display: flex;
  font-size: 1.7rem;
  height: 100%;
}

.nav-primary {
  margin-left: auto;
  margin-right: -2rem;
}

.nav-secondary {
  margin-left: 2rem;
  margin-right: -2rem;
}

.nav-link {
  align-items: center;
  color: $grey-500;
  display: flex;
  height: 100%;
  padding: 0 1rem;
  text-decoration: none;
}

.nav-link .inner {
  border-radius: 3px;
  display: inline-block;
  padding: 0.6rem 1rem;
  white-space: nowrap;
}

.nav-link .inner > :not(:last-child) {
  margin-right: 0.8rem;
}

.nav-link .icon {
  display: inline-block;
  opacity: 0.5;
}

.nav-link.is-active {
  color: $white;
}

.nav-link.is-active .inner {
  background: $grey-900;
}

.nav-dropdown,
.nav-dropdown ::v-deep > div {
  display: flex;
  height: 100%;
}

@media screen and (max-width: 1023px) {
  .navbar {
    font-size: 1.6rem;
  }

  .nav-link {
    padding: 1rem 0.4rem;
  }

  .nav-link .inner {
    padding: 0.6rem 0.8rem;
  }

  .logo {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }

  .logo > img {
    flex-shrink: 0;
    max-height: 2.5rem;
  }

  .logo > span {
    display: block;
    font-size: 1.4rem;
    margin: 0;
  }
}

@media screen and (max-width: 1023px) {
  .navbar {
    padding: 0;
  }

  .container {
    display: block;
    height: auto;
    position: relative;
  }

  .header {
    height: 60px;
    position: relative;
  }

  .logo {
    align-items: center;
    bottom: 0;
    flex-direction: row;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav-toggle {
    align-items: center;
    color: $grey-400;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
  }

  .nav-primary {
    background: #fff;
    display: none;
    margin: 0;
  }

  .nav-primary .nav-link {
    border-bottom: 1px solid $grey-300;
    color: $grey-600;
    padding: 0;
  }

  .nav-primary .nav-link .inner {
    border-radius: 0;
    padding: 1.5rem 2rem;
  }

  .nav-primary .nav-link.is-active {
    color: $grey-900;
  }

  .nav-primary .nav-link.is-active .inner {
    background: none;
  }

  .nav-secondary {
    height: 60px;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav-link-user .text {
    display: none;
  }

  .is-expanded .nav-primary {
    display: block;
  }
}
</style>
