<template>
  <select class="select" :value="value" @change="handleChange">
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === value"
      :disabled="option.disabled"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
    },
  },

  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('change', event)
    },
  },
}
</script>

<style lang="scss">
.select {
  appearance: none;
  background: url('../assets/select.png') no-repeat, $white;
  background-position: calc(100% - 15px) 50%;
  background-size: 8px;
  border: 1px solid $grey-400;
  border-radius: 3px;
  box-shadow: 0 1px 2px $grey-200;
  cursor: pointer;
  display: inline-flex;
  padding: 0.6rem calc(1.2rem + 38px) 0.6rem 1.2rem;
  width: 100%;
}

.select:focus {
  border-color: $blue-500;
  box-shadow: 0 0 0 3px $blue-100;
  outline: 0;
}
</style>
