<template>
  <sf-card>
    <div class="bg-gray-100 border-b flex h-20 items-center px-8 rounded-t">
      <div class="font-medium">
        {{ config.name }}
      </div>
      <div class="flex gap-4 ml-auto">
        <sf-button
          size="small"
          type="button"
          @click="openRename"
          :disabled="!enabled"
        >
          <font-awesome-icon slot="icon" :icon="icons.faEdit" /> Rename
        </sf-button>
        <sf-button
          size="small"
          type="button"
          @click="$emit('remove')"
          :disabled="!enabled"
        >
          <font-awesome-icon slot="icon" :icon="icons.faTrashAlt" /> Remove
        </sf-button>
      </div>
    </div>
    <div class="body">
      <div class="font-medium mb-4">Range (CIDR)</div>
      <div class="mb-8 space-y-4">
        <div
          class="flex gap-4 mb-4"
          v-for="(range, index) in config.networks"
          :key="index"
        >
          <sf-input
            class="flex-1"
            :ref="setNetworkRef"
            v-model.trim="config.networks[index]"
            placeholder="CIDR (e.g. 10.0.0.0/8)"
            :disabled="!enabled"
            @input="changed"
            @keydown.enter.prevent="addRange"
          />
          <sf-button
            :disabled="config.networks.length < 2 || !enabled"
            plain
            type="button"
            @click="removeRange(index)"
          >
            Remove
          </sf-button>
        </div>
      </div>
      <sf-stack>
        <sf-stack-item span="1">
          <sf-button
            type="button"
            @click="addRange"
            :disabled="!allowAddRange || !enabled"
          >
            New range
          </sf-button>
        </sf-stack-item>
      </sf-stack>
    </div>
    <sf-modal
      v-if="renaming"
      title="Rename network"
      @enter="selectNewName"
      @close="cancelRename"
    >
      <sf-input
        ref="newName"
        v-model.trim="newName"
        @keydown.enter.prevent="confirmRename"
      />
      <template slot="actions">
        <sf-button-group>
          <sf-button plain type="button" @click="cancelRename">
            Cancel
          </sf-button>
          <sf-button
            :disabled="!config.name"
            type="button"
            @click="confirmRename"
          >
            Confirm
          </sf-button>
        </sf-button-group>
      </template>
    </sf-modal>
  </sf-card>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCard from '@/components/SfCard'
import SfInput from '@/components/SfInput.vue'
import SfModal from '@/components/SfModal.vue'
import SfStack from '@/components/SfStack.vue'
import SfStackItem from '@/components/StackItem'
import cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    FontAwesomeIcon,
    SfButton,
    SfButtonGroup,
    SfCard,
    SfInput,
    SfModal,
    SfStack,
    SfStackItem,
  },

  data() {
    return {
      newName: '',
      newRange: '',
      renaming: false,
      config: cloneDeep(this.value),
      networkRefs: [],
    }
  },

  created() {
    this.icons = {
      faEdit,
      faTrashAlt,
    }
  },

  computed: {
    allowAddRange() {
      return this.config.networks[this.config.networks.length - 1] !== ''
    },
  },

  methods: {
    addRange() {
      const lastNetwork = this.config.networks[this.config.networks.length - 1]
      if (lastNetwork === '') {
        return this.focusLastRangeInput()
      }
      this.config.networks.push('')
      this.$emit('input', this.config)
      this.$nextTick(this.focusLastRangeInput)
    },

    cancelRename() {
      this.renaming = false
    },

    confirmRename() {
      if (this.newName) {
        this.config.name = this.newName
        this.renaming = false
        this.$emit('input', this.config)
      }
    },

    selectNewName() {
      this.$refs.newName.select()
    },

    openRename() {
      this.newName = this.config.name
      this.renaming = true
    },

    removeRange(index) {
      this.config.networks.splice(index, 1)
      this.$emit('input', this.config)
    },

    changed() {
      this.$emit('input', this.config)
    },

    focusFirstRangeInput() {
      this.networkRefs[0]?.focus()
    },

    focusLastRangeInput() {
      this.networkRefs[this.networkRefs.length - 1]?.focus()
    },

    setNetworkRef(el) {
      if (el) {
        this.networkRefs.push(el)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  background: $grey-000;
  border-bottom: 1px solid $grey-200;
  display: flex;
  padding: 1.2rem 2rem;
}

.header > .title {
  font-weight: 500;
}

.header > .actions {
  margin-left: auto;
}

.actions > .remove {
  color: $red-700;
}

.body {
  padding: 2rem;
}

.grid-header {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.grid-row {
  margin-bottom: 2rem;
}
</style>
