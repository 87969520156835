import get from 'lodash/get'
import set from 'lodash/set'

export const defaultStorage = browserStorage('sensorfleet')

export function browserStorage(itemName, defaultData = {}) {
  const storage = window.localStorage || window.sessionStorage
  if (!storage) {
    return {
      get() {},
      set() {},
    }
  }
  let data
  try {
    data = JSON.parse(storage.getItem(itemName) || { ...defaultData })
  } catch (error) {
    data = { ...defaultData }
  }
  return {
    get(path, defaultValue) {
      return get(data, path, defaultValue)
    },
    set(path, value) {
      storage.setItem(itemName, JSON.stringify(set({ ...data }, path, value)))
    },
  }
}
