<template>
  <sf-modal
    title="Upgrade Sensor"
    width="narrow"
    @keyup.enter="upgrade"
    @close="close"
  >
    <sf-stack column>
      <sf-stack-item>
        <sf-card>
          <sf-card-section v-if="updateRunning && !upgradeRunning">
            <sf-spinner :loading="true" />
            <div class="check-text">Checking for upgrades...</div>
          </sf-card-section>
          <sf-card-section v-else-if="!availableUpgrades">
            Sensor is already up-to-date.
          </sf-card-section>
          <sf-card-section v-else>
            <sf-json-pretty :data="availableUpgrades" />
          </sf-card-section>
        </sf-card>
      </sf-stack-item>
      <sf-stack-item>
        <sf-progress
          :value="upgradePercent"
          v-if="upgradeRunning || upgradeCompleted"
        />
      </sf-stack-item>
      <sf-stack-item v-if="upgradeCompleted">
        Upgrade completed successfully.
      </sf-stack-item>
    </sf-stack>
    <template slot="actions">
      <sf-button-group>
        <sf-button
          plain
          type="button"
          @click="close"
          :disabled="upgradeRunning"
        >
          {{ closeButtonText }}
        </sf-button>
        <sf-button @click="upgrade" :disabled="!upgradeAllowed">
          Upgrade
        </sf-button>
      </sf-button-group>
    </template>
  </sf-modal>
</template>

<script>
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfModal from '@/components/SfModal'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfSpinner from '@/components/SfSpinner'
import SfProgress from '@/components/SfProgress'
import SfJsonPretty from '@/components/JsonPretty'
import get from 'lodash/get'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfModal,
    SfStack,
    SfStackItem,
    SfCard,
    SfCardSection,
    SfProgress,
    SfSpinner,
    SfJsonPretty,
  },

  props: {
    updateStatus: {
      type: Object,
    },
    upgradeStatus: {
      type: Object,
    },
  },

  computed: {
    updateRunning() {
      return !this.updateStatus || !this.updateStatus['completed']
    },

    availableUpgrades() {
      if (Object.keys(this.updateStatus['result']).length === 0) {
        return null
      }
      return this.updateStatus['result']
    },

    upgradeAllowed() {
      return (
        !this.updateRunning &&
        !this.updateStatus['result']['error'] &&
        !this.upgradeRunning &&
        Object.keys(this.updateStatus['result']).length !== 0 &&
        !get(this.upgradeStatus, 'completed', false)
      )
    },

    upgradeRunning() {
      return this.upgradeStatus && !this.upgradeStatus['completed']
    },

    upgradePercent() {
      if (!this.upgradeStatus || !this.upgradeStatus['percent']) {
        return 0.0
      }
      return this.upgradeStatus['percent']
    },

    upgradeCompleted() {
      return get(this.upgradeStatus, 'completed', false)
    },

    closeButtonText() {
      if (!this.updateStatus || !this.updateStatus['result']) {
        return 'Cancel'
      } else {
        return 'Close'
      }
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    async upgrade() {
      this.$emit('submit')
    },
  },
}
</script>
<style scoped>
.check-text {
  display: inline;
  margin-left: 2rem;
  vertical-align: super;
}
</style>
