<template>
  <div class="context-help">
    <sf-tooltip>
      <div class="content" slot="trigger">
        <div class="text" v-if="$slots.default">
          <slot />
        </div>
        <font-awesome-icon class="icon" :icon="icons.faQuestionCircle" />
      </div>
      <slot name="help" />
    </sf-tooltip>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import SfTooltip from '@/components/SfTooltip'

export default {
  components: {
    FontAwesomeIcon,
    SfTooltip,
  },

  created() {
    this.icons = {
      faQuestionCircle,
    }
  },
}
</script>

<style lang="scss" scoped>
.context-help {
  display: inline-block;
}

.content {
  align-items: center;
  display: inline-flex;
}

.text {
  margin-right: 0.5rem;
}

.icon {
  opacity: 0.5;
  position: relative;
  top: -1px;
}
</style>
