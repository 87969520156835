<template>
  <sf-modal title="Add user" width="narrow" @close="$emit('close')">
    <sf-form @submit.prevent="save">
      <sf-form-item label="Username">
        <sf-input ref="username" v-model="form.data.username" required />
      </sf-form-item>
      <sf-form-item label="Password">
        <div class="flex space-x-2">
          <sf-input
            ref="password"
            v-model="form.data.password"
            type="text"
            required
          />
          <sf-button plain type="button" @click="copyPassword">
            <font-awesome-icon slot="icon" :icon="icons.faCopy" />
            Copy
          </sf-button>
        </div>
      </sf-form-item>
      <sf-form-item label="Full name">
        <sf-input v-model="form.data.full_name" />
      </sf-form-item>
      <sf-form-item label="Email address">
        <sf-input v-model="form.data.email" type="email" />
      </sf-form-item>
      <sf-form-item label="Privileges">
        <sf-checkbox
          v-for="option in privilegeOptions"
          :key="option"
          v-model="form.data.privileges"
          :label="option"
          :value="option"
          required
        />
      </sf-form-item>
      <div class="flex justify-end mt-4">
        <sf-button-group>
          <sf-button
            :disabled="form.busy"
            plain
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </sf-button>
          <sf-button :loading="form.busy">Save</sf-button>
        </sf-button-group>
      </div>
    </sf-form>
  </sf-modal>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCheckbox from '@/components/SfCheckbox'
import SfForm from '@/components/SfForm'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import { USER_PRIVILEGES } from '@/helpers/privileges'

export default {
  components: {
    FontAwesomeIcon,
    SfButton,
    SfButtonGroup,
    SfCheckbox,
    SfForm,
    SfFormItem,
    SfInput,
    SfModal,
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        busy: false,
        data: {
          username: '',
          password: this.initialData.password ?? '',
          full_name: '',
          email: '',
          privileges: [],
        },
      },
    }
  },
  created() {
    this.icons = { faCopy }
    this.privilegeOptions = USER_PRIVILEGES
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.username.focus()
    })
  },
  methods: {
    async copyPassword() {
      try {
        this.$refs.password.select()
        document.execCommand('copy')
        this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Password copied to clipboard',
        })
      } catch (error) {
        this.$store.dispatch('toasts/raise', {
          status: 'alert',
          message: 'Failed to copy the password, please copy it manually',
        })
      }
    },
    async save() {
      this.form.busy = true
      try {
        await this.$store.dispatch('users/createUser', { data: this.form.data })
        this.$emit('success', { username: this.form.data.username })
      } catch (error) {
        this.$emit('error')
      } finally {
        this.form.busy = false
      }
    },
  },
}
</script>
