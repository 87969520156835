<template>
  <sf-card>
    <sf-card-section>
      <sf-form-item>
        <sf-checkbox :label="label" v-model="inputVal" />
        <label class="help" v-if="help">
          <sf-markdown>
            {{ help }}
          </sf-markdown>
        </label>
      </sf-form-item>
    </sf-card-section>
  </sf-card>
</template>
<script>
import SfCard from '@/components/SfCard'
import SfCardSection from '@/components/CardSection'
import SfFormItem from '@/components/FormItem'
import SfMarkdown from '@/components/SfMarkdown.vue'
import SfCheckbox from '@/components/SfCheckbox'

export default {
  components: {
    SfCard,
    SfCardSection,
    SfFormItem,
    SfMarkdown,
    SfCheckbox,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    cy: {
      type: String,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.help {
  color: $grey-600;
}
</style>
