import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import apiTokens from '@/store/apiTokens'
import toasts from '@/store/toasts'
import health from '@/store/health'
import users from '@/store/users'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    apiTokens,
    auth,
    toasts,
    health,
    users,
  },
  state: {
    id: undefined,
    friendly_name: '',
    mode: undefined,
    jobs: {},
    socketConnected: false,
    availableInstrumentsUpdating: false,
    configVersions: {},
    userManagement: false,
  },

  mutations: {
    setId(state, data) {
      state.id = data
    },

    setFriendlyName(state, data) {
      state.friendly_name = data
    },

    setMode(state, data) {
      state.mode = data
    },

    setUserManagement(state, userManagement) {
      state.userManagement = userManagement
    },

    removeFinishedJobs(state) {
      for (let id in state.jobs) {
        if (
          Object.hasOwnProperty.call(state.jobs, id) &&
          state.jobs[id].completed === true
        ) {
          delete state.jobs[id]
        }
      }
    },

    availableInstrumentsUpdating(state, value) {
      state.availableInstrumentsUpdating = value
    },

    setConfigVersion(state, { item, version }) {
      state.configVersions[item] = version
    },

    SOCKET_ONOPEN(state) {
      state.socketConnected = true
      this.$socket.sendObj({
        type: 'subscription',
        items: ['health'],
      })

      // Re-subscribe jobs after connection is lost
      // for example when upgrading itself
      Object.keys(state.jobs).forEach((job) => {
        this.$socket.sendObj({
          type: 'job_subscription',
          id: job,
        })
      })
    },

    SOCKET_ONCLOSE(state) {
      state.socketConnected = false
    },

    SOCKET_ONERROR(state) {
      state.socketConnected = false
    },

    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      switch (message.type) {
        case 'health':
          state.health.initialized = true
          state.health.health = message.data
          break
        case 'job_progress':
          Vue.set(state.jobs, message.data.id, message.data)
          break
        case 'get_available_instruments':
          state.availableInstrumentsUpdating = false
          break
      }
    },

    // mutations for reconnect methods
    SOCKET_RECONNECT() {},

    SOCKET_RECONNECT_ERROR(state) {
      state.socketConnected = false
    },
  },

  getters: {
    mode: (state) => {
      return state.mode
    },
    id: (state) => {
      return state.id
    },
  },

  actions: {
    removeFinishedJobs({ commit }) {
      commit('removeFinishedJobs')
    },
  },
})
