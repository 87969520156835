<template>
  <div class="app">
    <router-view />
    <sf-toast-center />
  </div>
</template>

<script>
import SfToastCenter from '@/components/ToastCenter'

export default {
  components: {
    SfToastCenter,
  },
}
</script>
