<template>
  <sf-base-view :title="this.sensor.id | sensorName(this.sensor.friendly_name)">
    <router-view />
  </sf-base-view>
</template>

<script>
import SfBaseView from '@/components/BaseView'
import sensorName from '@/filters/sensorName'
import apiService from '@/services/apiService'

export default {
  components: {
    SfBaseView,
  },

  filters: {
    sensorName,
  },

  async preload({ route }) {
    const { data: sensor } = await apiService.getSensor(route.params.id)
    return {
      sensor,
    }
  },
}
</script>
