<template>
  <sf-base-view :title="instrument.friendly_name || instrument.id">
    <sf-tabs slot="tabs">
      <sf-tabs-item
        :to="`/instruments/${$route.params.id}/configure/interfaces`"
      >
        Interfaces
      </sf-tabs-item>
      <sf-tabs-item
        :to="`/instruments/${$route.params.id}/configure/retention`"
      >
        Data retention
      </sf-tabs-item>
      <sf-tabs-item
        :to="`/instruments/${$route.params.id}/configure/downloads`"
      >
        Downloads
      </sf-tabs-item>
      <sf-tabs-item :to="`/instruments/${$route.params.id}/configure/settings`">
        Settings
      </sf-tabs-item>
      <sf-tabs-item :to="`/instruments/${$route.params.id}/configure/custom`">
        Custom configuration
      </sf-tabs-item>
    </sf-tabs>
    <router-view @refresh="refresh" />
  </sf-base-view>
</template>

<script>
import SfBaseView from '@/components/BaseView'
import SfTabs from '@/components/SfTabs'
import SfTabsItem from '@/components/TabsItem'
import apiService from '@/services/apiService'

export default {
  components: {
    SfBaseView,
    SfTabs,
    SfTabsItem,
  },

  async preload({ route }) {
    const { data: instrument } = await apiService.getInstrument(
      route.params.id.split('@')[1],
      route.params.id
    )
    return {
      instrument,
    }
  },

  methods: {
    async refresh() {
      const { data: instrument } = await apiService.getInstrument(
        this.$route.params.id.split('@')[1],
        this.$route.params.id
      )
      this.instrument = instrument
    },
  },
}
</script>
