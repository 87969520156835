<template>
  <div :class="classes" @click="toggle">
    <div class="track">
      <div class="knob" />
    </div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        switch: true,
        'is-active': this.value,
        'is-disabled': this.disabled,
      }
    },
  },

  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.switch {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  user-select: none;
}

.track {
  background: $grey-100;
  border: 1px solid $grey-400;
  border-radius: 1rem;
  height: 1.8rem;
  position: relative;
  transition: all 0.1s;
  width: 4rem;
}

.knob {
  background: $grey-300;
  border: 1px solid $grey-500;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba($grey-000, 0.25);
  height: 2rem;
  left: -0.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.1s ease-in-out;
  width: 2rem;
}

.label {
  margin-left: 1rem;
}

.is-active .knob {
  background: $blue-400;
  border-color: $blue-600;
  left: calc(100% - 1.2rem);
}

.is-disabled {
  cursor: not-allowed;
}

.is-disabled .track {
  opacity: 0.5;
}
</style>
