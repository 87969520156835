<template>
  <sf-popper
    ref="popper"
    :config="{ placement: 'bottom-start', strategy: 'fixed' }"
  >
    <div slot-scope="{ isOpen, close, toggle }">
      <sf-on-outside-click :handle="close">
        <div data-trigger class="cursor-pointer" @click="toggle">
          <sf-status-indicator v-if="status" :status="status">
            <template v-if="count > 1">
              {{ count }}
            </template>
          </sf-status-indicator>
        </div>
      </sf-on-outside-click>
      <div
        data-popper
        v-show="isOpen"
        class="bg-white border border-gray-300 cursor-auto divide-y overflow-auto rounded shadow-lg top-full z-50"
        :style="{ maxHeight: '420px', maxWidth: '320px' }"
      >
        <div v-for="(health, index) in healths" :key="health.instrument_id">
          <div
            class="bg-gray-100 border-b border-gray-300 font-semibold px-3 py-2"
            :class="{ 'border-t': index !== 0, '-mt-1': index !== 0 }"
          >
            <router-link
              class="font-semibold no-underline text-2xl text-gray-600 hover:underline"
              :to="'/instruments/' + health.instrument_id + '/configure'"
              >{{ health.instrument_id }}</router-link
            >
          </div>
          <div class="text-2xl text-gray-600">
            <ul v-if="health.messages.length" class="divide-y list-outside">
              <li
                v-for="message in health.messages"
                :key="message.title"
                class="p-2"
              >
                <sf-status-indicator :status="message.status" />
                <span class="break-words ml-2">{{ message.title }}</span>
              </li>
            </ul>
            <div v-else class="p-2">
              <sf-status-indicator status="ok" />
              <span class="ml-2">Status OK</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sf-popper>
</template>

<script>
import SfOnOutsideClick from '@/components/OnOutsideClick'
import SfPopper from '@/components/SfPopper'
import SfStatusIndicator from './StatusIndicator'

export default {
  components: {
    SfOnOutsideClick,
    SfPopper,
    SfStatusIndicator,
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
    healths: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
    },
  },
}
</script>
