export default {
  namespaced: true,

  state: {
    nextId: 1,
    toasts: [],
  },

  mutations: {
    incrementNextId(state) {
      state.nextId++
    },

    setToasts(state, toasts) {
      state.toasts = toasts
    },
  },

  actions: {
    raise({ commit, dispatch, state }, { status, message }) {
      const id = state.nextId
      commit('setToasts', [...state.toasts, { id, status, message }])
      commit('incrementNextId')
      setTimeout(() => dispatch('removeById', id), 2500)
    },

    removeById({ commit, state }, id) {
      commit(
        'setToasts',
        state.toasts.filter((t) => t.id !== id)
      )
    },
  },
}
