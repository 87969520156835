<template>
  <sf-modal
    v-on="current.matches('editing.saveButton.saving') ? {} : { close }"
    :title="this.editing ? 'Edit interface' : 'Add interface'"
    width="narrow"
    @keyup.enter="submit"
  >
    <sf-stack column>
      <sf-stack-item v-if="!editing">
        <sf-form-item label="Interface type">
          <sf-choice-group>
            <sf-radio label="Sensor bridge" value="bridge" v-model="type" />
            <sf-radio label="Physical" value="physical" v-model="type" />
          </sf-choice-group>
        </sf-form-item>
      </sf-stack-item>
      <template>
        <sf-stack-item>
          <sf-form-item label="Interface name">
            <sf-input
              ref="name"
              v-model.trim="config.friendly_name"
              cy="friendly-name"
              @click.native="$event.target.select()"
            />
          </sf-form-item>
        </sf-stack-item>
        <sf-stack-item>
          <sf-form-item
            v-if="current.matches('editing.bridgeSelector.visible')"
            label="Sensor bridge"
          >
            <sf-select
              :options="sensorInterfaceOptions"
              v-model="config.sensor_bridge_name"
            />
          </sf-form-item>
          <sf-form-item
            v-if="!current.matches('editing.physInterfaceSelector.hidden')"
            label="Physical interface"
          >
            <sf-select
              :options="physicalInterfaceOptions"
              v-model="config.sensor_physical_interface_name"
              :disabled="
                current.matches('editing.physInterfaceSelector.disabled')
              "
            />
          </sf-form-item>
        </sf-stack-item>
        <template v-if="current.matches('editing.ipAddressSelector.visible')">
          <sf-stack-item>
            <sf-switch label="DHCP" v-model="config.dhcp_client" />
            &nbsp;
            <sf-context-help>
              <template slot="help">
                Enable DHCPv4 client for this interface. If a DHCP server is
                reachable from the network, will acquire an IP address and apply
                routes and DNS servers offered by the DHCP server.
              </template>
            </sf-context-help>
          </sf-stack-item>
          <sf-stack-item
            v-if="current.matches('editing.ipAddressSelector.visible.static')"
          >
            <sf-form-item label="IP address and netmask in CIDR format">
              <sf-input
                placeholder="10.10.0.1/24"
                v-model.trim="config.ip_addresses[0]"
              />
            </sf-form-item>
          </sf-stack-item>
        </template>
        <sf-stack-item>
          <sf-form-item label="Capabilities">
            <sf-choice-group>
              <sf-checkbox
                label="RX"
                value="rx"
                v-model="config.capabilities"
              />
              <sf-checkbox
                label="TX"
                value="tx"
                v-model="config.capabilities"
              />
            </sf-choice-group>
          </sf-form-item>
        </sf-stack-item>
      </template>
    </sf-stack>
    <template slot="actions">
      <sf-button-group>
        <sf-button
          plain
          type="button"
          :disabled="current.matches('editing.saveButton.saving')"
          @click="close"
        >
          Cancel
        </sf-button>
        <sf-button
          :disabled="current.matches('editing.saveButton.disabled')"
          :loading="current.matches('editing.saveButton.saving')"
          @click="submit"
        >
          {{ this.editing ? 'Save' : 'Add' }}
        </sf-button>
      </sf-button-group>
    </template>
  </sf-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import SfButton from '@/components/SfButton'
import SfButtonGroup from '@/components/ButtonGroup'
import SfCheckbox from '@/components/SfCheckbox'
import SfChoiceGroup from '@/components/ChoiceGroup'
import SfContextHelp from '@/components/ContextHelp'
import SfFormItem from '@/components/FormItem'
import SfInput from '@/components/SfInput'
import SfModal from '@/components/SfModal'
import SfRadio from '@/components/SfRadio'
import SfSelect from '@/components/SfSelect'
import SfStack from '@/components/SfStack'
import SfStackItem from '@/components/StackItem'
import SfSwitch from '@/components/SfSwitch'
import { interpret } from 'xstate'
import InterfaceDialogMachine from './interfaceDialogMachine'
import isCidr from 'is-cidr'
import apiService from '@/services/apiService'

export default {
  components: {
    SfButton,
    SfButtonGroup,
    SfCheckbox,
    SfChoiceGroup,
    SfContextHelp,
    SfFormItem,
    SfInput,
    SfModal,
    SfRadio,
    SfSelect,
    SfStack,
    SfStackItem,
    SfSwitch,
  },

  props: {
    editing: {
      type: Boolean,
    },
    initialConfig: {
      type: Object,
    },
    physicalInterfaces: {
      type: Array,
      default() {
        return []
      },
    },
    save: {
      type: Function,
      required: true,
    },
    sensorInterfaces: {
      type: Array,
      default() {
        return []
      },
    },
  },

  created() {
    // Start service on component creation
    this.interfaceDialogService
      .onTransition((state) => {
        // Update the current state component data property with the next state
        this.current = state
        // Update the context component data property with the updated context
        this.context = state.context
      })
      .start()
  },

  data() {
    let initialContext
    if (!this.editing) {
      initialContext = {
        typeSelector: 'bridge',
        bridgeSelector: 'visible',
        physInterfaceSelector: 'hidden',
        ipAddressSelector: 'dhcp',
        saveButton: 'disabled',
      }
    } else {
      let ipAddressSelector = 'dhcp'
      if (this.initialConfig.type === 'mirror-bridge') {
        ipAddressSelector = 'hidden'
      } else if (this.initialConfig.ip_addresses.length) {
        ipAddressSelector = 'static'
      } else if (
        this.initialConfig.type === 'physical' &&
        !this.initialConfig.dhcp_client
      ) {
        ipAddressSelector = 'static'
      }

      initialContext = {
        typeSelector: 'hidden',
        bridgeSelector: 'visible',
        physInterfaceSelector:
          this.initialConfig.type === 'physical' ? 'disabled' : 'hidden',
        ipAddressSelector: ipAddressSelector,
        saveButton: 'enabled',
      }
    }

    return {
      // Interpret the machine and store it in data
      interfaceDialogService: interpret(
        InterfaceDialogMachine.withContext(initialContext)
      ),
      // Start with the machine's initial state
      current: InterfaceDialogMachine.initialState,
      // Start with the machine's initial context
      context: InterfaceDialogMachine.context,

      config: cloneDeep(this.initialConfig),
      type: this.initialConfig.type === 'physical' ? 'physical' : 'bridge',
    }
  },

  watch: {
    sensorInterfaceType: function (newVal) {
      if (newVal === 'mirror-bridge') {
        this.send('MIRROR_BRIDGE_SELECTED')
      } else if (newVal === 'bridge') {
        this.send('BRIDGE_SELECTED')
      }
    },

    config: {
      deep: true,
      handler(newVal) {
        // mark config as dirty when it's changed
        this.$emit('dirty')

        // check DHCP state
        if (newVal.dhcp_client === true) {
          this.send('DHCP_ON')
        } else {
          this.send('DHCP_OFF')
        }

        // fetch IP for bridge interface
        if (
          newVal.dhcp_client === false &&
          this.sensorInterfaceType === 'bridge' &&
          newVal.ip_addresses.length === 0
        ) {
          this.fetchFreeIp(newVal.sensor_bridge_name)
        }
      },
    },

    type(newVal) {
      this.$emit('dirty')
      if (newVal === 'bridge') {
        if (this.sensorInterfaceType === 'mirror-bridge') {
          this.send('MIRROR_BRIDGE_SELECTED')
        } else {
          this.send('BRIDGE_SELECTED')
        }
      } else if (newVal === 'physical') {
        this.send('PHYS_SELECTED')
      }
    },

    valid(newVal) {
      if (newVal === true) {
        this.send('CONFIG_VALID')
      } else {
        this.send('CONFIG_INVALID')
      }
    },
  },

  computed: {
    valid() {
      if (!this.config.friendly_name) {
        return false
      } else if (
        this.type === 'bridge' &&
        this.current.matches('editing.ipAddressSelector.visible.static') &&
        this.config.ip_addresses[0] &&
        this.config.ip_addresses[0].length > 0 &&
        !isCidr(this.config.ip_addresses[0])
      ) {
        // Check for invalid IP, empty IP is allowed
        return false
      }
      return (
        (this.type === 'bridge' && this.config.sensor_bridge_name !== 'none') ||
        (this.type === 'physical' &&
          this.config.sensor_physical_interface_name !== 'none' &&
          (this.config.dhcp_client ||
            !this.config.ip_addresses[0] ||
            this.config.ip_addresses[0] === '' ||
            !!isCidr(this.config.ip_addresses[0])))
      )
    },

    physicalInterfaceOptions() {
      // Display current interface only when editing, filter rest
      let filteredInterfaces
      if (this.editing) {
        filteredInterfaces = this.physicalInterfaces.filter(
          (i) =>
            i.user === null ||
            i.name === this.initialConfig.sensor_physical_interface_name
        )
      } else {
        filteredInterfaces = this.physicalInterfaces.filter(
          (i) => i.user === null
        )
      }

      // Create labels and values for dropdown
      let options = [
        {
          label: 'None',
          value: 'none',
        },
        ...filteredInterfaces.map((i) => ({
          label: i.name + ' (driver: ' + i.driver + ', mac: ' + i.mac + ')',
          value: i.name,
        })),
      ]

      // Replace current interface label. We don't have MAC and driver for it because it's in use
      if (this.editing) {
        options = options.map((i) => {
          if (i.value === this.initialConfig.sensor_physical_interface_name) {
            return {
              label: i.value,
              value: i.value,
            }
          } else {
            return i
          }
        })
      }

      return options
    },

    sensorInterfaceOptions() {
      return [
        {
          label: 'None',
          value: 'none',
        },
        ...this.sensorInterfaces.map((i) => ({
          label: i.friendly_name,
          value: i.id,
        })),
      ]
    },

    sensorInterfaceType() {
      const iface = this.sensorInterfaces.filter(
        (sensorInterface) =>
          sensorInterface.id === this.config.sensor_bridge_name
      )[0]

      return iface ? iface.type : null
    },
  },

  methods: {
    // Send events to the service
    send(event) {
      // console.log(event)
      this.interfaceDialogService.send(event)
    },

    async fetchFreeIp(bridge) {
      const reply = await apiService.getFreeIp(
        this.$route.params.id.split('@')[1],
        bridge
      )
      // Replace whole array so Vue reactivity detects it
      this.config.ip_addresses = [reply.data.ip]
    },

    async submit() {
      this.send('SAVING')
      this.config.type = this.type === 'physical' ? 'physical' : 'bridge'

      // Delete IP if sensor bridge is mirror
      if (this.sensorInterfaceType === 'mirror-bridge') {
        this.config.ip_addresses[0] = null
        this.config.dhcp_client = null
      }

      try {
        await this.save({ ...this.config })
        this.send('SAVED')
      } catch (error) {
        this.send('SAVE_FAILED')
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
