<template>
  <div :class="classes">
    <div class="blip">
      <div class="inner" />
    </div>
    <div class="label">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        health: true,
        [`is-status-${this.convertedStatus}`]: this.status,
      }
    },

    convertedStatus() {
      if (['error', 'critical'].includes(this.status)) {
        return 'error'
      }
      return this.status
    },
  },
}
</script>

<style lang="scss" scoped>
.health {
  align-items: center;
  color: $grey-700;
  display: inline-flex;
}

.blip {
  align-items: center;
  background: $grey-100;
  border-radius: 50%;
  box-shadow: inset 0 1px 2px $grey-600;
  display: flex;
  height: 1.8rem;
  justify-content: center;
  width: 1.8rem;
}

.blip .inner {
  background: $grey-600;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
}

.label {
  margin-left: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}

.is-status-info {
  color: $blue-800;
}

.is-status-info .blip {
  background: $blue-000;
  box-shadow: inset 0 1px 2px $blue-600;
}

.is-status-info .blip .inner {
  background: $blue-600;
}

.is-status-ok {
  color: $green-900;
}

.is-status-ok .blip {
  background: $green-000;
  box-shadow: inset 0 1px 2px $green-600;
}

.is-status-ok .blip .inner {
  background: $green-700;
}

.is-status-warning {
  color: $yellow-900;
}

.is-status-warning .blip {
  background: $yellow-100;
  box-shadow: inset 0 1px 2px $yellow-600;
}

.is-status-warning .blip .inner {
  background: $yellow-900;
}

.is-status-error {
  color: $red-900;
}

.is-status-error .blip {
  background: $red-100;
  box-shadow: inset 0 1px 2px $red-600;
}

.is-status-error .blip .inner {
  background: $red-800;
}
</style>
