<template>
  <div class="configure-downloads">
    <template v-if="downloads.length">
      <div class="header">
        <h2 class="title">Downloads</h2>

        <div class="actions">
          <span class="mx-8" v-if="!this.allowEdit">
            Downloads are controlled by the Instrument.
          </span>
          <sf-button :disabled="!this.allowEdit" @click="openAddDialog">
            Add Download
          </sf-button>
        </div>
      </div>
      <sf-card>
        <sf-downloads-list
          :downloads="downloads"
          :allowEdit="this.allowEdit"
          @edit="openEditDialog"
          @delete="deleteDownload"
        />
      </sf-card>
    </template>
    <template v-else>
      <sf-notification title="No downloads">
        <p class="mb-4">This instrument has no downloads configured.</p>
        <sf-button @click="openAddDialog"> Add download </sf-button>
      </sf-notification>
    </template>
    <sf-download-dialog
      v-if="newDownload"
      :initial-config="newDownload"
      :save="createDownload"
      @close="closeAddDialog"
      @dirty="setDirty"
    />
    <sf-download-dialog
      v-if="editedDownload"
      :editing="true"
      :initial-config="editedDownload"
      :save="updateDownload"
      @close="closeEditDialog"
      @dirty="setDirty"
    />
    <sf-confirm-dialog ref="deleteConfirm" title="Delete download?">
      <p>This action cannot be undone.</p>
    </sf-confirm-dialog>
    <sf-unsaved-dialog ref="confirmUnsaved" />
  </div>
</template>

<script>
import apiService from '@/services/apiService'
import SfButton from '@/components/SfButton'
import SfCard from '@/components/SfCard'
import SfConfirmDialog from '@/components/ConfirmDialog'
import SfDownloadDialog from './_components/DownloadDialog'
import SfDownloadsList from './_components/DownloadsList'
import SfNotification from '@/components/SfNotification'
import { Handled } from '@/helpers/errors'
import confirmUnsaved from '@/mixins/confirmUnsaved'

export default {
  mixins: [confirmUnsaved],

  components: {
    SfButton,
    SfCard,
    SfConfirmDialog,
    SfDownloadDialog,
    SfDownloadsList,
    SfNotification,
  },

  async preload({ route }) {
    const { data: downloads } = await apiService.getInstrumentDownloads(
      route.params.id.split('@')[1],
      route.params.id
    )

    const { data: allowEdit } = await apiService.getAllowEditDownloads(
      route.params.id.split('@')[1],
      route.params.id
    )

    return {
      downloads,
      allowEdit,
    }
  },

  data() {
    return {
      editedDownload: null,
      newDownload: null,
    }
  },

  methods: {
    openAddDialog() {
      this.newDownload = {
        output_filename: '',
        download_url: '',
        poll_interval_sec: 60,
        headers: [],
        meta: {},
      }
    },

    openEditDialog(download) {
      this.editedDownload = {
        output_filename: download.output_filename,
        download_url: download.download_url,
        poll_interval_sec: download.poll_interval_sec,
        id: download.id,
        headers: download.headers || [],
        meta: download.meta || {},
      }
    },

    closeAddDialog() {
      this.clearDirty()
      this.newDownload = null
    },

    closeEditDialog() {
      this.clearDirty()
      this.editedDownload = null
    },

    async createDownload(config) {
      try {
        await apiService.createInstrumentDownload(
          this.$route.params.id.split('@')[1],
          this.$route.params.id,
          config
        )
        const response = await apiService.getInstrumentDownloads(
          this.$route.params.id.split('@')[1],
          this.$route.params.id
        )
        this.downloads = response.data
        this.closeAddDialog()
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Download added',
        })
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to add download',
          })
        }
      }
    },

    async deleteDownload(downloadId) {
      try {
        await this.$refs.deleteConfirm.open(async () => {
          await apiService.deleteInstrumentDownload(
            this.$route.params.id.split('@')[1],
            this.$route.params.id,
            downloadId
          )
          const response = await apiService.getInstrumentDownloads(
            this.$route.params.id.split('@')[1],
            this.$route.params.id
          )
          this.downloads = response.data
          await this.$store.dispatch('toasts/raise', {
            status: 'success',
            message: 'Download removed',
          })
        })
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to remove download',
          })
        }
      }
    },

    async updateDownload(config) {
      try {
        await apiService.updateInstrumentDownload(
          this.$route.params.id.split('@')[1],
          this.$route.params.id,
          this.editedDownload.id,
          config
        )
        const response = await apiService.getInstrumentDownloads(
          this.$route.params.id.split('@')[1],
          this.$route.params.id
        )
        this.downloads = response.data
        await this.$store.dispatch('toasts/raise', {
          status: 'success',
          message: 'Download updated',
        })
        this.closeEditDialog()
        this.clearDirty()
      } catch (err) {
        if (err !== Handled) {
          await this.$store.dispatch('toasts/raise', {
            status: 'alert',
            message: 'Failed to update download',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  display: flex;
  margin: 0 0 20px;
}

.title {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}

.actions {
  margin-left: auto;
}
</style>
