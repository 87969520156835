<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    segmented: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        'button-group': true,
        'is-segmented': this.segmented,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-group {
  display: inline-flex;
}

.button-group > * + * {
  margin-left: 1rem;
}

.button-group ::v-deep .button:focus {
  position: relative;
  z-index: 1;
}

.is-segmented > * + * {
  margin-left: -1px;
}

.is-segmented > :not(:last-child),
.is-segmented > :not(:last-child) ::v-deep .button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.is-segmented > :not(:first-child),
.is-segmented > :not(:first-child) ::v-deep .button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
</style>
