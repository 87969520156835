<template>
  <component
    class="block px-6 py-2 cursor-pointer relative text-left w-full z-10 hover:bg-gray-100"
    :is="attrs.to ? 'router-link' : 'button'"
    v-bind="attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    attrs() {
      const attrs = { ...this.$attrs }
      if (!attrs.to) {
        attrs['type'] = 'button'
      }
      return attrs
    },
  },
}
</script>
