<template>
  <textarea
    ref="input"
    class="border border-gray-300 px-4 py-3 rounded-md"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  methods: {
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
