import apiService from '@/services/apiService'

/**
 * Fetch instruments for sensors.
 *
 * @param sensors Result of apiService.getSensors() call.
 * @returns Object of apiService.getInstruments() replies indexed by sensor.id
 */
export default async function instrumentsForSensors(sensors) {
  // Fetch instruments for every sensor parallel
  let sensorsAndInstruments = await Promise.all(
    sensors.map(async (sensor) => {
      try {
        const { data: instruments } = await apiService.getInstruments(sensor.id)
        return { [sensor.id]: instruments }
      } catch (e) {
        console.warn('No reply from ' + sensor.id)
        return { [sensor.id]: [] }
      }
    })
  )
  // convert array of objects to single object
  return sensorsAndInstruments.reduce(function (result, current) {
    return Object.assign(result, current)
  }, {})
}
