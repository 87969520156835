<template>
  <div class="progress">
    <div class="bar" :style="{ width: `${value}%` }">
      <div class="label">{{ value }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.progress {
  background: $grey-200;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px $grey-400;
  display: block;
  overflow: hidden;
}

.bar {
  background: $blue-800;
  height: 1.8rem;
  overflow: hidden;
  transition: 0.25s;
}

.bar > .label {
  color: $white;
  font-size: 1.3rem;
  text-align: center;
}
</style>
